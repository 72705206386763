import { APICall } from "../index";
import { signout } from "../../store/actions/userActions";

export const postAvailability = async (post_data) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall("post", "create-user-availabilities", post_data);
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
};

export const postCategorySetting = async (post_data) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall("post", "admin/update-category", post_data);
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
};

export const postProfileExpEduSetting = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall("post", "add-work-profile-education", post_data);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
};

export const postProfileWorkProjectSetting = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall("post", "add-work-profile-projects", post_data);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
};

export const postBankDetail = async (post_data) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall("post", "bank-details", post_data);
    status = res_data.status === 200 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
};
