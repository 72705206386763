import React, { useEffect, useState } from "react";
import {size} from "lodash";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import { unescapeHTML } from "../../../components/common/Common";
import { getPageData } from "../../../services/home/publicRequest";
import Loader from "../../../components/common/Loader";


const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      let data = await getPageData("Privacy");
      if (data.status === 1) {
        setPageData(data.data);
      } else {
        navigate("/page-not-found");
      }
    };
    getData();
  }, [navigate]);
  return (
    <>
      <Header />
      <div className="wt-haslayout wt-innerbannerholder">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-xs-12 col-sm-12 col-md-8 push-md-2 col-lg-6 push-lg-3">
              <div className="wt-innerbannercontent">
                <div className="wt-title">
                  <h2>{size(pageData) > 0 && pageData.blockTitle}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="wt-main" className="wt-main wt-haslayout wt-innerbgcolor">
        <div className="wt-main-section wt-haslayout">
          <div className="wt-haslayout">
            <div className="container">
              {size(pageData) === 0 && <Loader />}
              <div
                className="col-12 col-sm-6 col-md-3 col-lg-3"
                dangerouslySetInnerHTML={{
                  __html: unescapeHTML(pageData.blockContent),
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
