import React from "react";
import { NavLink } from "react-router-dom";

const FilterContentBlock = ({
  filter,
  callBack,
  filterType,
  selectedFilter,
}) => {
  return (
    <>
      <div className="wt-widget wt-effectiveholder">
        <div className="wt-widgettitle">
          <h2>{filter.filterTitle}</h2>
        </div>
        <div className="wt-widgetcontent">
          <form className="wt-formtheme wt-formsearch">
            {filter.placeholder ? (
              <fieldset>
                <div className="form-group">
                  <input
                    type="text"
                    name="Search"
                    className="form-control"
                    placeholder={filter.placeholder}
                  />
                  <NavLink to="#" className="wt-searchgbtn">
                    <i className="lnr lnr-magnifier" />
                  </NavLink>
                </div>
              </fieldset>
            ) : (
              ""
            )}

            <fieldset>
              <div className="wt-radio wt-verticalscrollbar">
                {filter.filterOptions.map((filOpt, index) => (
                  <span key={index}>
                    <input
                      type="radio"
                      name={filter.filterTitle}
                      id={`${filter.filterTitle}-${index}`}
                      value={filOpt.filterName}
                      checked={selectedFilter === filOpt.filterName}
                      onChange={(e) =>
                        callBack(e, filterType, filOpt.filterName)
                      }
                    />
                    <label className="mb-2" htmlFor={`${filter.filterTitle}-${index}`}>
                      <span> {filOpt.filterName}</span>
                    </label>
                  </span>
                ))}
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </>
  );
};

export default FilterContentBlock;
