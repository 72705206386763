import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { size } from "lodash";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  sendStartJob,
  sendEndJob,
  sendCompletedJob,
  getJobsByStatus,
} from "../../../services/job/jobRequest";

import Loader from "../../common/Loader";
import JobListDetail from "./JobListDetail";
import Chatting from "../chatting";
import MapViewModal from "../../MapViewModal";

const JobListOnWay = ({ update_status, user_role_type, job_status_type }) => {
  const dispatch = useDispatch();
  const [activeLoader, setActiveLoader] = useState(false);

  const [jobList, setJobList] = useState([]);
  const [completedJob, setCompletedJob] = useState({});
  const [modalAction, setModalAction] = useState(false);
  const [chatModalAction, setChatModalAction] = useState(false);
  const [showMapViewModal, setShowMapViewModal] = useState(false);

  const startJob = async (job_id) => {
    setActiveLoader(true);
    let post_data = {
      job_id: job_id,
      job_time: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    let response = await sendStartJob(post_data, dispatch);
    if (response.status === 1) {
      toast("Success! Action has successfully completed");
      // let job_list_data = jobList.filter((value) => {
      //   return value._id === job_id ? false : true;
      // });
      // setJobList(job_list_data);
    } else {
      toast("Error! Please try again later");
    }
    setActiveLoader(false);
  };

  const endJob = async (job_id) => {
    setActiveLoader(true);
    let post_data = {
      job_id: job_id,
      job_time: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    let response = await sendEndJob(post_data, dispatch);
    if (response.status === 1) {
      toast("Success! Action has successfully completed");
      let res_com = await sendCompletedJob(
        {
          job_id: job_id,
          complete_time: moment().format("YYYY-MM-DD HH:mm:ss"),
        },
        dispatch
      );
      if (res_com.status === 1) {
        setCompletedJob(res_com.data);
        setModalAction(true);
      }
      let res_jobs = await getJobsByStatus({ job_status: 4 }, dispatch);
      setJobList(res_jobs.status === 0 ? [] : res_jobs.data);
      // let job_list_data = jobList.filter((value) => {
      //   return value._id === job_id ? false : true;
      // });
      // setJobList(job_list_data);
    } else {
      toast("Error! Please try again later");
    }
    setActiveLoader(false);
  };

  useEffect(() => {
    if (update_status !== null && job_status_type === 'onWay') {
      const getData = async () => {
        setActiveLoader(true);
        let res = await getJobsByStatus({ job_status: 4 }, dispatch);
        setActiveLoader(false);
        setJobList(res.data);
      };
      getData();
      const interval = setInterval(() => {
        getData();
      }, 30 * 1000);
      return () => clearInterval(interval);
    }
  }, [dispatch, update_status, job_status_type]);
  return (
    <>
      {activeLoader && <Loader />}
      <div className="tab-pane" id="tab-job-list-onway">
        <div className="wt-jobdetailsholder">
          <div className="wt-completejobholder">
            <div className="wt-tabscontenttitle">
              <h2>On way to Start Jobs</h2>
            </div>
            <div>
              {size(jobList) > 0 ? (
                jobList.map((value, index) => (
                  <div
                    className="wt-userlistinghold wt-featured wt-userlistingvtwo"
                    key={index}
                    style={{ paddingBottom: 20 }}
                  >
                    <div className="wt-userlistingcontent wt-userlistingcontentvtwo">
                      <JobListDetail
                        list_value={value}
                        user_role_type={user_role_type}
                      />
                      <div className="wt-contenthead">
                        {(user_role_type === 4 || user_role_type === "4") && (
                          <div className="row pt-2">
                            <div className="col-12">
                              {value.job_starttime === "" ? (
                                <button
                                  type="button"
                                  className="btn btn-success mr-1"
                                  onClick={() => startJob(value._id)}
                                >
                                  Start Job
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => endJob(value._id)}
                                >
                                  End Job
                                </button>
                              )}
                              {chatModalAction === value._id ? (
                                <Chatting
                                  key={value._id}
                                  modalAction={chatModalAction === value._id}
                                  setModalAction={setChatModalAction}
                                  professionalId={value.professional._id}
                                  professionalName={
                                    value.professional.first_name +
                                    " " +
                                    value.professional.last_name
                                  }
                                  jobId={value._id}
                                  name={value.job_title}
                                  client_id={user_role_type === 3 ? undefined : value.client_id}
                                />
                              ) : (
                                <Button
                                  variant="info"
                                  type="button"
                                  onClick={() => setChatModalAction(value._id)}
                                  className="py-2 px-3 ml-2 btn btn-info"
                                >
                                  Chat
                                </Button>
                              )}
                              <Button
                                hidden={user_role_type === 4}
                                variant="success"
                                type="button"
                                style={{ marginLeft: "10px" }}
                                onClick={() =>
                                  setShowMapViewModal(value.professional._id)
                                }
                                className="btn btn-success ml-2"
                              >
                                View on map
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center">No Jobs Found</div>
              )}
            </div>
            {size(completedJob) > 0 && (
              <Modal show={modalAction}>
                <Modal.Header>Job Completed</Modal.Header>
                <Modal.Body>
                  <div>
                    Job completed, amount transferred to your wallet.
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div
                    className="btn btn-secondary"
                    onClick={() => setModalAction(false)}
                  >
                    Close
                  </div>
                </Modal.Footer>
              </Modal>
            )}
          </div>
        </div>
      </div>
      <MapViewModal
        open={!!showMapViewModal}
        userId={showMapViewModal}
        onClose={() => setShowMapViewModal(null)}
      />
    </>
  );
};

export default JobListOnWay;
