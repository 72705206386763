import { APICall } from "../index";

export const getPageData = async (post_data,dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('get','staticblock/'+post_data,[]);
    status = res_data.status === 200 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data.data};
}