import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { get, size } from "lodash";
import Header from "../../../components/Header";
import SearchContentBlock from "../../../components/users/SearchContentBlock";
import UserListBlock from "../../../components/users/UserListBlock";
// import userdata from "../data/UsersData";
import { FreelancerListData } from "../../../store/actions/freelancerListActions";
import { GoogleAddressLatLong } from "../../../components/common/third_party/GoogleAddressLatLong";

// import {
//   categoryFilter,
//   locationFilter,
//   hourlyRate,
//   freelancerType,
//   englishLevel,
//   languages,
// } from "../data/FileterData";
import FilterContentBlock from "../../../components/users/FilterContentBlock";
import PaginationBlock from "../../../components/users/PaginationBlock";
import Loader from "../../../components/common/Loader";
import ProfessionalUserList from "../dashboard/ProfessionalUserList";

const FreelancerList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const { totalTime } = state || { totalTime: null };
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const freelancerData = useSelector((state) => state.freelancer_list);
  const { loading } = freelancerData;
  const [prevInvited, setPrevInvited] = useState(0);
  //console.log(freelancerData.freelancer_list?.selectedskill);
  let search = new URLSearchParams(window.location.search).get("search");
  let category = new URLSearchParams(window.location.search).get("category");

  const [filterExperience, setFilterExperience] = useState("");
  // const [filterLocation, setFilterLocation] = useState("");
  const [filterHourlyRate, setFilterHourlyRate] = useState("");
  // const [filterEnglishLevel, setEnglishLevel] = useState("");
  // const [filterLanguage, setLanguage] = useState("");
  const [filterRadius, setRadius] = useState("");
  const [searchQueryString, setSearchQueryString] = useState(() => {
    let filter_data_string = "";
    if (search !== null && category !== null) {
      filter_data_string += "?search=" + search + "&category=" + category;
    }
    return filter_data_string;
  });

  const setFilterValues = (e, filterType, value) => {
    const { checked } = e.target;
    let filterFunction;
    let flag = true;
    switch (filterType) {
      case "exp":
        filterFunction = setFilterExperience;
        break;
      // case "location":
      //   filterFunction = setFilterLocation;
      //   break;
      case "rate":
        filterFunction = setFilterHourlyRate;
        break;
      // case "level":
      //   filterFunction = setEnglishLevel;
      //   break;
      // case "lang":
      //   filterFunction = setLanguage;
      //   break;
      case "radius":
        filterFunction = setRadius;
        break;
      default:
        flag = false;
        break;
    }
    if (!flag) return;
    if (checked) {
      filterFunction(value);
    } else if (!checked) {
      filterFunction((prev) =>
        prev.filter((val) => {
          return val !== value;
        })
      );
    } else {
      filterFunction([]);
    }
  };

  const callBackClearFilter = () => {
    setFilterExperience([]);
    // setFilterLocation([]);
    setFilterHourlyRate([]);
    // setEnglishLevel([]);
    // setLanguage([]);
    setRadius([]);
  };

  const perHourPrice = (price) => {
    if (price > prevInvited) {
      setPrevInvited(price);
    }
  };

  // console.log("filterExperience", filterExperience);
  // console.log("filterLocation", filterLocation);
  // console.log("filterHourlyRate", filterHourlyRate);
  // console.log("filterEnglishLevel", filterEnglishLevel);
  // console.log("filterLanguage", filterLanguage);

  const filtersList = get(freelancerData, "freelancer_list.filters", []);
  const structureFilters = (filter, filterTitle) => {
    const filterOptions = [];
    size(filter) > 0 &&
      filter.map((filterName) => {
        filterOptions.push({
          filterName,
        });
        return true;
      });
    return {
      filterTitle,
      filterOptions,
      placeholder: "",
    };
  };
  const handleAddressChange = async (e) => {
    let address = e.target.value;
    let data = await GoogleAddressLatLong(address);
  };

  const getQueryString = () => {
    let filter_data_string = "";
    let flag_key = "?";
    if (search !== null && category !== null) {
      filter_data_string +=
        flag_key + "search=" + search + "&category=" + category;
      flag_key = "&";
    }
    if (filterExperience.length > 0) {
      filter_data_string += flag_key + "experience=" + filterExperience;
      flag_key = "&";
    }
    // if (filterLocation.length > 0) {
    //   filter_data_string += flag_key + "location=" + filterLocation;
    //   flag_key = "&";
    // }
    if (filterHourlyRate.length > 0) {
      filter_data_string += flag_key + "hourlyrate=" + filterHourlyRate;
      flag_key = "&";
    }
    // if (filterEnglishLevel.length > 0) {
    //   filter_data_string += flag_key + "englishlevel=" + filterEnglishLevel;
    //   flag_key = "&";
    // }
    // if (filterLanguage.length > 0) {
    //   filter_data_string += flag_key + "language=" + filterLanguage;
    //   flag_key = "&";
    // }
    if (filterRadius.length > 0) {
      filter_data_string += flag_key + "radius=" + filterRadius;
      flag_key = "&";
    }
    if (id) {
      filter_data_string += flag_key + "job_id=" + id;
      flag_key = "&";
    }

    return filter_data_string;
  };

  useEffect(() => {
    let filter_data_string = getQueryString();
    dispatch(FreelancerListData(filter_data_string));
  }, [dispatch, searchQueryString]);

  useEffect(() => {
    let filter_data_string = getQueryString();
    setSearchQueryString(filter_data_string);
  }, [
    filterExperience,
    // filterLocation,
    filterHourlyRate,
    // filterEnglishLevel,
    // filterLanguage,
    filterRadius,
  ]);

  const onAddFunds = () => {
    navigate(`/addfunds/${id}`, {
      state: { jobId: id, totalPrice: prevInvited * totalTime },
    });
  };
  return userInfo.user.role === 4 ? (
    <ProfessionalUserList />
  ) : (
    <>
      <Header extraClass="wt-headervtwo" />
      <div className="wt-haslayout wt-innerbannerholder">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-xs-12 col-sm-12 col-md-8 push-md-2 col-lg-6 push-lg-3">
              <div className="wt-innerbannercontent">
                <div className="wt-title">
                  <h2>Search Result</h2>
                </div>
                <ol className="wt-breadcrumb">
                  <li>
                    <NavLink to="#">Home</NavLink>
                  </li>
                  <li className="wt-active">Freelancers</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Inner Home End*/}
      {/*Main Start*/}
      <main id="wt-main" className="wt-main wt-haslayout wt-innerbgcolor">
        <div className="wt-main-section wt-haslayout">
          {/* User Listing Start*/}
          <div className="wt-haslayout">
            <div className="container">
              <div className="row">
                <div id="wt-twocolumns" className="wt-twocolumns wt-haslayout">
                  <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 float-left">
                    <div className="wt-usersidebaricon">
                      <span className="fa fa-cog wt-usersidebardown">
                        <i />
                      </span>
                    </div>
                    <aside
                      id="wt-sidebar"
                      className="wt-sidebar wt-usersidebar"
                    >
                      <FilterContentBlock
                        filter={structureFilters(
                          filtersList.experience_level,
                          "Experience Level"
                        )}
                        callBack={setFilterValues}
                        filterType="exp"
                        selectedFilter={filterExperience}
                      />
                      {/* <FilterContentBlock
                        filter={structureFilters(
                          filtersList.location,
                          "Location"
                        )}
                        callBack={setFilterValues}
                        filterType="location"
                        selectedFilter={filterLocation}
                      /> */}
                      <FilterContentBlock
                        filter={structureFilters(
                          filtersList.hourly_rate,
                          "Rate"
                        )}
                        callBack={setFilterValues}
                        filterType="rate"
                        selectedFilter={filterHourlyRate}
                      />
                      {/* <FilterContentBlock

                          filter={structureFilters(
                            filtersList.english_level,
                            "English Level"
                          )}
                          callBack={setFilterValues}
                          filterType="level"
                          selectedFilter={filterEnglishLevel}

                        /> */}
                      {/* <FilterContentBlock

                          filter={structureFilters(
                            filtersList.languages_known,
                            "Languages"
                          )}
                          callBack={setFilterValues}
                          filterType="lang"
                          selectedFilter={filterLanguage}
                      /> */}
                      <FilterContentBlock
                        filter={structureFilters(
                          filtersList.search_by_radius,
                          "Radius"
                        )}
                        callBack={setFilterValues}
                        filterType="radius"
                        selectedFilter={filterRadius}
                      />
                      <div className="wt-widget wt-applyfilters-holder hide">
                        <div className="wt-widgetcontent">
                          <div className="wt-applyfilters">
                            <span>
                              Click “Apply Filter” to apply latest
                              <br /> changes made by you.
                            </span>
                            <NavLink to="#" className="wt-btn">
                              Apply Filters
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </aside>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-8 float-left">
                    <div className="wt-userlistingholder wt-userlisting wt-haslayout">
                      {loading ? (
                        <Loader />
                      ) : (
                        <>
                          <div className="d-flex align-items-center justify-content-between wt-userlistingtitle">
                            <span>
                              {size(freelancerData.freelancer_list.users)}{" "}
                              results{" "}
                              <em>{search !== null && "For " + search}</em>
                            </span>
                            {/* {id && (
                                  // <button
                                  //   className="btn btn-success"
                                  //   onClick={onAddFunds}
                                  // >
                                  //   Add Funds
                                  // </button>
                                )} */}
                          </div>
                          <SearchContentBlock
                            filterExperience={filterExperience}
                            // filterLocation={filterLocation}
                            filterHourlyRate={filterHourlyRate}
                            // filterEnglishLevel={filterEnglishLevel}
                            // filterLanguage={filterLanguage}
                            filterRadius={filterRadius}
                            callBackClearFilter={callBackClearFilter}
                          />
                          {size(freelancerData.freelancer_list.users) > 0 &&
                            freelancerData.freelancer_list.users.map(
                              (user, index) => (
                                <UserListBlock
                                  userinfo={user}
                                  baseurl={
                                    freelancerData.freelancer_list.baseurl
                                  }
                                  invitedUsers={
                                    freelancerData.freelancer_list
                                      .userJobInvitation
                                  }
                                  key={index}
                                  perHourPrice={perHourPrice}
                                  selectskill={freelancerData.freelancer_list?.selectedskill}
                                />
                              )
                            )}
                          <PaginationBlock />
                        </>
                      )}

                    </div>
                  </div>
                </div>
              </div>
              {/* User Listing End*/}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default FreelancerList;
