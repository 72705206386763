import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { size, get } from "lodash";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import { submitJobFeedback } from "../../../services/job/jobRequest";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaStar } from "react-icons/fa";
import { Container, Radio, Rating } from "./RatingStyles";
import { useNavigate } from "react-router-dom";

const JobListDetail = ({ list_value, user_role_type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rate, setRate] = useState(0);

  const [loaderState, setLoaderState] = useState(false);
  const showBookingTime = (duration) => {
    let final_data = "";
    if (duration.length > 0) {
      let data_01 = duration.split("^");
      for (var i = 0; i < data_01.length; i++) {
        final_data += "<span>";
        let data_02 = data_01[i].split("~");
        for (var j = 0; j < data_02.length; j++) {
          let data_03 = data_02[j].split("$");
          for (var k = 0; k < data_03.length; k++) {
            final_data += "&nbsp;<span>";
            final_data += data_03[k];
            final_data += "</span>";
          }
        }
        final_data += "</span>";
      }
    }
    return final_data;
  };

  function formatDate(date) {
    var dt = new Date(date);
    return dt.getFullYear() + "-" + ('0' + (dt.getMonth() + 1)).slice(-2) + "-" + ('0' + dt.getDate()).slice(-2);
  }

  const [modalAction, setModalAction] = useState(false);

  const Schema = Yup.object().shape({
    feedbackText: Yup.string().required("Feedback is required"),
  });

  return (
    <>
      <div className="row">
        <div className="col-12 mx-3 mt-3">
          <div className="">
            <div className="">
              {(user_role_type === 3 || user_role_type === "3") && (
                <h4>{list_value.job_title}</h4>
              )}
              {(user_role_type === 4 || user_role_type === "4") && size(get(list_value, "client", [])) > 0 && (
                <>
                  <div className="wt-rightarea" style={{ display: "flex" }} >
                    <div style={{ "float": "left" }}>
                      <h4>{list_value.job_title}</h4>
                    </div>
                    <div>
                      <h4 style={{ color: "#1edb48", "float": "left", "marginLeft": "19rem" }}>
                        {list_value.client.first_name +
                          " " +
                          list_value.client.last_name}
                      </h4>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div style={{ float: "left" }}><span><b>Job Created:</b> </span>{list_value.jobcreated}&nbsp;&nbsp;| &nbsp; </div>
            <div className="flex" style={{ float: "left" }}>
              <b>Job Timing:</b>
              <span
                dangerouslySetInnerHTML={{
                  __html: showBookingTime(list_value.duration),
                }}
              ></span>
            </div>
            <div style={{ float: "left" }}>&nbsp;| <span><b>Job Category:</b> </span>{list_value.category_name}</div>
          </div>
        </div>
        {list_value.job_status === 6 && (
          <div className="col-12 mb-5">
            <button className="wt-btn" onClick={() => setModalAction(true)}>
              Submit Feedback for this job
            </button>
          </div>
        )}
        <div className="col-12 mx-3 flex">
          <div className="mt-3 flex">{list_value.description}</div>
        </div>
        <div className="col-12">
          {(user_role_type === 3 || user_role_type === "3") &&
            size(get(list_value, "professional", [])) > 0 && (
              <div className="wt-rightarea">
                <div className="wt-hireduserstatus">
                  <h4>Awarded</h4>
                  <span>
                    {list_value.professional.first_name +
                      " " +
                      list_value.professional.last_name}
                  </span>
                </div>
              </div>
            )}
        </div>
        {/* {list_value.totalamount > 0 && ( */}
        <div className="col-12 mx-3 mt-2">
          <h4>
            {list_value.amounttopay === 0 ? (
              <span>Waiting for job acceptance</span>
            ) : (
                <span>${list_value.amounttopay}
                  {/* {list_value.amounttopay.toFixed(Math.max(((list_value.amounttopay + '').split(".")[1] || "").length, 2))} */}
                </span>
              )}
            {(user_role_type === 3 || user_role_type === "3") && list_value.job_status === 1 && list_value.funds === 0 && (
              <button
                onClick={() =>
                  navigate("/addfunds", {
                    state: { jobId: list_value._id },
                  })
                }
                className="btn btn-success ml-3"
              >
                Add Funds
              </button>
            )}
          </h4>
          {/* <table className="table table-responsive table-striped table-hover">
              <tbody>
                <tr>
                  <td width="50%">Hours</td>
                  <td width="50%">{list_value.hour_min}</td>
                </tr>
                <tr>
                  <td>Total Minutes</td>
                  <td>{list_value.totalminutes}</td>
                </tr>

                <tr>
                  <td>Hours Fee</td>
                  <td>{list_value.hourlyfee}</td>
                </tr>
                <tr>
                  <td>Admin Fee</td>
                  <td>{list_value.adminfee}</td>
                </tr>
                <tr>
                  <td>Total Amount</td>
                  <td>{list_value.totalamount}</td>
                </tr>
              </tbody>
            </table> */}
        </div>
        {/* )} */}
      </div>
      {list_value.job_status === 6 && (
        <Modal show={modalAction}>
          <Modal.Header>Job Feedback</Modal.Header>
          <Modal.Body>
            <div className="col-12">
              <Formik
                initialValues={{
                  job_id: "",
                  feedbackText: "",
                }}
                validationSchema={Schema}
                onSubmit={async (values, actions) => {
                  if (rate === 0) {
                    toast.error("Error! Rating is required");
                    return;
                  }
                  setLoaderState(true);
                  let post_data = {
                    job_id: list_value._id,
                    feedbackText: values.feedbackText,
                    feedbackRating: rate,
                  };

                  let response = await submitJobFeedback(post_data, dispatch);
                  if (response.status === 1) {
                    toast.success("Success! Review has submitted successfully");
                  } else {
                    toast.error("Error! Please try again later");
                  }
                  setModalAction(false);
                  setLoaderState(false);
                }}
              >
                {(props) => (
                  <form
                    className="wt-formtheme wt-userform"
                    onSubmit={props.handleSubmit}
                  >
                    {loaderState && <Loader />}
                    <fieldset>
                      <label>Submit Feedback for this job</label>
                      <div className="form-group">
                        <label>Choose Rating</label>
                        <Container>
                          {[...Array(5)].map((item, index) => {
                            const givenRating = index + 1;
                            return (
                              <label key={index}>
                                <Radio
                                  type="radio"
                                  value={givenRating}
                                  onClick={() => {
                                    setRate(givenRating);
                                  }}
                                />
                                <Rating>
                                  <FaStar
                                    color={
                                      givenRating < rate || givenRating === rate
                                        ? "000"
                                        : "rgb(192,192,192)"
                                    }
                                  />
                                </Rating>
                              </label>
                            );
                          })}
                        </Container>
                      </div>
                      <div className="form-group">
                        <label>Write your experience for this job</label>
                        <Field
                          component="textarea"
                          placeholder="Describe your Experience"
                          className="form-control"
                          name="feedbackText"
                        />
                        {props.errors.feedbackText &&
                          props.touched.feedbackText && (
                            <span className="text-danger">
                              {props.errors.feedbackText}
                            </span>
                          )}
                      </div>
                      <div className="form-group mb-5">
                        <button className="wt-btn" type="submit">
                          Submit Feedback
                        </button>
                      </div>
                    </fieldset>
                  </form>
                )}
              </Formik>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              className="btn btn-secondary"
              onClick={() => setModalAction(false)}
            >
              Close
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default JobListDetail;
