import Axios from "axios";
import {
  COMMON_COUNTRY_LIST_REQUEST,
  COMMON_COUNTRY_LIST_SUCCESS,
  COMMON_COUNTRY_LIST_FAIL,
  COMMON_STATE_LIST_REQUEST,
  COMMON_STATE_LIST_SUCCESS,
  COMMON_STATE_LIST_FAIL,
  COMMON_CITY_LIST_REQUEST,
  COMMON_CITY_LIST_SUCCESS,
  COMMON_CITY_LIST_FAIL,
  COMMON_LOGIN_POPUP_REQUEST,
  COMMON_LOGIN_POPUP_SUCCESS
} from "../../constants/common/common";

export const setCountryList = () => async (dispatch) => {
  dispatch({
    type: COMMON_COUNTRY_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      `${process.env.REACT_APP_API_URI}/api/v1/countries`
    );
    dispatch({ type: COMMON_COUNTRY_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: COMMON_COUNTRY_LIST_FAIL, payload: message });
  }
};
export const setStateListByCountryId = (country_id) => async (dispatch) => {
  dispatch({
    type: COMMON_STATE_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      `${process.env.REACT_APP_API_URI}/api/v1/countries/`+country_id
    );
    dispatch({ type: COMMON_STATE_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: COMMON_STATE_LIST_FAIL, payload: message });
  }
};

export const setCityListBStateId = (state_id) => async (dispatch) => {
  dispatch({
    type: COMMON_CITY_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      `${process.env.REACT_APP_API_URI}/api/v1/states/`+state_id
    );
    dispatch({ type: COMMON_CITY_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: COMMON_CITY_LIST_FAIL, payload: message });
  }
};

export const setLoginPopupStatus = (data) => async (dispatch) => {
  dispatch({
    type: COMMON_LOGIN_POPUP_REQUEST,
  });
  try {
    dispatch({ type: COMMON_LOGIN_POPUP_SUCCESS, payload: data });
  } catch (error) {

  }
};