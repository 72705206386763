import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { size } from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { setUserJobs } from "../../../store/actions/job/MyJobListActions";
import {
  getJobsByStatus,
  sendJobReleasePayment,
  sendJobRaiseDispute,
  jobPaymentSuccess,
  jobPaymentFailed,
  getPaypalClientId,
} from "../../../services/job/jobRequest";
import Loader from "../../common/Loader";
import JobListDetail from "./JobListDetail";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import Chatting from "../chatting";

const JobListCompleted = ({ update_status, user_role_type, job_status_type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [LoaderState, setLoaderState] = useState(false);
  const [paypalClientId, setPaypalClientId] = useState("");
  const [jobList, setJobList] = useState([]);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [orderID, setOrderID] = useState(false);
  const [modalAction, setModalAction] = useState(false);
  const [selectedJob, setSelectedJob] = useState(false);
  const [chatModalAction, setChatModalAction] = useState(false);

  useEffect(async () => {
    let response = await getPaypalClientId(dispatch);
    setPaypalClientId(response.data.data.paypal.clientid);
  }, []);

  const post_data_default = { job_status: 5 };

  const jobPayment = (job_id) => {
    // setLoaderState(true);
    // let post_data = {
    //   job_id: job_id,
    //   approvetime: moment().format("YYYY-MM-DD HH:mm:ss"),
    // };

    setSelectedJob(jobList.find((item) => item._id == job_id));
    setModalAction(true);

    // let response = await sendJobReleasePayment(post_data, dispatch);
    // console.log('response', response);
    // if (response.status === 1) {
    // toast("Success! Please Pay with COD method");
    // dispatch(setUserJobs(post_data_default));
    // } else {
    //   toast("Error! Please try again later");
    // }
    // setLoaderState(false);
  };

  const jobDispute = async (job_id) => {
    setLoaderState(true);
    let post_data_reject = {
      job_id: job_id,
      disputetime: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    let response = await sendJobRaiseDispute(post_data_reject, dispatch);

    if (response.status === 1) {
      toast("Success! Action has successfully completed");
      dispatch(setUserJobs(post_data_default));
      navigate("/dispute/" + job_id);
    } else {
      toast("Error! Please try again later");
    }
    setLoaderState(false);
  };

  //job status : 0 - Pending, 1 - Accept, 2- Reject, 3- Reject after Accept, 5 - Complete, 6 - Approve, 7 - RaiseIssue
  // const post_data_default = { job_status: 7 };
  useEffect(() => {
    if (update_status !== null && job_status_type === 'completed') {
      const getData = async () => {
        setLoaderState(true);
        let res = await getJobsByStatus({ job_status: 5 }, dispatch);
        setJobList(res.data);
        setLoaderState(false);
      };
      getData();
      const interval = setInterval(() => {
        getData();
      }, 30 * 1000);
      return () => clearInterval(interval);
    }
  }, [dispatch, update_status, job_status_type]);

  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: selectedJob?.description,
            amount: {
              currency_code: "USD",
              value: selectedJob?.totalamount,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(async (details) => {
      const success_data = {
        job_id: selectedJob._id,
        alldata: details,
        transactionid: details.id,
        transactiondt:
          details.purchase_units[0].payments.captures[0].create_time,
        amount: details.purchase_units[0].payments.captures[0].amount.value,
      };
      jobPaymentSuccess(success_data, dispatch);

      let post_data = {
        job_id: selectedJob._id,
        approvetime: moment().format("YYYY-MM-DD HH:mm:ss"),
      };
      let response = await sendJobReleasePayment(post_data, dispatch);
      if (response.status === 1) {
        setModalAction(false);
        toast("Success!");
        // dispatch(setUserJobs(post_data_default));
        navigate("/payment-success", {
          state: {
            amount: details.purchase_units[0].payments.captures[0].amount.value,
            ref_id: details.id,
          },
        });
      } else {
        toast("Error! Please try again later");
      }
    });
  };

  //capture likely error
  const onError = (data, actions) => {
    let post_data = {
      job_id: selectedJob._id,
    };
    jobPaymentFailed(post_data, dispatch);
    setModalAction(false);
    alert("An Error occured with your payment ");
  };

  if (paypalClientId == "") return null;

  return (
    <PayPalScriptProvider
      options={{
        "client-id": paypalClientId,
      }}
    >
      {LoaderState && <Loader />}
      <div className="tab-pane" id="tab-job-list-complete">
        <div className="wt-jobdetailsholder">
          <div className="wt-completejobholder">
            <div className="wt-tabscontenttitle">
              <h2>Completed Jobs</h2>
            </div>
            <div>
              {size(jobList) > 0 ? (
                jobList.map((value, index) => (
                  <div
                    className="wt-userlistinghold wt-featured wt-userlistingvtwo"
                    key={index}
                  >
                    <div className="wt-userlistingcontent wt-userlistingcontentvtwo">
                      <JobListDetail
                        list_value={value}
                        user_role_type={user_role_type}
                      />
                      <div className="wt-contenthead">
                        <div className="wt-title">
                          {userInfo &&
                            size(userInfo) > 0 &&
                            userInfo.user.role === 3 && (
                              <>
                                {/* <button
                                  className="btn btn-outline-success"
                                  onClick={() => jobPayment(value._id)}
                                >
                                  Release Payment
                                </button> */}
                                <button
                                  className="btn btn-danger py-2 px-3"
                                  onClick={() => jobDispute(value._id)}
                                >
                                  Raise Dispute
                                </button>
                              </>
                            )}
                          {chatModalAction === value._id ? (
                            <Chatting
                              key={value._id}
                              modalAction={chatModalAction === value._id}
                              setModalAction={setChatModalAction}
                              professionalId={value.professional._id}
                              professionalName={
                                value.professional.first_name +
                                " " +
                                value.professional.last_name
                              }
                              jobId={value._id}
                              name={value.job_title}

                            />
                          ) : (
                            <button
                              className="py-2 px-3 ml-2 btn btn-info"
                              onClick={() => setChatModalAction(value._id)}
                            >
                              Chat
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center">No Jobs Found</div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <Modal show={modalAction}>
        <Modal.Header>Release Payment</Modal.Header>
        <Modal.Body>
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onError}
          />
        </Modal.Body>
        <Modal.Footer>
          <div
            className="btn btn-secondary"
            onClick={() => setModalAction(false)}
          >
            Close
          </div>
        </Modal.Footer>
      </Modal> */}
    </PayPalScriptProvider>
  );
};

export default JobListCompleted;
