// import React from "react";
import Geocode from "react-geocode";

export const GoogleAddressLatLong = async (address) => {
  // console.log('process.env.REACT_APP_API_URI',process.env.REACT_APP_GOOGLE_MAP_KEY);
  // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.

  //process.env.REACT_APP_GOOGLE_MAP_KEY

  //AIzaSyBirshPopuQwC9YDP8FHejPayO962KGPkk
  //AIzaSyARwT743ZE5Suzuu_VepT2ly8mZu-Deg2A
  //AIzaSyAP_4Et-FIEhvQFYfpnXB4VnqZ1SWK2bIQ
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY);

  // set response language. Defaults to english.
  Geocode.setLanguage("en");

  // set response region. Its optional.
  // A Geocoding request with region=es (Spain) will return the Spanish city.
  Geocode.setRegion("us");

  // set location_type filter . Its optional.
  // google geocoder returns more that one address for given lat/lng.
  // In some case we need one address as response for which google itself provides a location_type filter.
  // So we can easily parse the result for fetching address components
  // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
  // And according to the below google docs in description, ROOFTOP param returns the most accurate result.
  Geocode.setLocationType("ROOFTOP");

  // Enable or disable logs. Its optional.
  Geocode.enableDebug();

  let data = await Geocode.fromAddress(address).then(
    (response) => {
      // const { lat, lng } = response.results[0].geometry.location;
      const data = response.results[0].geometry.location;
      return data;
    },
    (error) => {
      console.error(error);
    }
  );
  return data;
  // let data = {lat:'1',lng:'2'}
  // return data;
};
