import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { setMembershipPlan } from "../../../store/actions/profile/ProfileActions";
import { unescapeHTML } from "../../../components/common/Common";
import { useNavigate } from "react-router-dom";

const Membership = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const membership_plan = useSelector((state) => state.data_membership_plan);
  //console.log("membership_plan", membership_plan);
  //console.log(userInfo.user.isMember);
  //setMembershipPlan
  useEffect(() => {
    dispatch(setMembershipPlan());
  }, [dispatch]);
  return (
    <>
      <Header />
      <main id="wt-main" className="wt-main-dashboard wt-haslayout">
        <section className="wt-haslayout wt-dbsectionspace">
          <div className="row mt-5">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 ">
              <div className="wt-dashboardbox wt-dashboardtabsholder wt-accountsettingholder500 pb-5">
                <div className="wt-tabscontent1 tab-content p-5">
                  <div className="wt-passwordholder" id="wt-password">
                    <div className="wt-changepassword">
                      {(userInfo.user.isMember === 0 || userInfo.user.isMember === undefined) && (
                        <>
                          <div className="wt-tabscontenttitle">
                            <h2><b>Choose Your Plan</b></h2>
                          </div>
                          <div className="row">
                            {!membership_plan.loading &&
                              membership_plan.data.map(
                                (value, index) =>
                                  value.isActive === 1 && (
                                    <div className="col-md-4" key={index}>
                                      <div className="card">
                                        <div className="card-header">
                                          <b>{value.plan}</b>
                                        </div>
                                        <div className="card-body custom_card_height">
                                          <div
                                            className="card-text"
                                            dangerouslySetInnerHTML={{
                                              __html: unescapeHTML(
                                                value.description
                                              ),
                                            }}
                                          ></div>
                                          <h5 className="card-title">
                                            Amount: {value.amount}
                                          </h5>
                                        </div>
                                        <div className="card-footer text-center">
                                          <button
                                            onClick={() =>
                                              navigate("/paymembership", {
                                                state: { memberId: value._id, membershipAmt: value.amount },
                                              })
                                            }
                                            className="btn wt-btn ml-3"
                                          >
                                            Buy Now
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )
                              )}
                          </div>
                        </>
                      )}
                      {userInfo.user.isMember === 1 && (
                        <>
                          <div className="wt-tabscontenttitle">
                            <h2><b>Your have a Membership Plan activated</b></h2>
                          </div>
                          <div className="card-footer text-center">
                            <a href={`/`}
                              className="btn btn-outline-primary mb-5"
                            >
                              Back to Home Page
                            </a>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Membership;
