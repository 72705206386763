import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import JobList from "../../../components/dashboard/job_list";
// import ProfileRightSide from '../../../components/dashboard/ProfileRightSide'
// import Sidebar from '../../../components/dashboard/Sidebar'

const MyJobList = () => {
  return (
    <>
      <Header />
      <main id="wt-main-dashboard" className="wt-main-dashboard wt-haslayout">
        {/* <Sidebar /> */}
        <div className="row">
          <JobList />
          {/* <ProfileRightSide /> */}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default MyJobList;
