import React from 'react'
import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import Profile from '../../../components/dashboard/profile'
import ProfileRightSide from '../../../components/dashboard/ProfileRightSide'
// import Sidebar from '../../../components/dashboard/Sidebar'

const UserProfile = () => {
    return (
        <>
            <Header />
            <main id="wt-main-dashboard" className="wt-main-dashboard wt-haslayout">
                {/* <Sidebar /> */}
                <div className="row">
                    <Profile />
                    <ProfileRightSide />
                </div>
            </main>
            <Footer/>
        </>
    )
}

export default UserProfile
