import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import {
  getJobDetails,
  getPaypalClientId,
  jobPaymentFailed,
  jobPaymentSuccess,
  sendJobReleasePayment,
} from "../../../services/job/jobRequest";
import moment from "moment";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { APICall } from "../../../services";
import { any } from "prop-types";

const AddFunds = () => {
  const { state } = useLocation();
  const { jobId, totalPrice } = state || { jobId: null, totalPrice: null };
  console.log(jobId);
  console.log(totalPrice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [paypalClientId, setPaypalClientId] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [currentBalance, setCurrentBalance] = useState();
  const [modalAction, setModalAction] = useState(false);
  const OnAddFunds = () => {
    setModalAction(true);
  };
  const [amount, setAmount] = useState(totalPrice);
  const userLogin = useSelector((state) => state.userLogin);
  const [amountToPay, setAmountToPay] = useState(0);
  const { userInfo } = userLogin;

  const onAmountChange = (e) => {
    if (e.target.value >= 1) {
      setAmount(e.target.value);
    } else {
      setAmount(0);
    }
  };

  useEffect(async () => {
    let response = await getPaypalClientId(dispatch);
    if (response) {
      setPaypalClientId(response.data.data.paypal.clientid);
    }
    if (jobId != null) {
      let res = await getJobDetails(jobId, dispatch);
      //console.log(res.status);
      if (res.status !== 0) {
        setAmountToPay(res.data.jobs[0].amounttopay);
      }
    }
    if ((userInfo.user.role === 4 || userInfo.user.role === "4") && totalPrice != null) {
      setAmountToPay(totalPrice);
    }
    let data = await APICall("post", "user-wallet", { token: userInfo.token });
    setCurrentBalance(data.userWalletAmt);
  }, []);

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              currency_code: "USD",
              value: amountToPay ? amountToPay : amount,
            },
          },
        ],
      })
      .then((orderId) => {
        setOrderID(orderId);
        return orderId;
      });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(async (details) => {
      //console.log(details);
      const success_data = {
        amount: Number(
          details.purchase_units[0].payments.captures[0].amount.value
        ),
        transaction_type: 1,
        transaction_date: moment(
          details.purchase_units[0].payments.captures[0].create_time
        ).format("DD-MM-YYYY"),
        transaction_id: details.id,
        statusCode: 200,
        ref_job_id: jobId ? jobId : "",
        payment_mode: "online",
        payment_status: "success",
        getway_response: {
          ...details,
        },
        remark: "Funds added for new job",
      };
      const res_data = await APICall("post", "funds", success_data);
      //console.log(res_data);
      if (res_data) {
        setModalAction(false);
        toast(res_data.message);
        navigate("/payment-success", {
          state: {
            amount: details.purchase_units[0].payments.captures[0].amount.value,
            ref_id: details.id,
            ref_job_id: res_data.data.ref_job_id ? res_data.data.ref_job_id : '',
          },
        });
      } else {
        toast("Error! Please try again later");
      }
    });
  };

  // const onError = (data, actions) => {
  //     let post_data = {
  //         user_id: userInfo.user._id,
  //     };
  //     jobPaymentFailed(post_data, dispatch);
  //     setModalAction(false)
  //     alert("An Error occured with your payment ");
  //   };

  if (paypalClientId == "") return null;

  return (
    <PayPalScriptProvider
      options={{
        "client-id": paypalClientId,
      }}
    >
      <Header />
      <main id="wt-main" className="wt-main-dashboard wt-haslayout">
        <section className="wt-haslayout wt-dbsectionspace">
          <div className="row mt-3 ml-4">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-11">
              <div className="wt-dashboardbox wt-dashboardtabsholder wt-accountsettingholder500 pb-5">
                <div className="wt-tabscontent1 tab-content p-5">
                  <div className="wt-passwordholder" id="wt-password">
                    <div className="wt-changepassword">
                      <div className="wt-tabscontenttitle">
                        <h2>Add Funds</h2>
                      </div>
                      <div className="ml-4">
                        <h5>Current Balance</h5>
                        <h5 className="my-3">$ {currentBalance}</h5>
                        <div className="d-flex">
                          {jobId ? (
                            <>
                              <input
                                type="number"
                                placeholder="Enter Amount"
                                min={1}
                                value={amountToPay}
                                disabled={totalPrice}
                                className="col-lg-2 col-sm-4 form-control mr-3"
                                onChange={onAmountChange}
                              />
                              <button
                                className="btn btn-success"
                                disabled={amountToPay <= 0 ? true : false}
                                onClick={OnAddFunds}
                              >
                                Add Funds
                              </button>
                            </>
                          ) : (
                            <>
                              <input
                                type="number"
                                placeholder="Enter Amount"
                                min={1}
                                value={totalPrice}
                                disabled={totalPrice}
                                className="col-lg-2 col-sm-4 form-control mr-3"
                                onChange={onAmountChange}
                              />
                              <button
                                className="btn btn-success"
                                disabled={amount <= 0 ? true : false}
                                onClick={OnAddFunds}
                              >
                                Add Funds
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <Modal show={modalAction}>
        <Modal.Header>Release Payment</Modal.Header>
        <Modal.Body>
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={createOrder}
            onApprove={onApprove}
          // onError={onError}
          />
        </Modal.Body>
        <Modal.Footer>
          <div
            className="btn btn-secondary"
            onClick={() => setModalAction(false)}
          >
            Close
          </div>
        </Modal.Footer>
      </Modal>
    </PayPalScriptProvider>
  );
};

export default AddFunds;
