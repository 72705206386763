import Axios from "axios"
import {
    FREELANCER_DETAIL_REQUEST,
    FREELANCER_DETAIL_SUCCESS,
    FREELANCER_DETAIL_FAIL
} from "../constants/freelancerConstants"

export const FreelancerDetail = (freelancer_id) => async (dispatch) => {
    dispatch({
        type: FREELANCER_DETAIL_REQUEST
    });
    try {
        const { data } = await Axios.get(`${process.env.REACT_APP_API_URI}/api/v1/work-mate-users/`+freelancer_id);
        dispatch({ type: FREELANCER_DETAIL_SUCCESS, payload: data});
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: FREELANCER_DETAIL_FAIL, payload: message });
    }
};