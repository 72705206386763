import React, { useState } from "react";
import SlideToggle from "react-slide-toggle";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { size } from "lodash";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";

import BackgroundSlider from "react-background-slider";

const Banner = ({ categories }) => {
  const navigate = useNavigate();
  const banner_data = useSelector((state) => state.home_banner);
  const { home_banner } = banner_data;
  const banner_list = size(home_banner) > 0 ? home_banner.banner : [];
  const banner_base_url = size(home_banner) > 0 ? home_banner.baseurl : [];
  const banner_images = banner_list.map(
    (value, index) => banner_base_url + "" + value.banner_link
  );
  const [category, setCategory] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  //console.log(userLogin);
  const Schema = Yup.object().shape({
    search_text: Yup.string().required("Search Text required"),
    // selected_category: Yup.string().required("Please select category"),
  });
  const userID = userLogin?.userInfo?.user?._id;
  const lat = userLogin?.userInfo?.user?.lat;
  const lang = userLogin?.userInfo?.user?.lang;
  if (home_banner === undefined) {
    window.location.reload(false);
  }

  return (
    <>
      <div className="wt-haslayout wt-bannerholdertest custom-bg">
        <BackgroundSlider images={banner_images} duration={10} transition={2} />
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className="wt-bannerimages">
                <figure className="wt-bannermanimg" data-tilt>
                  <img
                    src="assets/images/bannerimg/img-01.png"
                    alt="img description"
                  />
                  <img
                    src="assets/images/bannerimg/img-02.png"
                    className="wt-bannermanimgone"
                    alt="img description"
                  />
                  <img
                    src="assets/images/bannerimg/img-03.png"
                    className="wt-bannermanimgtwo"
                    alt="img description"
                  />
                </figure>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
              <div className="wt-bannercontent">
                <div className="wt-bannerhead">
                  <div className="wt-title shadow">
                    <h1>{home_banner.banner[0].title_text}</h1>
                  </div>
                  <div className="wt-description shadow">
                    <p>{home_banner.banner[0].subtext}</p>
                  </div>
                </div>
                <Formik
                  initialValues={{
                    search_text: "",
                  }}
                  validationSchema={Schema}
                  onSubmit={async (values) => {
                    if (userLogin.userInfo) {
                      // if (category.length === 0) {
                      //   toast.error("Error! Please select the category");
                      //   return;
                      // }
                      navigate(
                        "/userlist?search=" +
                        values.search_text +
                        "&category=" +
                        category +
                        "&userID=" +
                        userID +
                        "&lat=" +
                        lat +
                        "&lang=" +
                        lang
                      );
                    } else {
                      toast.error("You have to login first");
                    }
                  }}
                >
                  {(props) => (
                    <form
                      className="wt-formtheme wt-formbanner"
                      onSubmit={props.handleSubmit}
                    >
                      <fieldset>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="I’m looking for"
                            name="search_text"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.search_text}
                          />
                          {props.errors.search_text &&
                            props.touched.search_text && (
                              <span className="text-danger">
                                {props.errors.search_text}
                              </span>
                            )}
                          <SlideToggle collapsed>
                            {({ toggle, setCollapsibleElement }) => (
                              <div className="wt-formoptions">
                                <div
                                  className="wt-dropdown my-collapsible__toggle"
                                  onClick={toggle}
                                >
                                  <span>
                                    In:{" "}
                                    <em className="selected-search-type">
                                      {category ? category : "Categories"}
                                    </em>
                                    <i className="lnr lnr-chevron-down" />
                                  </span>
                                </div>
                                <div
                                  className="wt-radioholder my-collapsible__content"
                                  ref={setCollapsibleElement}
                                >
                                  {categories.map((category, index) => (
                                    <span className="wt-radio" key={index}>
                                      <input
                                        id={`wt-freelancers${index}`}
                                        type="radio"
                                        placeholder="I’m looking for"
                                        name="selected_category"
                                        onChange={() =>
                                          setCategory(category.cat_name)
                                        }
                                        onClick={toggle}
                                      />
                                      <label htmlFor={`wt-freelancers${index}`}>
                                        {category.cat_name}
                                      </label>
                                    </span>
                                  ))}
                                  {props.errors.search_text &&
                                    props.touched.search_text && (
                                      <span className="text-danger">
                                        {props.errors.search_text}
                                      </span>
                                    )}
                                </div>

                                <button type="submit" className="wt-searchbtn">
                                  <i className="lnr lnr-magnifier" />
                                </button>
                              </div>
                            )}
                          </SlideToggle>
                        </div>
                      </fieldset>
                    </form>
                  )}
                </Formik>
                {/* <div className="wt-videoholder">
                  <div className="wt-videoshow">
                    <a
                      data-rel="prettyPhoto[video]"
                      href="https://www.youtube.com/watch?v=J37W6DjqT3Q"
                    >
                      <i className="fa fa-play" />
                    </a>
                  </div>
                  <div className="wt-videocontent">
                    <span className="shadow">
                      See For Yourself!
                      <em>How it works &amp; experience the ultimate joy.</em>
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
