import Axios from "axios"
import {
    Articles_REQUEST,
    Articles_SUCCESS,
    Articles_FAIL
} from "../constants/Articles"

export const LoadArticles = () => async (dispatch) => {
    dispatch({
        type: Articles_REQUEST
    });
    try {
        const { data } = await Axios.get(`${process.env.REACT_APP_API_URI}/api/v1/homeposts`);
        dispatch({ type: Articles_SUCCESS, payload: data});
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: Articles_FAIL, payload: message });
    }
};