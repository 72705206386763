import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import JobListPending from "./JobListPending";
import JobListAccepted from "./JobListAccepted";
import JobListDispute from "./JobListDispute";
import JobListCompleted from "./JobListCompleted";
import JobListRejected from "./JobListRejected";
import JobListRejectedAfterAccept from "./JobListRejectedAfterAccept";
import JobListAll from "./JobListAll";
import JobListApprove from "./JobListApprove";
import JobListOnWay from "./JobListOnWay";

const JobList = () => {
  const navigate = useNavigate();
  const userLoginData = JSON.parse(localStorage.getItem("userInfo"));
  //console.log(userLoginData);
  const [allActionLoad, setAllActionLoad] = useState({
    pending: false,
    accepted: null,
    rejected: null,
    rejectedAfterAccept: null,
    onWay: null,
    completed: null,
    approve: null,
    dispute: null,
    allJobs: null,
  });
  const [selectedTab, setSelectedTab] = useState('pending');

  const tabClicked = (value) => {
    setAllActionLoad({
      ...allActionLoad,
      [value]: !allActionLoad[value],
    });
    setSelectedTab(value);
  };
  //job status : 0 - Pending, 1 - Accept, 2- Reject, 3- Reject after Accept,4- On Way, 5 - Complete, 6 - Approve, 7 - RaiseIssue
  //4, it means the professional is on the way to job location

  // console.log('userLoginData',userLoginData);
  return (
    <>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-9">
        <div className="wt-haslayout wt-dbsectionspace">
          <div className="wt-dashboardbox wt-dashboardtabsholder">
            <div className="d-flex w-100 justify-content-between wt-dashboardboxtitle">
              <h2>My Jobs</h2>
            </div>
            <div className="wt-dashboardtabs">
              <ul className="wt-tabstitle nav navbar-nav">
                <li className="nav-item textColor">
                  <a
                    className="active"
                    data-toggle="tab"
                    href="#tab-job-list-pending"
                    onClick={() => tabClicked("pending")}
                  >
                    Pending to Accept
                  </a>
                </li>

                <li className="nav-item textColor">
                  <a
                    data-toggle="tab"
                    href="#tab-job-list-accepted"
                    onClick={() => tabClicked("accepted")}
                  >
                    Accepted Jobs
                  </a>
                </li>
                <li className="nav-item textColor">
                  <a
                    data-toggle="tab"
                    href="#tab-job-list-rejected"
                    onClick={() => tabClicked("rejected")}
                  >
                    Rejected
                  </a>
                </li>
                {/* <li className="nav-item textColor">
                  <a
                    data-toggle="tab"
                    href="#tab-job-list-rejected-after-accept"
                    onClick={() => tabClicked("rejectedAfterAccept")}
                  >
                    Rejected After Accept
                  </a>
                </li> */}
                <li className="nav-item textColor">
                  <a
                    data-toggle="tab"
                    href="#tab-job-list-onway"
                    onClick={() => tabClicked("onWay")}
                  >
                    On Way
                  </a>
                </li>
                <li className="nav-item textColor">
                  <a
                    data-toggle="tab"
                    href="#tab-job-list-complete"
                    onClick={() => tabClicked("completed")}
                  >
                    Completed
                  </a>
                </li>
                <li className="nav-item textColor">
                  <a
                    data-toggle="tab"
                    href="#tab-job-list-approve"
                    onClick={() => tabClicked("approve")}
                  >
                    Approve
                  </a>
                </li>
                <li className="nav-item textColor">
                  <a
                    data-toggle="tab"
                    href="#tab-job-list-dispute"
                    onClick={() => tabClicked("dispute")}
                  >
                    Dispute
                  </a>
                </li>
                <li className="nav-item textColor">
                  <a
                    data-toggle="tab"
                    href="#tab-job-list-all"
                    onClick={() => tabClicked("allJobs")}
                  >
                    All Jobs
                  </a>
                </li>
              </ul>
            </div>
            <div className="wt-tabscontent tab-content">
              <JobListPending
                user_role_type={userLoginData.user.role}
                update_status={allActionLoad["pending"]} job_status_type={selectedTab}
              />
              <JobListAccepted
                user_role_type={userLoginData.user.role}
                update_status={allActionLoad["accepted"]} job_status_type={selectedTab}
              />
              <JobListOnWay
                user_role_type={userLoginData.user.role}
                update_status={allActionLoad["onWay"]} job_status_type={selectedTab}
              />
              <JobListRejected
                user_role_type={userLoginData.user.role}
                update_status={allActionLoad["rejected"]} job_status_type={selectedTab}
              />
              {/* <JobListRejectedAfterAccept
                user_role_type={userLoginData.user.role}
                update_status={allActionLoad["rejectedAfterAccept"]}
              /> */}
              <JobListDispute
                user_role_type={userLoginData.user.role}
                update_status={allActionLoad["dispute"]} job_status_type={selectedTab}
              />
              <JobListApprove
                user_role_type={userLoginData.user.role}
                update_status={allActionLoad["approve"]} job_status_type={selectedTab}
              />
              <JobListCompleted
                user_role_type={userLoginData.user.role}
                update_status={allActionLoad["completed"]} job_status_type={selectedTab}
              />
              <JobListAll
                user_role_type={userLoginData.user.role}
                update_status={allActionLoad["allJobs"]} job_status_type={selectedTab}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobList;
