import {
    Menu_LIST_REQUEST,
    Menu_LIST_SUCCESS,
    Menu_LIST_FAIL
} from "../constants/menuConstants"

export const menuListReducer = (state = { loading: true}, action) => {
    switch (action.type) {
        case Menu_LIST_REQUEST:
            return { loading: true };
        case Menu_LIST_SUCCESS:
            return { loading: false, menuList:action.payload };
        case Menu_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};