import React from 'react'
import BankInfo from '../../../components/dashboard/profile/BankInfo'
import Header from '../../../components/Header'

const BankDetails = () => {
    localStorage.removeItem("add_bank_account");
    return (
        <>
            <Header />
            <main id="wt-main" className="wt-main-dashboard wt-haslayout">
                <section className="wt-haslayout wt-dbsectionspace">
                    <div className="row mt-5">
                        <BankInfo />
                    </div>
                </section>
            </main>
        </>
    )
}

export default BankDetails