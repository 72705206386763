import {
    SUBCATEGORY_LIST_REQUEST,
    SUBCATEGORY_LIST_SUCCESS,
    SUBCATEGORY_LIST_FAIL
} from "../constants/categoryConstants"

export const subcategoryListReducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case SUBCATEGORY_LIST_REQUEST:
            return { loading: true };
        case SUBCATEGORY_LIST_SUCCESS:
            return { loading: false, sub_categories: action.payload };
        case SUBCATEGORY_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};