import {
  MY_JOB_LIST_GET_REQUEST,
  MY_JOB_LIST_GET_SUCCESS,
  MY_JOB_LIST_GET_FAIL,
} from "../../constants/job";

export const getMyJobList = (state = { loading: true }, action) => {
  switch (action.type) {
    case MY_JOB_LIST_GET_REQUEST:
      return { loading: true };
    case MY_JOB_LIST_GET_SUCCESS:
      return { loading: false, data: action.payload };
    case MY_JOB_LIST_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
