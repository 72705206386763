import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Loader from "./common/Loader";
import Banner from "./Banner";
import Footer from "./Footer";
import Header from "./Header";
import Main from "./main/Main";
import { listCategories } from "../store/actions/categoryActions";
// import { MenuList } from "../actions/menuAction";
import { homeBanner } from "../store/actions/homeContentAction";
import { LoadArticles } from "../store/actions/articlesActions";
import { submitRegister } from "../store/actions/userActions";
import { FreelancerListData } from "../store/actions/freelancerListActions";
import { FreelancerListHomeData } from "../store/actions/freelancerListHomeActions";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categoryList = useSelector((state) => state.categoryList);
  const { loading } = categoryList;

  const { categories } = categoryList;
  const [loadingFlag, setLoadingFlag] = useState(loading);

  const add_bank_account = JSON.parse(localStorage.getItem('add_bank_account'));

  async function getData() {
    await Promise.all([
      dispatch(listCategories()),
      // dispatch(MenuList()),
      dispatch(homeBanner()),
      dispatch(LoadArticles()),
      dispatch(FreelancerListData()),
      dispatch(FreelancerListHomeData()),
    ]);
    setLoadingFlag(false);
  }

  useEffect(() => {
    dispatch(submitRegister(1, {}));
    if (add_bank_account === 0) {
      navigate("/add-bank-detail");
    }
    loading && getData();
  }, [dispatch]);
  return (
    <>
      <Header />
      {loadingFlag ? (
        <Loader />
      ) : (
        <>
          <Banner categories={categories} />
          <Main categories={categories} />
          <Footer />
        </>
      )}
    </>
  );
};

export default Home;
