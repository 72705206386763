import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CreateJobRightSide } from '../../store/actions/job/CreateJobRightSide';
import { unescapeHTML } from '../common/Common';

const ProfileRightSide = () => {
    const dispatch = useDispatch();
  const data_right_side = useSelector(
    (state) => state.data_right_side
  );
  const { loading } = data_right_side;

  useEffect( () => {
    loading && dispatch(CreateJobRightSide())
  }, [dispatch,loading]);

    return (
        <>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                <div className="wt-haslayout wt-dbsectionspace wt-codescansidebar">
                    {/* <div className="tg-authorcodescan wt-codescanholder">
                        <figure className="tg-qrcodeimg">
                            <img src="images/qrcode.png" alt="img description" />
                        </figure>
                        <div className="tg-qrcodedetail">
                            <span className="lnr lnr-laptop-phone" />
                            <div className="tg-qrcodefeat">
                                <h3>Scan with your <span>Smart Phone </span> To Get It Handy.</h3>
                            </div>
                        </div>
                        <div className="wt-codescanicons">
                            <span>Share Your Profile</span>
                            <ul className="wt-socialiconssimple">
                                <li className="wt-facebook"><a href="#"><i className="fa fa-facebook-f" /></a></li>
                                <li className="wt-twitter"><a href="#"><i className="fab fa-twitter" /></a></li>
                                <li className="wt-linkedin"><a href="#"><i className="fab fa-linkedin-in" /></a></li>
                                <li className="wt-clone"><a href="#"><i className="far fa-clone" /></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="wt-companyad">
                        <figure className="wt-companyadimg"><img src="images/add-img.jpg" alt="img description" /></figure>
                        <span>Advertisement  255px X 255px</span>
                    </div> */}

                    {!loading && data_right_side.article_data.isActive === 1 && (
                        <div
                        dangerouslySetInnerHTML={{
                            __html: unescapeHTML(data_right_side.article_data.blockContent),
                        }}
                        ></div>
                    )}

                </div>
            </div>

        </>
    )
}

export default ProfileRightSide
