import axios from "axios";
import {
  CREATE_JOB_RIGHT_SIDE_FAIL,
  CREATE_JOB_RIGHT_SIDE_REQUEST,
  CREATE_JOB_RIGHT_SIDE_SUCCESS,
} from "../../constants/job";

export const CreateJobRightSide = () => async (dispatch) => {
  dispatch({
    type: CREATE_JOB_RIGHT_SIDE_REQUEST,
  });
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URI}/api/v1/staticblock/right_side`
    );
    dispatch({ type: CREATE_JOB_RIGHT_SIDE_SUCCESS, payload: data.data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: CREATE_JOB_RIGHT_SIDE_FAIL, payload: message });
  }
};
