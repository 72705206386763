import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { size } from "lodash";
import { toast } from "react-toastify";
import {
  setRegisterData,
  submitRegister,
} from "../../../../store/actions/userActions";
import {
  setStateListByCountryId,
  setCityListBStateId,
} from "../../../../store/actions/common/commonActions";
import AlreadyAccount from "../common/AlreadyAccount";
import JoinBlockClient from "../common/JoinBlockClient";
import Loader from "../../../common/Loader";
import { GoogleAddressLatLong } from "../../../common/third_party/GoogleAddressLatLong";

const RegisterSecond = ({ step, nextStep, prevStep }) => {
  const dispatch = useDispatch();
  const data_country_list = useSelector((state) => state.data_country_list);
  const data_state_list = useSelector((state) => state.data_state_list);
  const data_city_list = useSelector((state) => state.data_city_list);
  const data_registration = useSelector((state) => state.data_registration);
  const [countryId, setCountryId] = useState(
    data_registration.loading
      ? ""
      : data_registration.data_registration.country_id
  );
  const [loader_state, setLoaderState] = useState(false);
  let countryName = !data_state_list.loading
    ? !data_state_list.hasOwnProperty("error")
      ? data_state_list.data_state_list.countries[0].name
      : ""
    : "";
  const handleChangeCountry = (event) => {
    setCountryId(event.target.value);
    dispatch(setStateListByCountryId(event.target.value));
    let register_stage_2 = { country_id: event.target.value };
    let registerData = {
      ...data_registration.data_registration,
      ...register_stage_2,
    };
    dispatch(setRegisterData(registerData));
  };
  const [stateId, setStateId] = useState(data_registration.loading
    ? ""
    : data_registration.data_registration.state_id);
  let stateName = !data_city_list.loading
    ? data_city_list.data_city_list.states[0].name
    : "";
  const handleChangeState = (event) => {
    setStateId(event.target.value);
    dispatch(setCityListBStateId(event.target.value));
    let register_stage_2 = { state_id: event.target.value };
    let registerData = {
      ...data_registration.data_registration,
      ...register_stage_2,
    };
    dispatch(setRegisterData(registerData));
  };
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");

  const handleChangeCity = (event) => {
    let register_stage_2 = { city: event.target.value };
    let registerData = {
      ...data_registration.data_registration,
      ...register_stage_2,
    };
    dispatch(setRegisterData(registerData));
  };

  const handleAddressChange = async (e) => {
    let address = e.target.value;
    let data = await GoogleAddressLatLong(address);
    setLat(data.lat);
    setLong(data.lng);

    let register_stage_2 = { address: address, lat: data.lat, lang: data.lng };
    let registerData = {
      ...data_registration.data_registration,
      ...register_stage_2,
    };
    dispatch(setRegisterData(registerData));
  };

  const Schema = Yup.object().shape({
    country_id: Yup.string().required("Country is Required"),
    state_id: Yup.string().required("State is Required"),
    city_id: Yup.string().required("City is Required"),
    address: Yup.string().required("Address is Required"),
    password: Yup.string()
      .min(6, "Please enter more than 5 characters")
      .max(20, "Please enter less then 20 characters")
      .required("Password is Required"),
    c_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
    // start_as: Yup.string().required("Start as required"),
    // company_emp_no: Yup.string().required("Phone Number is required"),
    // company_department: Yup.string().required("City is Required"),
    // newsletter: Yup.bool().oneOf([true], 'Newsletter is required')
    terms: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
  });
  return (
    <>
      <main id="wt-main" className="wt-main wt-haslayout wt-innerbgcolor">
        {/*Register Form Start*/}
        <div className="wt-haslayout wt-main-section">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-xs-12 col-sm-12 col-md-10 push-md-1 col-lg-8 push-lg-2">
                <div className="wt-registerformhold">
                  <div className="wt-registerformmain">
                    {loader_state && <Loader />}
                    <JoinBlockClient />
                    <div className="wt-joinforms">
                      <ul className="wt-joinsteps">
                        <li className="wt-done-next">
                          <NavLink to="#">
                            <i className="fa fa-check" />
                          </NavLink>
                        </li>
                        <li className="wt-active">
                          <NavLink to="#">02</NavLink>
                        </li>
                        <li>
                          <NavLink to="#">03</NavLink>
                        </li>
                        {/* <li>
                          <NavLink to="#">04</NavLink>
                        </li> */}
                      </ul>
                      <Formik
                        initialValues={{
                          country_id: data_registration.loading
                            ? ""
                            : data_registration.data_registration.country_id,
                          state_id: data_registration.loading
                            ? ""
                            : data_registration.data_registration.state_id,
                          city_id: data_registration.loading
                            ? ""
                            : data_registration.data_registration.city,
                          address: data_registration.loading
                            ? ""
                            : data_registration.data_registration.address,
                          password: data_registration.loading
                            ? ""
                            : data_registration.data_registration.password,
                          c_password: data_registration.loading
                            ? ""
                            : data_registration.data_registration.password,
                          company_emp_no: "",
                          company_department: "",
                          newsletter: false,
                          terms: false,
                        }}
                        validationSchema={Schema}
                        onSubmit={async (values) => {
                          setLoaderState(true);
                          let register_stage_2 = {
                            country: countryName,
                            country_id: values.country_id,
                            state: stateName,
                            state_id: values.state_id,
                            city: values.city_id,
                            address: values.address,
                            lat: lat,
                            lang: long,
                            password: values.password,
                            confirm_password: values.c_password,
                            role: 3, // 3 company, 4 = freelancer
                            company_emp_no: '',
                            company_department: '',
                            newsletter: values.newsletter,
                            terms: values.terms,
                          };
                          let registerData = {
                            ...data_registration.data_registration,
                            ...register_stage_2,
                          };

                          dispatch(setRegisterData(registerData));
                          let response = await dispatch(
                            submitRegister(2, registerData)
                          );
                          setLoaderState(false);

                          if (response.status === 201) {
                            nextStep();
                          } else {
                            toast.error(response.message);
                            prevStep();
                          }
                        }}
                      >
                        {(props) => (
                          <form
                            className="wt-formtheme wt-formregister"
                            onSubmit={props.handleSubmit}
                          >
                            <fieldset className="wt-registerformgroup">
                              <div className="form-group">
                                <span className="wt-select">
                                  <select
                                    className="chosen-select locations"
                                    data-placeholder="Country"
                                    name="country_id"
                                    onChange={(e) => {
                                      props.handleChange(e);
                                      handleChangeCountry(e);
                                    }}
                                    onBlur={props.handleBlur}
                                    value={countryId}
                                  >
                                    <option defaultValue value="">
                                      Country
                                    </option>
                                    {!data_country_list.loading &&
                                      size(
                                        data_country_list.data_country_list
                                          .countries
                                      ) > 0 &&
                                      data_country_list.data_country_list.countries.map(
                                        (v, index) => (
                                          <option value={v._id} key={index}>
                                            {v.name}
                                          </option>
                                        )
                                      )}
                                  </select>
                                  {props.errors.country_id &&
                                    props.touched.country_id && (
                                      <span className="text-danger">
                                        {props.errors.country_id}
                                      </span>
                                    )}
                                </span>
                              </div>
                              <div className="form-group form-group-half">
                                <span className="wt-select">
                                  <select
                                    className="chosen-select locations"
                                    data-placeholder="State"
                                    name="state_id"
                                    onChange={(e) => {
                                      props.handleChange(e);
                                      handleChangeState(e);
                                    }}
                                    onBlur={props.handleBlur}
                                    value={stateId}
                                  >
                                    <option defaultValue value="">
                                      State
                                    </option>
                                    {!data_state_list.loading &&
                                      size(
                                        data_state_list.data_state_list
                                          .countries
                                      ) > 0 &&
                                      data_state_list.data_state_list.countries.map(
                                        (v) =>
                                          size(v.states) > 0 &&
                                          v.states.map((sv, si) => (
                                            <option value={sv._id} key={si}>
                                              {sv.name}
                                            </option>
                                          ))
                                      )}
                                  </select>
                                  {props.errors.state_id &&
                                    props.touched.state_id && (
                                      <span className="text-danger">
                                        {props.errors.state_id}
                                      </span>
                                    )}
                                </span>
                              </div>
                              <div className="form-group form-group-half">
                                <span className="wt-select">
                                  <select
                                    className="chosen-select locations"
                                    data-placeholder="City"
                                    name="city_id"
                                    onChange={(e) => {
                                      props.handleChange(e);
                                      handleChangeCity(e);
                                    }}
                                    onBlur={props.handleBlur}
                                    value={props.values.city_id}
                                  >
                                    <option defaultValue value="">
                                      City
                                    </option>
                                    {!data_city_list.loading &&
                                      size(
                                        data_city_list.data_city_list.states
                                      ) > 0 &&
                                      data_city_list.data_city_list.states.map(
                                        (v) =>
                                          size(v.cities) > 0 &&
                                          v.cities.map((sv, si) => (
                                            <option value={sv.name} key={si}>
                                              {sv.name}
                                            </option>
                                          ))
                                      )}
                                  </select>
                                  {props.errors.city_id &&
                                    props.touched.city_id && (
                                      <span className="text-danger">
                                        {props.errors.city_id}
                                      </span>
                                    )}
                                </span>
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Address*"
                                  name="address"
                                  onChange={(e) => {
                                    props.handleChange(e);
                                    handleAddressChange(e);
                                  }}
                                  onBlur={props.handleBlur}
                                  value={props.values.address}
                                />
                                {props.errors.address &&
                                  props.touched.address && (
                                    <span className="text-danger">
                                      {props.errors.address}
                                    </span>
                                  )}
                              </div>
                              <div className="form-group form-group-half">
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Password*"
                                  name="password"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.password}
                                />

                                {props.errors.password &&
                                  props.touched.password && (
                                    <span className="text-danger">
                                      {props.errors.password}
                                    </span>
                                  )}
                              </div>
                              <div className="form-group form-group-half">
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Retype Password*"
                                  name="c_password"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.c_password}
                                />

                                {props.errors.c_password &&
                                  props.touched.c_password && (
                                    <span className="text-danger">
                                      {props.errors.c_password}
                                    </span>
                                  )}
                              </div>
                            </fieldset>
                            <fieldset className="wt-formregisterstart hide">
                              <div className="wt-title wt-formtitle"></div>
                              <div
                                className="wt-accordiondetails collapse show"
                                id="collapseOne"
                                aria-labelledby="headingOne"
                              >
                                <div className="wt-radioboxholder">
                                  <div className="wt-title">
                                    <h4>No. of employees you have</h4>
                                  </div>

                                  {props.errors.company_emp_no &&
                                    props.touched.company_emp_no && (
                                      <span className="text-danger">
                                        {props.errors.company_emp_no}
                                      </span>
                                    )}
                                  <span className="wt-radio">
                                    <Field
                                      id="wt-just"
                                      type="radio"
                                      checked
                                      value="1"
                                      name="company_emp_no"
                                    />
                                    <label htmlFor="wt-just">
                                      It's just me
                                    </label>
                                  </span>
                                  <span className="wt-radio">
                                    <Field
                                      id="wt-employees"
                                      type="radio"
                                      value="2-9"
                                      name="company_emp_no"
                                    />
                                    <label htmlFor="wt-employees">
                                      2 - 9 employees
                                    </label>
                                  </span>
                                  <span className="wt-radio">
                                    <Field
                                      id="wt-employees1"
                                      type="radio"
                                      value="10-99"
                                      name="company_emp_no"
                                    />
                                    <label htmlFor="wt-employees1">
                                      10 - 99 employees
                                    </label>
                                  </span>
                                  <span className="wt-radio">
                                    <Field
                                      id="wt-employees2"
                                      type="radio"
                                      value="100-499"
                                      name="company_emp_no"
                                    />
                                    <label htmlFor="wt-employees2">
                                      100 - 499 employees
                                    </label>
                                  </span>
                                  <span className="wt-radio">
                                    <Field
                                      id="wt-employees3"
                                      type="radio"
                                      value="500-1000"
                                      name="company_emp_no"
                                    />
                                    <label htmlFor="wt-employees3">
                                      500 - 1000 employees
                                    </label>
                                  </span>
                                  <span className="wt-radio">
                                    <Field
                                      id="wt-employees4"
                                      type="radio"
                                      value="1001"
                                      name="company_emp_no"
                                    />
                                    <label htmlFor="wt-employees4">
                                      More than 1000 employees
                                    </label>
                                  </span>
                                </div>
                                <div className="wt-radioboxholder">
                                  <div className="wt-title">
                                    <h4>Your Department?</h4>
                                  </div>
                                  {props.errors.company_department &&
                                    props.touched.company_department && (
                                      <span className="text-danger">
                                        {props.errors.company_department}
                                      </span>
                                    )}
                                  <span className="wt-radio">
                                    <Field
                                      id="wt-department"
                                      type="radio"
                                      checked
                                      value="Customer Service Or Operations"
                                      name="company_department"
                                    />
                                    <label htmlFor="wt-department">
                                      Customer Service or Operations
                                    </label>
                                  </span>
                                  <span className="wt-radio">
                                    <Field
                                      id="wt-department1"
                                      type="radio"
                                      value="Finance or Legal"
                                      name="company_department"
                                    />
                                    <label htmlFor="wt-department1">
                                      Finance or Legal
                                    </label>
                                  </span>
                                  <span className="wt-radio">
                                    <Field
                                      id="wt-department2"
                                      type="radio"
                                      value="Engineering or Product Management"
                                      name="company_department"
                                    />
                                    <label htmlFor="wt-department2">
                                      Engineering or Product Management
                                    </label>
                                  </span>
                                  <span className="wt-radio">
                                    <Field
                                      id="wt-department3"
                                      type="radio"
                                      value="Marketing or Sales"
                                      name="company_department"
                                    />
                                    <label htmlFor="wt-department3">
                                      Marketing or Sales
                                    </label>
                                  </span>
                                  <span className="wt-radio">
                                    <Field
                                      id="wt-department4"
                                      type="radio"
                                      value="Human Resources"
                                      name="company_department"
                                    />
                                    <label htmlFor="wt-department4">
                                      Human Resources
                                    </label>
                                  </span>
                                  <span className="wt-radio">
                                    <Field
                                      id="wt-department5"
                                      type="radio"
                                      value="Other"
                                      name="company_department"
                                    />
                                    <label htmlFor="wt-department5">
                                      Other
                                    </label>
                                  </span>
                                </div>
                                <div className="form-group wt-othersearch d-none">
                                  <input
                                    type="text"
                                    name="fullname"
                                    className="form-control"
                                    placeholder="Enter Your Department"
                                  />
                                </div>
                              </div>
                            </fieldset>
                            <fieldset className="wt-termsconditions">
                              <div className="wt-checkboxholder">
                                <span className="wt-checkbox">
                                  <input
                                    id="termsconditions"
                                    type="checkbox"
                                    name="newsletter"
                                    onChange={props.handleChange}
                                    value={true}
                                  />
                                  <label htmlFor="termsconditions">
                                    <span>Newsletter</span>
                                  </label>
                                  {props.errors.newsletter &&
                                    props.touched.newsletter && (
                                      <span className="text-danger">
                                        {props.errors.newsletter}
                                      </span>
                                    )}
                                </span>
                                <span className="wt-checkbox">
                                  <input
                                    id="termsconditions2"
                                    type="checkbox"
                                    name="terms"
                                    onChange={props.handleChange}
                                    value={true}
                                  />
                                  <label htmlFor="termsconditions2">
                                    <span>
                                      <NavLink to="/terms-and-conditions">
                                        Terms &amp; Conditions
                                      </NavLink>
                                    </span>
                                  </label>
                                  {props.errors.terms &&
                                    props.touched.terms && (
                                      <span className="text-danger">
                                        {props.errors.terms}
                                      </span>
                                    )}
                                </span>
                                <button
                                  className="wt-btn"
                                  type="button"
                                  onClick={() => prevStep()}
                                >
                                  <i className="fa fa-arrow-left"></i>
                                </button>
                                <button
                                  className="wt-btn pull-right"
                                  type="submit"
                                >
                                  Continue
                                </button>
                              </div>
                            </fieldset>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                  <AlreadyAccount />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Register Form End*/}
      </main>
    </>
  );
};

export default RegisterSecond;
