import Axios from "axios";
import {
  FREELANCER_LIST_REQUEST,
  FREELANCER_LIST_SUCCESS,
  FREELANCER_LIST_FAIL,
} from "../constants/freelancerConstants";
import { get } from "lodash";

export const FreelancerListData = (filter_data_string='') => async (dispatch) => {
  dispatch({
    type: FREELANCER_LIST_REQUEST,
  });
  try {
    let { data } = await Axios.get(
      `${process.env.REACT_APP_API_URI}/api/v1/work-mate-users`+filter_data_string
    );
    let list = get(data, "users", []).map((v) => ({ ...v, is_invited: false }));
    data.users = list;
    dispatch({ type: FREELANCER_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: FREELANCER_LIST_FAIL, payload: message });
  }
};
