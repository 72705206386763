import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyB1BDFjbDjQuJ4yWDywOSdD7_sBwfud99U",
    authDomain: "jugusu-chat.firebaseapp.com",
    projectId: "jugusu-chat",
    storageBucket: "jugusu-chat.appspot.com",
    messagingSenderId: "558918435677",
    appId: "1:558918435677:web:ae706aa8dfb8b54264deb4",
    measurementId: "G-4MYG7TXE5H"
})

const db = firebaseApp.firestore()

export { db }