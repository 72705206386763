import React from "react";
import Footer from '../Footer'
import Header from '../Header'
import { useLocation, Navigate } from "react-router-dom";

const MembershipPaySuccess = () => {
    const { state } = useLocation();
    const { amount, ref_id, membershipId } = state;

    //console.log(membershipId)


    if (state === null) {
        return <Navigate to="/" />;
    }
    return (
        <>
            <Header />
            <main className="payment-success">
                <div className="msg">
                    <h1>Thankyou!</h1>
                    {`Payment of $${amount} has been recieved `}
                    <br />
                    {`Reference No. ${ref_id} `}
                </div>
                <div className="btn-wrapper">
                    <a href={`/membership/`}
                        className="btn btn-outline-primary mb-5"
                    >
                        Back to Membership Page
                    </a>
                </div>

            </main >
            <Footer />
        </>
    )
}

export default MembershipPaySuccess