import React from "react";
import Experience from "./Experience";
import Personalnfo from "./Personalnfo";
import AvailabilitySetting from "./AvailabilitySetting";
import CategorySetting from "./CategorySetting";
import Projects from "./Projects";
import BankInfo from "./BankInfo";
//import SkillSet from "./SkillSet";

const Profile = () => {
  const userLoginData = JSON.parse(localStorage.getItem("userInfo"));
  //console.log('userLoginData', userLoginData.user.role);
  return (
    <>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-9">
        <div className="wt-haslayout wt-dbsectionspace">
          <div className="wt-dashboardbox wt-dashboardtabsholder">
            <div className="wt-dashboardboxtitle">
              <h2>My Profile</h2>
            </div>
            <div className="wt-dashboardtabs">
              <ul className="wt-tabstitle nav navbar-nav">
                <li className="nav-item textColor">
                  <a className="active" data-toggle="tab" href="#wt-skills">
                    Personal Details &amp; Skills
                  </a>
                </li>
                {(userLoginData.user.role === 4 || userLoginData.user.role === "4") && (
                  <>
                    {/* <li className="nav-item textColor">
                      <a data-toggle="tab" href="#wt-education">
                        Experience &amp; Education
                      </a>
                    </li>
                    <li className="nav-item textColor">
                      <a data-toggle="tab" href="#wt-awards">
                        Projects &amp; Awards
                      </a>
                    </li> */}
                    <li className="nav-item textColor">
                      <a data-toggle="tab" href="#tab-availability-setting">
                        Availability Setting
                      </a>
                    </li>
                    <li className="nav-item textColor">
                      <a data-toggle="tab" href="#tab-category-setting">
                        Category Setting
                      </a>
                    </li>
                    {/* <li className="nav-item textColor">
                      <a data-toggle="tab" href="#tab-skill-setting">
                        Skills Setting
                      </a>
                    </li> */}
                  </>
                )}
                {/* <li className="nav-item textColor">
                  <a data-toggle="tab" href="#tab-bank-detail">
                    Bank Details
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="wt-tabscontent tab-content">
              <Personalnfo />
              {(userLoginData.user.role === 4 || userLoginData.user.role === "4") && (
                <>
                  {/* <Experience />
                  <Projects /> */}
                  <AvailabilitySetting />
                  <CategorySetting />
                  {/* <SkillSet /> */}
                </>
              )}
              {/* <BankInfo /> */}
            </div>
          </div>
        </div>
        {/* <div className="wt-updatall">
                    <i className="ti-announcement" />
                    <span>Update all the latest changes made by you, by just clicking on “Save &amp; Continue” button.</span>
                    <a className="wt-btn" href="#">Save &amp; Update</a>
                </div> */}
      </div>
    </>
  );
};

export default Profile;
