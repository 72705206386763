import {
    Articles_REQUEST,
    Articles_SUCCESS,
    Articles_FAIL
} from "../constants/Articles"

export const ArticlesReducer = (state = { loading: true}, action) => {
    switch (action.type) {
        case Articles_REQUEST:
            return { loading: true };
        case Articles_SUCCESS:
            return { loading: false, article_data:action.payload };
        case Articles_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};