export const HOME_BANNER_REQUEST = 'HOME_BANNER_REQUEST';
export const HOME_BANNER_FAIL = 'HOME_BANNER_FAIL';
export const HOME_BANNER_SUCCESS = 'HOME_BANNER_SUCCESS';
export const HOME_HOW_IT_WORK_REQUEST = 'HOME_HOW_IT_WORK_REQUEST';
export const HOME_HOW_IT_WORK_FAIL = 'HOME_HOW_IT_WORK_FAIL';
export const HOME_HOW_IT_WORK_SUCCESS = 'HOME_HOW_IT_WORK_SUCCESS';
export const HOME_TOP_FREELANCER_HEADING_REQUEST = 'HOME_TOP_FREELANCER_HEADING_REQUEST';
export const HOME_TOP_FREELANCER_HEADING_FAIL = 'HOME_TOP_FREELANCER_HEADING_FAIL';
export const HOME_TOP_FREELANCER_HEADING_SUCCESS = 'HOME_TOP_FREELANCER_HEADING_SUCCESS';
export const HOME_INTRODUCING_REQUEST = 'HOME_INTRODUCING_REQUEST';
export const HOME_INTRODUCING_FAIL = 'HOME_INTRODUCING_FAIL';
export const HOME_INTRODUCING_SUCCESS = 'HOME_INTRODUCING_SUCCESS';
export const HOME_LATEST_ARTICLE_REQUEST = 'HOME_LATEST_ARTICLE_REQUEST';
export const HOME_LATEST_ARTICLE_FAIL = 'HOME_LATEST_ARTICLE_FAIL';
export const HOME_LATEST_ARTICLE_SUCCESS = 'HOME_LATEST_ARTICLE_SUCCESS';
export const HOME_START_CMP_OR_FREE_REQUEST = 'HOME_START_CMP_OR_FREE_REQUEST';
export const HOME_START_CMP_OR_FREE_FAIL = 'HOME_START_CMP_OR_FREE_FAIL';
export const HOME_START_CMP_OR_FREE_SUCCESS = 'HOME_START_CMP_OR_FREE_SUCCESS';
export const HOME_EXPLORE_CATEGORY_REQUEST = 'HOME_EXPLORE_CATEGORY_REQUEST';
export const HOME_EXPLORE_CATEGORY_FAIL = 'HOME_EXPLORE_CATEGORY_FAIL';
export const HOME_EXPLORE_CATEGORY_SUCCESS = 'HOME_EXPLORE_CATEGORY_SUCCESS';
