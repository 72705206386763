import {
    MY_AVAILABILITY_GET_REQUEST,
    MY_AVAILABILITY_GET_SUCCESS,
    MY_AVAILABILITY_GET_FAIL,
    MEMBERSHIP_PLAN_GET_REQUEST,
    MEMBERSHIP_PLAN_GET_SUCCESS,
    MEMBERSHIP_PLAN_GET_FAIL
} from "../../constants/profile"

export const getMyAvailability = (state = { loading: true}, action) => {
    switch (action.type) {
        case MY_AVAILABILITY_GET_REQUEST:
            return { loading: true };
        case MY_AVAILABILITY_GET_SUCCESS:
            return { loading: false, data:action.payload };
        case MY_AVAILABILITY_GET_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const getMembershipPlan = (state = { loading: true}, action) => {
    switch (action.type) {
        case MEMBERSHIP_PLAN_GET_REQUEST:
            return { loading: true };
        case MEMBERSHIP_PLAN_GET_SUCCESS:
            return { loading: false, data:action.payload };
        case MEMBERSHIP_PLAN_GET_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};