import Axios from "axios";
import {
  Menu_LIST_REQUEST,
  Menu_LIST_SUCCESS,
  Menu_LIST_FAIL,
} from "../constants/menuConstants";

export const MenuList = () => async (dispatch) => {
  dispatch({
    type: Menu_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      `${process.env.REACT_APP_API_URI}/api/v1/allmenus`
    );
    dispatch({ type: Menu_LIST_SUCCESS, payload: data.categories });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: Menu_LIST_FAIL, payload: message });
  }
};


