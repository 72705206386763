import React from "react";
import { size } from "lodash";
import { useNavigate } from "react-router-dom";
const FreelancersList = ({ freelancer, baseurl }) => {
  const navigate = useNavigate();
  const getFreelancerDetails = () => {
    navigate("/user/" + freelancer._id);
  };

  return (
    <>
      <div className="col-12 col-sm-12 col-md-6 col-lg-3 float-left mb-2">
        <div className="wt-freelanceritems">
          <div className="wt-userlistinghold wt-featured">
            <span className="wt-featuredtag">
              <img
                src="assets/images/featured.png"
                alt="img description"
                data-tipso="Plus Member"
                className="template-content tipso_style"
              />
            </span>
            <div className="wt-userlistingcontent">
              <figure>
                <img
                  src={
                    freelancer.profile_image
                      ? baseurl + freelancer.profile_image
                      : "/assets/images/profile/avtar250.jpg"
                  }
                  alt=""
                  className="img-sm"
                />
              </figure>
              <div className="wt-contenthead">
                <div className="wt-title">
                  <span className="btn" onClick={() => getFreelancerDetails()}>
                    <i className="fa fa-check-circle" />
                    {freelancer.first_name + " " + freelancer.last_name}
                  </span>
                  <h2>
                    {size(freelancer.work_profiles) > 0 &&
                      freelancer.work_profiles.work_title}
                  </h2>
                </div>
              </div>
              <div className="wt-viewjobholder">
                <ul>
                  <li>
                    <span>
                      <i className="far fa-money-bill-alt" />$
                      {size(freelancer.work_profiles) > 0 &&
                        freelancer.work_profiles.price}
                    </span>
                  </li>
                  <li>
                    <span>
                      {size(freelancer.profiles) > 0 &&
                        freelancer.profiles.country}
                    </span>
                  </li>
                  {/* <li><a href="#" className="wt-clicklike wt-clicksave"><i className="fa fa-heart" />{freelancer.like}</a></li> */}
                  {/* <li><a href="#" className="wt-freestars"><i className="fas fa-star"> </i>{freelancer.rating} <em> {freelancer.feedback}</em></a></li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreelancersList;
