import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { size } from "lodash";
import {
  getPendingJobRequest,
  sendAcceptJob,
  sendRejectJob
} from "../../../services/job/jobRequest";
import Loader from "../../common/Loader";
import JobListDetail from "./JobListDetail";
import Chatting from "../chatting";
import { APICall } from "../../../services";

const JobListPending = ({ update_status, user_role_type, job_status_type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeLoader, setActiveLoader] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [chatModalAction, setChatModalAction] = useState(false);

  const userLoginData = JSON.parse(localStorage.getItem("userInfo"));

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const jobAction = async (job_id, type) => {
    setActiveLoader(true);
    let post_data = { job_id: job_id };
    let response = { status: 0 };
    switch (type) {
      case "accept":
        response = await sendAcceptJob(post_data, dispatch);
        break;
      case "reject":
        response = await sendRejectJob(post_data, dispatch);
        break;
      default:
        break;
    }

    // response =
    //   type === "accept"
    //     ? await sendAcceptJob(post_data, dispatch)
    //     : await sendRejectJob(post_data, dispatch);

    if (response.status === 1) {
      toast("Success! Action has successfully completed");

      let job_list_data = jobList.filter((value) => {
        return value._id === job_id ? false : true;
      });
      //console.log(job_list_data);
      setJobList(job_list_data);
    } else {
      toast("Error! Please try again later");
    }
    setActiveLoader(false);
  };

  const onViewJobFunctionality = (jobId, adminpercentage) => async () => {
    const success_data = {
      amount: adminpercentage,
      transaction_type: 2,
      transaction_date: Date.now().toString(),
      statusCode: 200,
      ref_job_id: jobId ? jobId : "",
      remark: "admin fee",
    };

    const res_data = await APICall("post", "funds", success_data);
    if (res_data) {
      //toast(res_data.message);
      toast("Admin fee deducted successfully !");
      let res = await getPendingJobRequest(dispatch);
      setJobList(res.data);
      Object.keys(userLoginData).forEach((key) => {
        if (key === 'user') {
          userLoginData[key].wallet = userLoginData[key].wallet - adminpercentage;
        };
      });
      localStorage.setItem('userInfo', JSON.stringify(userLoginData));
    } else {
      toast("Error! Please try again later");
    }
  };
  const getWalletAmt = async () => {
    let data = await APICall("post", "user-wallet", { token: userInfo.token });

    const usersInfo = JSON.parse(localStorage.getItem('userInfo'));

    Object.keys(usersInfo).forEach((key) => {
      if (key === 'user') {
        usersInfo[key].wallet = data.userWalletAmt;
      };
    });
    localStorage.setItem('userInfo', JSON.stringify(usersInfo));
  }
  useEffect(() => {
    getWalletAmt(dispatch);
    if (update_status !== null && job_status_type === 'pending') {
      const getData = async () => {
        setActiveLoader(true);
        let res = await getPendingJobRequest(dispatch);
        setActiveLoader(false);
        setJobList(res.data);
      };
      getData();
      //console.log(job_status_type);
      const interval = setInterval(() => {
        getWalletAmt(dispatch);
        getData();
      }, 30 * 1000);
      return () => clearInterval(interval);

    }
  }, [dispatch, update_status, job_status_type]);
  return (
    <>
      {activeLoader && <Loader />}
      <div className="tab-pane  active fade show" id="tab-job-list-pending">
        <div className="wt-jobdetailsholder">
          <div className="wt-completejobholder">
            <div className="wt-tabscontenttitle">
              <h2>Pending Jobs</h2>
            </div>
            <div>
              {size(jobList) > 0 ? (
                jobList.map((value, index) => (
                  <div
                    className="wt-userlistinghold wt-featured wt-userlistingvtwo"
                    key={index}
                  >
                    {(user_role_type === 4 || user_role_type === "4")
                      && (userLoginData.user.wallet === undefined || userLoginData.user.wallet < value.adminpercentage) && value.adminfee === 0 && (
                        <div className="mt-3">
                          <button
                            onClick={() =>
                              navigate("/addfunds", {
                                state: { totalPrice: value.adminpercentage },
                              })
                            }
                            className="btn btn-warning"
                          >
                            Add Funds
                          </button>
                          <p className="mt-2">You do not have sufficent balance to view the job. Kindly add funds to your wallet.</p>
                        </div>
                      )}
                    {(user_role_type === 4 || user_role_type === "4")
                      && (userLoginData.user.wallet >= value.adminpercentage) && value.adminfee === 0 && (
                        <div className="mt-3">
                          <button
                            onClick={onViewJobFunctionality(value._id, value.adminpercentage)}
                            className="btn btn-warning"
                          >
                            View Jobs
                          </button>
                          <p className="mt-2">By clicking on "View Job", you are agree to pay the admin fee</p>
                        </div>
                      )}

                    {(user_role_type === 4 || user_role_type === "4") && value.adminfee === 1 && (
                      <>
                        <div className="wt-userlistingcontent wt-userlistingcontentvtwo">
                          <JobListDetail
                            list_value={value}
                            user_role_type={user_role_type}
                          />
                          <div className="wt-contenthead">
                            {(user_role_type === 4 || user_role_type === "4") && (
                              <span>
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  onClick={() => jobAction(value._id, "accept")}
                                >
                                  Accept
                                </button>
                                &nbsp;
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => jobAction(value._id, "reject")}
                                >
                                  Reject
                                </button>
                              </span>
                            )}
                            <ul className="wt-saveitem-breadcrumb wt-userlisting-breadcrumb wt-displaynone">
                              <li>
                                <span className="wt-dashboraddoller">
                                  <i className="fa fa-dollar-sign" /> Professional
                                </span>
                              </li>
                              <li>
                                <span>
                                  <img
                                    src="/assets/images/flag/img-04.png"
                                    alt="img description"
                                    className="mCS_img_loaded"
                                  />{" "}
                                  England
                                </span>
                              </li>
                              <li>
                                <Link to="#" className="wt-clicksavefolder">
                                  <i className="far fa-folder" /> Type: Per Fixed
                                </Link>
                              </li>
                              <li>
                                <span className="wt-dashboradclock">
                                  <i className="far fa-clock" /> Duration: 15 Days
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="wt-rightarea wt-displaynone">
                            <div className="wt-btnarea">
                              <span> Project Awarded</span>
                              <Link to="#" className="wt-btn">
                                VIEW DETAILS
                              </Link>
                            </div>
                            <div className="wt-hireduserstatus">
                              <h4>Awarded</h4>
                              <span>
                                {userLoginData.user.first_name +
                                  " " +
                                  userLoginData.user.last_name}
                              </span>
                              <ul className="wt-hireduserimgs">
                                <li>
                                  <figure>
                                    <img
                                      src="/assets/images/user/userlisting/img-02.jpg"
                                      alt="img description"
                                      className="mCS_img_loaded"
                                    />
                                  </figure>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {value.professional ? (
                          chatModalAction === value._id ? (
                            <Chatting
                              key={value._id}
                              modalAction={chatModalAction === value._id}
                              setModalAction={setChatModalAction}
                              professionalId={value.professional._id}
                              professionalName={
                                value.professional.first_name +
                                " " +
                                value.professional.last_name
                              }
                              jobId={value._id}
                              name={value.job_title}
                            />
                          ) : (
                            <button
                              className="p-2 m-2 btn btn-info"
                              onClick={() => setChatModalAction(value._id)}
                            >
                              Chat
                            </button>
                          )
                        ) : (
                          ""
                          // "No professional assigned to this job, once a professional accepts invitation, you will be able to chat."
                        )}
                      </>
                    )}
                    {(user_role_type === 3 || user_role_type === "3") && (
                      <>
                        <div className="wt-userlistingcontent wt-userlistingcontentvtwo">
                          <JobListDetail
                            list_value={value}
                            user_role_type={user_role_type}
                          />
                          <div className="wt-contenthead">
                            <ul className="wt-saveitem-breadcrumb wt-userlisting-breadcrumb wt-displaynone">
                              <li>
                                <span className="wt-dashboraddoller">
                                  <i className="fa fa-dollar-sign" /> Professional
                                </span>
                              </li>
                              <li>
                                <span>
                                  <img
                                    src="/assets/images/flag/img-04.png"
                                    alt="img description"
                                    className="mCS_img_loaded"
                                  />{" "}
                                  England
                                </span>
                              </li>
                              <li>
                                <Link to="#" className="wt-clicksavefolder">
                                  <i className="far fa-folder" /> Type: Per Fixed
                                </Link>
                              </li>
                              <li>
                                <span className="wt-dashboradclock">
                                  <i className="far fa-clock" /> Duration: 15 Days
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="wt-rightarea wt-displaynone">
                            <div className="wt-btnarea">
                              <span> Project Awarded</span>
                              <Link to="#" className="wt-btn">
                                VIEW DETAILS
                              </Link>
                            </div>
                            <div className="wt-hireduserstatus">
                              <h4>Awarded</h4>
                              <span>
                                {userLoginData.user.first_name +
                                  " " +
                                  userLoginData.user.last_name}
                              </span>
                              <ul className="wt-hireduserimgs">
                                <li>
                                  <figure>
                                    <img
                                      src="/assets/images/user/userlisting/img-02.jpg"
                                      alt="img description"
                                      className="mCS_img_loaded"
                                    />
                                  </figure>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {value.professional ? (
                          chatModalAction === value._id ? (
                            <Chatting
                              key={value._id}
                              modalAction={chatModalAction === value._id}
                              setModalAction={setChatModalAction}
                              professionalId={value.professional._id}
                              professionalName={
                                value.professional.first_name +
                                " " +
                                value.professional.last_name
                              }
                              jobId={value._id}
                              name={value.job_title}
                            />
                          ) : (
                            <button
                              className="p-2 m-2 btn btn-info"
                              onClick={() => setChatModalAction(value._id)}
                            >
                              Chat
                            </button>
                          )
                        ) : (
                          ""
                          // "No professional assigned to this job, once a professional accepts invitation, you will be able to chat."
                        )}
                      </>
                    )}
                  </div>
                ))
              ) : (
                <div className="text-center">No Jobs Found</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobListPending;
