import Axios from "axios";
import { APICall } from "../index";
import { signout } from '../../store/actions/userActions';

export const authRegisterVerifyOTP = async (post_data, token) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    const headers = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await Axios.post(
      `${process.env.REACT_APP_API_URI}/api/v1/verifyOTP`,
      post_data,
      headers
    );
    res_data = data;
    status = data.status === 200 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
};

export const sendForgotPassword = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'forgotpassword', post_data);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 200 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
};

export const sendChangePassword = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'changepassword', post_data);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 200 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
};

export const setIsOnlineStatus = async (dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'is-online');
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
};