import React, { useEffect} from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { size, get } from "lodash";
import { MenuList } from "../../store/actions/menuAction";
const MenuListing = () => {
  const allMenuList = useSelector((state) => state.allMenuList);
  const { loading, menuList } = allMenuList;
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userRoleType = get(userInfo, "user.role", 0);




  useEffect(() => {
    async function getData() {
      await Promise.all([dispatch(MenuList())]);
    }
    loading && getData();
  }, []);

  const generateMenu = (listdata) => {
    if (!loading) {
      return (
        size(listdata) > 0 &&
        listdata.map((value, index) => {
          if (value.isPrivate === 1) {
            if(value.userRole > 0 && value.userRole!==userRoleType){
              return;
            }
          }
          if (!value.hasOwnProperty("submenu")) {
            return (
                <li
                  key={index}
                  className="menu-item-has-children page_item_has_children"
                >
                  <Link to={value.link} className="remove_icon">
                    {value.menu_name}
                  </Link>
                </li>
            );
          } else {
            return (
              <li
                key={index}
                className="menu-item-has-children page_item_has_children"
              >
                <Link to="#">{value.menu_name}</Link>
                <ul className="sub-menu">
                  {value.submenu !== undefined && generateMenu(value.submenu)}
                </ul>
              </li>
            );
          }
        })
      );
    }
  };

  return <>{generateMenu(menuList)}</>;
};

export default MenuListing;
