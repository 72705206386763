import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { size } from "lodash";
import { getJobsByStatus } from "../../../services/job/jobRequest";
import Loader from "../../common/Loader";
import JobListDetail from "./JobListDetail";

import Chatting from "../chatting";

const JobListApprove = ({ update_status, user_role_type, job_status_type }) => {
  const dispatch = useDispatch();
  const [LoaderState, setLoaderState] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [modalAction, setModalAction] = useState("");

  //job status : 0 - Pending, 1 - Accept, 2- Reject, 3- Reject after Accept, 5 - Complete, 6 - Approve, 7 - RaiseIssue
  // const post_data_default = { job_status: 7 };
  useEffect(() => {
    if (update_status !== null && job_status_type === 'approve') {
      const getData = async () => {
        setLoaderState(true);
        let res = await getJobsByStatus({ job_status: 6 }, dispatch);
        setJobList(res.data);
        setLoaderState(false);
      };
      getData();
      const interval = setInterval(() => {
        getData();
      }, 30 * 1000);
      return () => clearInterval(interval);
    }
  }, [dispatch, update_status, job_status_type]);
  //console.log(jobList, user_role_type)
  return (
    <>
      {LoaderState && <Loader />}
      <div className="tab-pane" id="tab-job-list-approve">
        <div className="wt-jobdetailsholder">
          <div className="wt-completejobholder">
            <div className="wt-tabscontenttitle">
              <h2>Approve Jobs</h2>
            </div>
            <div>
              {size(jobList) > 0 ? (
                jobList.map((value, index) => (
                  <div
                    className="wt-userlistinghold wt-featured wt-userlistingvtwo"
                    key={index}
                  >
                    <div className="wt-userlistingcontent wt-userlistingcontentvtwo">
                      <JobListDetail
                        list_value={value}
                        user_role_type={user_role_type}
                      />
                    </div>

                    {value.professional ? (
                      modalAction === value._id ? (
                        <Chatting
                          key={value._id}
                          modalAction={modalAction === value._id}
                          setModalAction={setModalAction}
                          professionalId={value.professional._id}
                          professionalName={
                            value.professional.first_name +
                            " " +
                            value.professional.last_name
                          }
                          jobId={value._id}
                          name={value.job_title}
                        />
                      ) : (
                        <button
                          className="p-2 m-2 btn btn-info"
                          onClick={() => setModalAction(value._id)}
                        >
                          Chat
                        </button>
                      )
                    ) : (
                      "No professional assigned to this job, once a professional accepts invitation, you will be able to chat."
                    )}

                  </div>
                ))
              ) : (
                <div className="text-center">No Jobs Found</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobListApprove;
