import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {get} from "lodash";
import { unescapeHTML } from "./common/Common";
import {
  LoadFooter1,
  LoadFooter2,
  LoadFooter3,
  LoadFooterNewsletter,
  LoadFooterRights,
} from "../store/actions/footer/footerActions";

const Footer = () => {
  const dispatch = useDispatch();
  const data_footer_1 = useSelector((state) => state.data_footer_1);
  const data_footer_2 = useSelector((state) => state.data_footer_2);
  const data_footer_3 = useSelector((state) => state.data_footer_3);
  const data_footer_rights = useSelector((state) => state.data_footer_rights);
  const data_footer_newsletter = useSelector(
    (state) => state.data_footer_newsletter
  );
  const { loading } = data_footer_newsletter;
  const [loadingFlag, setLoadingFlag] = useState(loading);
  async function getData() {
    await Promise.all([
      dispatch(LoadFooter1()),
      dispatch(LoadFooter2()),
      dispatch(LoadFooter3()),
      dispatch(LoadFooterNewsletter()),
      dispatch(LoadFooterRights()),
    ]);
    setLoadingFlag(false);
  }
  useEffect(() => {
    loading && (getData());
  }, []);

  return (
    <>
      {!loadingFlag && (
        <footer id="wt-footer" className="wt-footer wt-haslayout">
          <div className="wt-footerholder wt-haslayout">
            <div className="container">
              <div className="row">
                {get(data_footer_1, 'data.isActive',0) === 1 && (
                  <div
                    className="col-12 col-sm-12 col-md-6 col-lg-6"
                    dangerouslySetInnerHTML={{
                      __html: unescapeHTML(data_footer_1.data.blockContent),
                    }}
                  ></div>
                )}
                {get(data_footer_2, 'data.isActive',0) === 1 && (
                  <div
                    className="col-12 col-sm-6 col-md-3 col-lg-3"
                    dangerouslySetInnerHTML={{
                      __html: unescapeHTML(data_footer_2.data.blockContent),
                    }}
                  ></div>
                )}
                {get(data_footer_3, 'data.isActive',0) === 1 && (
                  <div
                    className="col-12 col-sm-6 col-md-3 col-lg-3"
                    dangerouslySetInnerHTML={{
                      __html: unescapeHTML(data_footer_3.data.blockContent),
                    }}
                  ></div>
                )}
              </div>
            </div>
          </div>
          {get(data_footer_newsletter, 'data.isActive',0) === 1 && (
            <div
              dangerouslySetInnerHTML={{
                __html: unescapeHTML(data_footer_newsletter.data.blockContent),
              }}
            ></div>
          )}

          {get(data_footer_rights, 'data.isActive',0) === 1 && (
            <div
              dangerouslySetInnerHTML={{
                __html: unescapeHTML(data_footer_rights.data.blockContent),
              }}
            ></div>
          )}
        </footer>
      )}
    </>
  );
};

export default Footer;
