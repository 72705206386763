import {
    HIRE_FREELANCER_GET_REQUEST,
    HIRE_FREELANCER_GET_SUCCESS,
    HIRE_FREELANCER_GET_FAIL
} from "../../constants/job"

export const getUserAvailability = (state = { loading: true}, action) => {
    switch (action.type) {
        case HIRE_FREELANCER_GET_REQUEST:
            return { loading: true };
        case HIRE_FREELANCER_GET_SUCCESS:
            return { loading: false, data:action.payload };
        case HIRE_FREELANCER_GET_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};