import {
    COMMON_COUNTRY_LIST_REQUEST,
    COMMON_COUNTRY_LIST_SUCCESS,
    COMMON_COUNTRY_LIST_FAIL,
    COMMON_STATE_LIST_REQUEST,
    COMMON_STATE_LIST_SUCCESS,
    COMMON_STATE_LIST_FAIL,
    COMMON_CITY_LIST_REQUEST,
    COMMON_CITY_LIST_SUCCESS,
    COMMON_CITY_LIST_FAIL,
    COMMON_LOGIN_POPUP_REQUEST,
    COMMON_LOGIN_POPUP_SUCCESS
  } from "../../constants/common/common";

export const getCountryListReducer = (state = { loading: true}, action) => {
    switch (action.type) {
        case COMMON_COUNTRY_LIST_REQUEST:
            return { loading: true };
        case COMMON_COUNTRY_LIST_SUCCESS:
            return { loading: false, data_country_list:action.payload };
        case COMMON_COUNTRY_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
export const getStateListReducer = (state = { loading: true}, action) => {
    switch (action.type) {
        case COMMON_STATE_LIST_REQUEST:
            return { loading: true };
        case COMMON_STATE_LIST_SUCCESS:
            return { loading: false, data_state_list:action.payload };
        case COMMON_STATE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
export const getCityListReducer = (state = { loading: true}, action) => {
    switch (action.type) {
        case COMMON_CITY_LIST_REQUEST:
            return { loading: true };
        case COMMON_CITY_LIST_SUCCESS:
            return { loading: false, data_city_list:action.payload };
        case COMMON_CITY_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const getLoginPopupReducer = (state = { data_login_popup_status: false}, action) => {
    switch (action.type) {
        case COMMON_LOGIN_POPUP_REQUEST:
            return { data_login_popup_status: false };
        case COMMON_LOGIN_POPUP_SUCCESS:
            return { data_login_popup_status:action.payload };
        default:
            return state;
    }
};