import {
  CREATE_JOB_RIGHT_SIDE_FAIL,
  CREATE_JOB_RIGHT_SIDE_REQUEST,
  CREATE_JOB_RIGHT_SIDE_SUCCESS,
  CREATE_JOB_TIME_FAIL,
  CREATE_JOB_TIME_REQUEST,
  CREATE_JOB_TIME_SUCCESS,
} from "../constants/job";

export const jobRightSideReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case CREATE_JOB_RIGHT_SIDE_REQUEST:
      return { loading: true };
    case CREATE_JOB_RIGHT_SIDE_SUCCESS:
      return { loading: false, article_data: action.payload };
    case CREATE_JOB_RIGHT_SIDE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
