import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { signout } from "../../store/actions/userActions";
import {
  setIsOnlineStatus
} from "../../services/auth/authAPI";
import Modal from "react-bootstrap/Modal";
//import isOnline from 'is-online';
import "bootstrap/dist/css/bootstrap.min.css";
import { useContext } from "react";


const UserNavBlock = ({ userinfo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([userinfo.user._id]);
  //const userIsOnline = userinfo.user.isOnline ? "o" : 'f';
  const [checkOnline, setCheckOnline] = useState(userinfo.user.isOnline);
  //console.log(userinfo.user.isOnline, checkOnline);
  // let isOnline;
  // if (userinfo.user.role === 4) {
  //   const [isOnline, setIsOnline] = userinfo.user.isOnline;
  // }

  // useEffect(() => {
  //   // const fun = async ()=> {
  //   //   /*setOnline(await isOnline())*/
  //   // }
  //   // fun();
  // })
  const setUserOnline = async (value) => {
    const usersInfo = JSON.parse(localStorage.getItem('userInfo'));
    Object.keys(usersInfo).forEach((key) => {
      if (key == 'user') {
        usersInfo[key].isOnline = !value;
      };
    });
    localStorage.setItem('userInfo', JSON.stringify(usersInfo));
    setCheckOnline(!value);
    let response = await setIsOnlineStatus(dispatch);
    if (response.status === 0) {
      toast("Success! User online status updated");
    } else {
      toast("Error! Please try again later");
    }
  };

  const [modalAction, setModalAction] = useState(() => {
    if (!cookies[userinfo.user._id]) {
      return true; //get cookie
    } else {
      return false;
    }
  });

  const closeModalBox = () => {
    setCookie(userinfo.user._id, true, {
      path: "/",
      expires: new Date(new Date().getTime() + 60 * 60 * 24 * 1000),
    });
    setModalAction(false);
  };
  const signoutHandler = () => {
    dispatch(signout());
    toast.success("Logout successfully");
  };
  return (
    <>
      <div className="wt-userlogedin">
        <div
          className="d-flex flex-column align-items-center"
          onClick={() => navigate("/user-profile")}
        >
          <figure className="wt-userimg">
            <img
              src={
                userinfo.user.profile_image
                  ? `${process.env.REACT_APP_API_URI}/` + userinfo.user.profile_image
                  : "/assets/images/profile/avtar250.jpg"
              }
              alt=" "
            />
          </figure>
          {/* <div className="wt-username">
                        <h3> {userinfo.user.first_name} {userinfo.user.last_name}</h3>
                    </div> */}
        </div>
        <nav className="wt-usernav">
          <ul>
            {/* <li>
              <Link to="/dashboard">
                <span>{userinfo.user.first_name}'s Dashboard</span>
              </Link>
            </li> */}
            {(userinfo.user.role === 4 || userinfo.user.role === "4") && (
              <li>
                <button onClick={() => setUserOnline(checkOnline)} className={`${checkOnline ? 'userOnline' : 'userOffline'}`} >
                  {checkOnline ? "Online" : "Offline"}
                </button>
              </li>
            )}

            {userinfo.user.role === 3 && (
              <li>
                <Link to="/create-job">
                  <span>Create Job</span>
                </Link>
              </li>
            )}
            <li>
              <Link to="/job-list">
                <span>Jobs</span>
              </Link>
            </li>
            <li>
              <Link to="/user-profile">
                <span>{userinfo.user.first_name}'s Profile</span>
              </Link>
            </li>
            <li>
              <Link to="/add-bank-detail">
                <span>Add Bank Details</span>
              </Link>
            </li>
            <li>
              <Link to="/addfunds">
                <span>Add to Wallet</span>
              </Link>
            </li>
            <li>
              <Link to="/withdraw-funds">
                <span>Withdraw Funds</span>
              </Link>
            </li>
            <li>
              <Link to="/transactions">
                <span>My Wallet</span>
              </Link>
            </li>
            <li>
              <Link to="/change-password">
                <span>Change Password</span>
              </Link>
            </li>
            <li>
              <Link to="/membership">
                <span>Membership</span>
              </Link>
            </li>
            <li>
              <Link to="#" onClick={signoutHandler}>
                Logout
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      {userinfo.user.role === 3 && modalAction && (
        <Modal show={modalAction}>
          <Modal.Header>Create Job</Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center">
              <button
                onClick={() => {
                  closeModalBox();
                  navigate("/create-job");
                }}
                type="button"
                className="wt-btn"
              >
                Do you want to Create Job?
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="btn btn-secondary" onClick={() => closeModalBox()}>
              Close
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default UserNavBlock;
