import {
    CATEGORY_LIST_REQUEST,
    CATEGORY_LIST_SUCCESS,
    CATEGORY_LIST_FAIL,
    SKILLS_LIST_REQUEST,
    SKILLS_LIST_SUCCESS,
    SKILLS_LIST_FAIL
} from "../constants/categoryConstants"

export const categoryListReducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case CATEGORY_LIST_REQUEST:
            return { loading: true };
        case CATEGORY_LIST_SUCCESS:
            return { loading: false, categories: action.payload };
        case CATEGORY_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const skillListReducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case SKILLS_LIST_REQUEST:
            return { loading: true };
        case SKILLS_LIST_SUCCESS:
            return { loading: false, skills: action.payload };
        case SKILLS_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};