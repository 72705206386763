import React from "react";
// import { useSelector} from 'react-redux'
import { Formik } from "formik";
import * as Yup from "yup";
import { get } from "lodash";
import GoogleMapReact from "google-map-react";
import { HiLocationMarker } from "react-icons/hi";

const Personalnfo = () => {
  // const dispatch = useDispatch();
  // const data_user_profile = useSelector((state) => state.data_user_profile);
  const userLoginData = JSON.parse(localStorage.getItem("userInfo"));
  const defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  };

  const Marker = () => (
    <div>
      <HiLocationMarker size={40} color="red" />
    </div>
  );

  const Schema = Yup.object().shape({
    userTitle: Yup.string()
      .max(10, "Please Enter valid title")
      .required("Full Name is Required"),
    firstName: Yup.string()
      .max(20, "Please enter less then 20 characters")
      .required("First Name is Required"),
    lastName: Yup.string()
      .max(20, "Please enter less then 20 characters")
      .required("Last Name is Required"),
    email: Yup.string()
      .email("Please enter valid email")
      .max(100, "Please enter less then 100 characters")
      .required("Email is required"),
    mobileNumber: Yup.string().required("Phone Number is required"),
  });

  //   useEffect(() => {
  //     dispatch(submitRegister(1,{}));
  //   })
  return (
    <>
      <div
        className="wt-personalskillshold tab-pane active fade show"
        id="wt-skills"
      >
        <div className="wt-yourdetails wt-tabsinfo">
          <div className="wt-tabscontenttitle">
            <h2>Your Details</h2>
          </div>
          <Formik
            initialValues={{
              userTitle: "",
              firstName: get(userLoginData, "user.first_name", ""),
              lastName: get(userLoginData, "user.last_name", ""),
              email: get(userLoginData, "user.email", ""),
              mobileNumber: "",
            }}
            validationSchema={Schema}
            onSubmit={(values, actions) => {
              let register_stage_1 = {
                user_title: values.userTitle,
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                contact_number: values.mobileNumber,
              };

              //   dispatch(setRegisterData(registerData));
              //   nextStep();
            }}
          >
            {(props) => (
              <form className="wt-formtheme wt-userform">
                <fieldset>
                  <div className="form-group form-group-half">
                    <input
                      type="text"
                      name="first_name"
                      className="form-control"
                      placeholder="First Name"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.firstName}
                    />
                  </div>
                  <div className="form-group form-group-half">
                    <input
                      type="text"
                      name="last_name"
                      className="form-control"
                      placeholder="Last Name"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.lastName}
                    />
                  </div>
                  <div className="form-group form-group-half">
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      value={props.values.email}
                      readOnly="readOnly"
                    />
                  </div>
                  <div className="form-group form-group-half">
                    <input
                      type="number"
                      name="rate"
                      className="form-control"
                      placeholder="Your Service Hourly Rate ($)"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="tagline"
                      className="form-control"
                      placeholder="Add Your Tagline Here"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      className="form-control"
                      placeholder="Description"
                      defaultValue={""}
                    />
                  </div>
                </fieldset>
              </form>
            )}
          </Formik>
        </div>
        <div className="wt-profilephoto wt-tabsinfo">
          <div className="wt-tabscontenttitle">
            <h2>Profile Photo</h2>
          </div>
          <div className="wt-profilephotocontent">
            {/* <div className="wt-description">
              <p>
                Consectetur adipisicing elit, sed do eiusmod tempor incididunt
                ut labore et dolore magna aliqua aut enim ad minim veniamac quis
                nostrud exercitation ullamco laboris.
              </p>
            </div> */}
            <form className="wt-formtheme wt-formprojectinfo wt-formcategory">
              <fieldset>
                <div className="form-group form-group-label">
                  <div className="wt-labelgroup">
                    <label htmlFor="filep">
                      <span className="wt-btn">Select Files</span>
                      <input type="file" name="file" id="filep" />
                    </label>
                    <span>Drop files here to upload</span>
                    <em className="wt-fileuploading">
                      Uploading
                      <i className="fa fa-spinner fa-spin" />
                    </em>
                  </div>
                </div>
                {/* <div className="form-group">
                  <ul className="wt-attachfile wt-attachfilevtwo">
                    <li className="wt-uploadingholder wt-companyimg-uploading">
                      <div className="wt-uploadingbox">
                        <figure>
                          <img
                            src="images/company/img-07.jpg"
                            alt="img description"
                          />
                        </figure>
                        <div className="wt-uploadingbar wt-uploading">
                          <span className="uploadprogressbar" />
                          <span>Profile Photo.jpg</span>
                          <em>
                            File size: 300 kb
                            <a href="#" className="lnr lnr-cross" />
                          </em>
                        </div>
                      </div>
                    </li>
                    <li className="wt-uploadingholder wt-companyimg-user">
                      <div className="wt-uploadingbox">
                        <figure>
                          <img
                            src="images/company/img-08.jpg"
                            alt="img description"
                          />
                        </figure>
                        <div className="wt-uploadingbar wt-uploading">
                          <span className="uploadprogressbar" />
                          <span>Profile Photo.jpg</span>
                          <em>
                            File size: 300 kb
                            <a href="#" className="lnr lnr-cross" />
                          </em>
                        </div>
                      </div>
                    </li>
                    <li className="wt-uploadingholder">
                      <div className="wt-uploadingbox">
                        <div className="wt-designimg">
                          <input
                            id="demoz"
                            type="radio"
                            name="employees"
                            defaultValue="company"
                            defaultChecked
                          />
                          <label htmlFor="demoz">
                            <img
                              src="images/company/img-09.jpg"
                              alt="img description"
                            />
                            <i className="fa fa-check" />
                          </label>
                        </div>
                        <div className="wt-uploadingbar wt-uploading">
                          <span className="uploadprogressbar" />
                          <span>Profile Photo.jpg</span>
                          <em>
                            File size: 300 kb
                            <a href="#" className="lnr lnr-cross" />
                          </em>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div> */}
              </fieldset>
            </form>
          </div>
        </div>
        {/* <div className="wt-bannerphoto wt-tabsinfo">
          <div className="wt-tabscontenttitle">
            <h2>Banner Photo</h2>
          </div>
          <div className="wt-profilephotocontent">
            <div className="wt-description">
              <p>
                Consectetur adipisicing elit, sed do eiusmod tempor incididunt
                ut labore et dolore magna aliqua aut enim ad minim veniamac quis
                nostrud exercitation ullamco laboris.
              </p>
            </div>
            <form className="wt-formtheme wt-formprojectinfo wt-formcategory">
              <fieldset>
                <div className="form-group form-group-label">
                  <div className="wt-labelgroup">
                    <label htmlFor="filew">
                      <span className="wt-btn">Select Files</span>
                      <input type="file" name="file" id="filew" />
                    </label>
                    <span>Drop files here to upload</span>
                    <em className="wt-fileuploading">
                      Uploading
                      <i className="fa fa-spinner fa-spin" />
                    </em>
                  </div>
                </div>
                {/* <div className="form-group">
                  <ul className="wt-attachfile wt-attachfilevtwo">
                    <li className="wt-uploadingholder">
                      <div className="wt-uploadingbox">
                        <div className="wt-designimg">
                          <input
                            id="demoq"
                            type="radio"
                            name="employees"
                            defaultValue="company"
                            defaultChecked
                          />
                          <label htmlFor="demoq">
                            <img
                              src="images/company/img-10.jpg"
                              alt="img description"
                            />
                            <i className="fa fa-check" />
                          </label>
                        </div>
                        <div className="wt-uploadingbar">
                          <span className="uploadprogressbar" />
                          <span>Banner Photo.jpg</span>
                          <em>
                            File size: 300 kb
                            <a href="#" className="lnr lnr-cross" />
                          </em>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div> * /}
              </fieldset>
              <fieldset>
                  <div className="form-group  mt-4">
                    <button className="wt-btn" type="button">
                      Save 
                    </button>
                  </div>
                </fieldset>
            </form>
          </div>
        </div> */}
        <div className="wt-location wt-tabsinfo">
          <div className="wt-tabscontenttitle">
            <h2>Your Location</h2>
          </div>
          <form className="wt-formtheme wt-userform">
            <fieldset>
              <div className="form-group form-group-half">
                <span className="wt-select">
                  <select>
                    <option value>United States</option>
                    <option value>China</option>
                    <option value>India</option>
                  </select>
                </span>
              </div>
              <div className="form-group form-group-half">
                <input
                  type="text"
                  name="address"
                  className="form-control"
                  placeholder="Your Address"
                />
              </div>
              {/* <div className="form-group wt-formmap">
                <div
                  id="wt-locationmap"
                  className="wt-locationmap"
                  style={{ position: "relative", overflow: "hidden" }}
                >
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      backgroundColor: "rgb(229, 227, 223)",
                    }}
                  >
                    <div style={{ overflow: "hidden" }} />
                    <div
                      className="gm-style"
                      style={{
                        position: "absolute",
                        zIndex: 0,
                        left: 0,
                        top: 0,
                        height: "100%",
                        width: "100%",
                        padding: 0,
                        borderWidth: 0,
                        margin: 0,
                      }}
                    >
                      <div
                        tabIndex={0}
                        aria-label="Map"
                        aria-roledescription="map"
                        role="group"
                        style={{
                          position: "absolute",
                          zIndex: 0,
                          left: 0,
                          top: 0,
                          height: "100%",
                          width: "100%",
                          padding: 0,
                          borderWidth: 0,
                          margin: 0,
                          cursor:
                            'url("https://maps.gstatic.com/mapfiles/openhand_8_8.cur"), default',
                          touchAction: "pan-x pan-y",
                        }}
                      >
                        <div
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              left: 0,
                              top: 0,
                              zIndex: 100,
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                zIndex: 0,
                              }}
                            />
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              left: 0,
                              top: 0,
                              zIndex: 101,
                              width: "100%",
                            }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              left: 0,
                              top: 0,
                              zIndex: 102,
                              width: "100%",
                            }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              left: 0,
                              top: 0,
                              zIndex: 103,
                              width: "100%",
                            }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              left: 0,
                              top: 0,
                              zIndex: 0,
                            }}
                          />
                        </div>
                        <div
                          style={{
                            zIndex: 3,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            padding: 0,
                            borderWidth: 0,
                            margin: 0,
                            left: 0,
                            top: 0,
                            touchAction: "pan-x pan-y",
                          }}
                        >
                          <div
                            style={{
                              zIndex: 4,
                              position: "absolute",
                              left: "50%",
                              top: "50%",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                zIndex: 104,
                                width: "100%",
                              }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                zIndex: 105,
                                width: "100%",
                              }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                zIndex: 106,
                                width: "100%",
                              }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                zIndex: 107,
                                width: "100%",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="gm-style-moc"
                          style={{
                            zIndex: 4,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            padding: 0,
                            borderWidth: 0,
                            margin: 0,
                            left: 0,
                            top: 0,
                            opacity: 0,
                          }}
                        >
                          <p className="gm-style-mot" />
                        </div>
                      </div>
                      <iframe
                        aria-hidden="true"
                        frameBorder={0}
                        tabIndex={-1}
                        style={{
                          zIndex: -1,
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          top: 0,
                          left: 0,
                          border: "none",
                        }}
                      />
                      <div
                        style={{
                          pointerEvents: "none",
                          width: "100%",
                          height: "100%",
                          boxSizing: "border-box",
                          position: "absolute",
                          zIndex: 1000002,
                          opacity: 0,
                          border: "2px solid rgb(26, 115, 232)",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="forGmap">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                  }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  center={defaultProps.center}
                >
                  <Marker
                    lat={defaultProps.center.lat}
                    lng={defaultProps.center.lng}
                  />
                </GoogleMapReact>
              </div>
              <div className="form-group form-group-half">
                <input
                  type="text"
                  name="text"
                  className="form-control"
                  placeholder="Enter Longitude (Optional)"
                />
              </div>
              <div className="form-group form-group-half">
                <input
                  type="text"
                  name="text"
                  className="form-control"
                  placeholder="Enter Latitude (Optional)"
                />
              </div>
            </fieldset>
            <fieldset>
              <div className="form-group  mt-4">
                <button className="wt-btn" type="button">
                  Save
                </button>
              </div>
            </fieldset>
          </form>
        </div>
        {/* <div className="wt-tabcompanyinfo wt-tabsinfo">
          <div className="wt-tabscontenttitle">
            <h2>Company Details</h2>
          </div>
          <div className="wt-accordiondetails">
            <div className="wt-radioboxholder">
              <div className="wt-title">
                <h4>No. of employees you have</h4>
              </div>
              <span className="wt-radio">
                <input
                  id="wt-just"
                  type="radio"
                  name="employees"
                  defaultValue="company"
                  defaultChecked
                />
                <label htmlFor="wt-just">It's just me</label>
              </span>
              <span className="wt-radio">
                <input
                  id="wt-employees"
                  type="radio"
                  name="employees"
                  defaultValue="company"
                />
                <label htmlFor="wt-employees">2 - 9 employees</label>
              </span>
              <span className="wt-radio">
                <input
                  id="wt-employees1"
                  type="radio"
                  name="employees"
                  defaultValue="company"
                />
                <label htmlFor="wt-employees1">10 - 99 employees</label>
              </span>
              <span className="wt-radio">
                <input
                  id="wt-employees2"
                  type="radio"
                  name="employees"
                  defaultValue="company"
                />
                <label htmlFor="wt-employees2">100 - 499 employees</label>
              </span>
              <span className="wt-radio">
                <input
                  id="wt-employees3"
                  type="radio"
                  name="employees"
                  defaultValue="company"
                />
                <label htmlFor="wt-employees3">500 - 1000 employees</label>
              </span>
              <span className="wt-radio">
                <input
                  id="wt-employees4"
                  type="radio"
                  name="employees"
                  defaultValue="company"
                />
                <label htmlFor="wt-employees4">More than 1000 employees</label>
              </span>
            </div>
            <div className="wt-radioboxholder">
              <div className="wt-title">
                <h4>Your Department?</h4>
              </div>
              <span className="wt-radio">
                <input
                  id="wt-department"
                  type="radio"
                  name="department"
                  defaultValue="department"
                  defaultChecked
                />
                <label htmlFor="wt-department">
                  Customer Service or Operations
                </label>
              </span>
              <span className="wt-radio">
                <input
                  id="wt-department1"
                  type="radio"
                  name="department"
                  defaultValue="department"
                />
                <label htmlFor="wt-department1">Finance or Legal</label>
              </span>
              <span className="wt-radio">
                <input
                  id="wt-department2"
                  type="radio"
                  name="department"
                  defaultValue="department"
                />
                <label htmlFor="wt-department2">
                  Engineering or Product Management
                </label>
              </span>
              <span className="wt-radio">
                <input
                  id="wt-department3"
                  type="radio"
                  name="department"
                  defaultValue="department"
                />
                <label htmlFor="wt-department3">Marketing or Sales</label>
              </span>
              <span className="wt-radio">
                <input
                  id="wt-department4"
                  type="radio"
                  name="department"
                  defaultValue="department"
                />
                <label htmlFor="wt-department4">Human Resources</label>
              </span>
              <span className="wt-radio">
                <input
                  id="wt-department5"
                  type="radio"
                  name="department"
                  defaultValue="department"
                />
                <label htmlFor="wt-department5">Other</label>
              </span>
            </div>
          </div>
        </div>
        <div className="wt-skills">
          <div className="wt-tabscontenttitle">
            <h2>My Skills</h2>
          </div>
          <div className="wt-skillscontent-holder">
            <form className="wt-formtheme wt-skillsform">
              <fieldset>
                <div className="form-group">
                  <div className="form-group-holder">
                    <span className="wt-select">
                      <select>
                        <option value>Select Your Skill</option>
                        <option value>HTML</option>
                        <option value>PHP</option>
                        <option value>JQUERY</option>
                      </select>
                    </span>
                    <input
                      type="number"
                      name="rate"
                      className="form-control"
                      placeholder="Rate Your Skill (0% to 100%)"
                    />
                  </div>
                </div>
                <div className="form-group wt-btnarea">
                  <a href="#" className="wt-btn">
                    Add Skills
                  </a>
                </div>
              </fieldset>
            </form>
            <div className="wt-myskills">
              <ul className="sortable list">
                <li>
                  <div className="wt-dragdroptool">
                    <a href="#" className="lnr lnr-menu" />
                  </div>
                  <span className="skill-dynamic-html">
                    PHP (<em className="skill-val">90</em>%)
                  </span>
                  <span className="skill-dynamic-field">
                    <input
                      type="text"
                      name="skills[1][percentage]"
                      defaultValue={90}
                    />
                  </span>
                  <div className="wt-rightarea">
                    <a href="#" className="wt-addinfo">
                      <i className="lnr lnr-pencil" />
                    </a>
                    <a href="#" className="wt-deleteinfo">
                      <i className="lnr lnr-trash" />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Personalnfo;
