import Axios from "axios"
import {
    SUBCATEGORY_LIST_REQUEST,
    SUBCATEGORY_LIST_SUCCESS,
    SUBCATEGORY_LIST_FAIL
} from "../constants/categoryConstants"

export const subCategoryList = (category_id) => async (dispatch) => {
    dispatch({
        type: SUBCATEGORY_LIST_REQUEST
    });
    try {
        const { data } = await Axios.get(`${process.env.REACT_APP_API_URI}/api/v1/categories/`+category_id);
        dispatch({ type: SUBCATEGORY_LIST_SUCCESS, payload: data.categories });
        return data;
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: SUBCATEGORY_LIST_FAIL, payload: message });
    }
};