import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as userActions from "../store/actions/userActions";

function useGeolocation() {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const [location, setLocation] = useState(null);

  const saveCoords = useCallback(
    async (coords) => {
      console.log(coords);
      if (coords)
        dispatch(
          userActions.saveUserLocation({
            userId: userLogin.userInfo.user._id,
            lat: coords.lat,
            lng: coords.lng,
          })
        );
      else
        dispatch(
          userActions.denyUserLocation({
            userId: userLogin.userInfo.user._id,
            lat: null,
            lng: null,
          })
        );
    },
    [dispatch, userLogin]
  );

  const fetchLocation = useCallback(async () => {
    if (navigator.geolocation)
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        let coords = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        saveCoords(coords);
      });
    else saveCoords(null);
  }, [saveCoords]);

  useEffect(() => {
    if (userLogin?.userInfo?.user?.role === 4) {
      fetchLocation();
    }
  }, [userLogin, fetchLocation]);

  return { location, isLocationEnabled: navigator.geolocation };
}

export default useGeolocation;
