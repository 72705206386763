export const FOOTER_1_REQUEST = 'FOOTER_1_REQUEST';
export const FOOTER_1_SUCCESS = 'FOOTER_1_SUCCESS';
export const FOOTER_1_FAIL = 'FOOTER_1_FAIL';
export const FOOTER_2_REQUEST = 'FOOTER_2_REQUEST';
export const FOOTER_2_SUCCESS = 'FOOTER_2_SUCCESS';
export const FOOTER_2_FAIL = 'FOOTER_2_FAIL';
export const FOOTER_3_REQUEST = 'FOOTER_3_REQUEST';
export const FOOTER_3_SUCCESS = 'FOOTER_3_SUCCESS';
export const FOOTER_3_FAIL = 'FOOTER_3_FAIL';
export const FOOTER_NEWSLETTER_REQUEST = 'FOOTER_NEWSLETTER_REQUEST';
export const FOOTER_NEWSLETTER_SUCCESS = 'FOOTER_NEWSLETTER_SUCCESS';
export const FOOTER_NEWSLETTER_FAIL = 'FOOTER_NEWSLETTER_FAIL';
export const FOOTER_RIGHTS_REQUEST = 'FOOTER_RIGHTS_REQUEST';
export const FOOTER_RIGHTS_SUCCESS = 'FOOTER_RIGHTS_SUCCESS';
export const FOOTER_RIGHTS_FAIL = 'FOOTER_RIGHTS_FAIL';

export const COMMON_COUNTRY_LIST_REQUEST = 'COMMON_COUNTRY_LIST_REQUEST';
export const COMMON_COUNTRY_LIST_SUCCESS = 'COMMON_COUNTRY_LIST_SUCCESS';
export const COMMON_COUNTRY_LIST_FAIL = 'COMMON_COUNTRY_LIST_FAIL';
export const COMMON_STATE_LIST_REQUEST = 'COMMON_STATE_LIST_REQUEST';
export const COMMON_STATE_LIST_SUCCESS = 'COMMON_STATE_LIST_SUCCESS';
export const COMMON_STATE_LIST_FAIL = 'COMMON_STATE_LIST_FAIL';
export const COMMON_CITY_LIST_REQUEST = 'COMMON_CITY_LIST_REQUEST';
export const COMMON_CITY_LIST_SUCCESS = 'COMMON_CITY_LIST_SUCCESS';
export const COMMON_CITY_LIST_FAIL = 'COMMON_CITY_LIST_FAIL';

export const COMMON_LOGIN_POPUP_REQUEST = 'COMMON_LOGIN_POPUP_REQUEST';
export const COMMON_LOGIN_POPUP_SUCCESS = 'COMMON_LOGIN_POPUP_SUCCESS';