import { APICall } from "../index";
import { signout } from '../../store/actions/userActions';

export const createJobRequest = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'create-job', post_data);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 201 ? 1 : 0;
    localStorage.removeItem("selectskill");
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
};


export const sendInviteJobRequest = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'job-invitations', post_data);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
};

export const getPendingJobRequest = async (dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'pending-invitations');
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data.data };
};

export const sendAcceptJob = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'job-invitation-accept', post_data);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
};
export const sendRejectJob = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'job-invitation-reject', post_data);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
};


export const sendOnWayToStartJob = async (job_id, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'startontheway/' + job_id, []);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
};


export const getAcceptedJobRequest = async (dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'accepted-jobs');
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data.jobs };
};

export const sendStartJob = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'start-job', post_data);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
};

export const sendEndJob = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'end-job', post_data);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
};

export const sendCompletedJob = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'completeJob', post_data);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data.data };
};

export const sendJobReleasePayment = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'payment_approve', post_data);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data.data };
};

export const jobPaymentSuccess = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'payment_success', post_data);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data.data };
};

export const jobPaymentFailed = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'payment_failed', post_data);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data.data };
};

export const sendJobRaiseDispute = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'raiseDispute', post_data);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data.data };
};

export const getJobDetails = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('get', 'jobs/' + post_data, []);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 200 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
};

export const getPaypalClientId = async (dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'paymentgateway');
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 200 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
};

export const sendJobDisputeByClient = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'add-job-dispute', post_data);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
}

export const sendJobDisputeDiscussion = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'add-job-dispute-discussion', post_data);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 201 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
}

export const getJobsByStatus = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'jobs', post_data);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 200 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data.jobs };
}

export const submitJobFeedback = async (post_data, dispatch) => {
  let res_data = [];
  let status = 0;
  let message = "";
  try {
    res_data = await APICall('post', 'review', post_data);
    res_data.status === 401 && dispatch(signout());
    status = res_data.status === 200 ? 1 : 0;
  } catch (error) {
    res_data = error.response.data;
    status = 0;
    message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
  }
  return { status: status, message: message, data: res_data };
}