import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, NavLink } from "react-router-dom";
import { size } from "lodash";
import Moment from "moment";
import Header from "../../../components/Header";
import LoadMoreBlock from "../../../components/LoadMoreBlock";
import ClientFeedbackBlock from "../../../components/users/ClientFeedbackBlock";
import ProjectStatisticBlock from "../../../components/users/ProjectStatisticBlock";
// import HireFreelancer from "../../../components/users/job/HireFreelancer";
import SkillsRatingBlock from "../../../components/users/SkillsRatingBlock";
import { FreelancerDetail } from "../../../store/actions/freelancerDetailActions";
import Loader from "../../../components/common/Loader";

const FreelancerInfo = () => {
  const freelancer_id = useParams();
  const dispatch = useDispatch();
  const freelancerDetail = useSelector((state) => state.freelancer_detail);
  const { loading } = freelancerDetail;

  const generateSkillSet = () => {
    let data = freelancerDetail;
    let skill = [];
    if (loading) {
      return skill;
    }
    if (
      size(data.freelancer_detail) > 0 &&
      size(data.freelancer_detail.users) > 0 &&
      size(data.freelancer_detail.users.work_profiles) > 0
    ) {
      skill = data.freelancer_detail.users.work_profiles.skills_set.split(",");
    }
    return skill;
  };

  const mySkills = generateSkillSet();

  useEffect(() => {
    dispatch(FreelancerDetail(freelancer_id.id));
  }, [dispatch, freelancer_id]);

  const clientFeedbacks = [
    {
      clinetImg: "img-01.jpg",
      clientName: "Themeforest Company",
      projectTitle: "Translation And Proof Reading (Multi Language)",
      projectType: "Beginner",
      country: "England",
      status: "In Progress",
      rating: "",
      clientComment: "",
    },
    {
      clinetImg: "img-02.jpg",
      clientName: "Videohive Studio",
      projectTitle:
        "Need Help Translating App Stringlist From English To Arabic",
      projectType: "Intermediate",
      country: "Canada",
      status: "Completed",
      rating: "4.5",
      clientComment:
        "Eiusmod tempor incididunt ut labore et dolore magna quis nostrud exercitation ullamco laboris.",
    },
    {
      clinetImg: "img-03.jpg",
      clientName: "Photodune Professionals",
      projectTitle: "Blog Post Writing In English Language",
      projectType: "Professional",
      country: "United States",
      status: "Completed",
      rating: "5",
      clientComment:
        "Consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliquaenim ad minim veniamac quis nostrud exercitation ullamco laboris.",
    },
  ];

  return (
    <>
      <Header extraClass="wt-headervtwo" />
      <div className="wt-haslayout wt-innerbannerholder wt-innerbannerholdervtwo">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-xs-12 col-sm-12 col-md-8 push-md-2 col-lg-6 push-lg-3"></div>
          </div>
        </div>
      </div>
      <main id="wt-main" className="wt-main wt-haslayout wt-innerbgcolor">
        {/* User Profile Start*/}
        {!loading ? (
          size(freelancerDetail.freelancer_detail) > 0 &&
            size(freelancerDetail.freelancer_detail.users) > 0 ? (
              <div className="wt-main-section wt-paddingtopnull wt-haslayout">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 float-left">
                      <div className="wt-userprofileholder">
                        <span className="wt-featuredtag">
                          <img
                            src="/assets/images/featured.png"
                            alt=" description"
                            data-tipso="Plus Member"
                            className="template-content tipso_style"
                          />
                        </span>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-3 float-left">
                          <div className="row">
                            <div className="wt-userprofile">
                              <figure>
                                <img
                                  src={
                                    freelancerDetail.freelancer_detail.users
                                      .profile_image
                                      ? freelancerDetail.freelancer_detail
                                        .baseurl +
                                      freelancerDetail.freelancer_detail.users
                                        .profile_image
                                      : "/assets/images/profile/avtar250.jpg"
                                  }
                                  alt=" "
                                />
                                {/* <div className="wt-userdropdown wt-online"></div> */}
                              </figure>
                              <div className="wt-title">
                                <h3>
                                  <i className={`fa fa-check-circle ${freelancerDetail.freelancer_detail.users.isOnline ? '' : 'grey'} `}> </i>&nbsp;
                                  {freelancerDetail.freelancer_detail.users
                                    .first_name +
                                    " " +
                                    freelancerDetail.freelancer_detail.users
                                      .last_name}
                                </h3>
                                {/* <span>
                                  5.0/5 <NavLink className="#">(860 Feedback)</NavLink> <br />
                                  Member since{" "}
                                  {Moment(
                                    freelancerDetail.freelancer_detail.users
                                      .created_at
                                  )
                                    .utc()
                                    .format("MMM DD, YYYY")}{" "}
                                  <br />
                                  <NavLink to="#">
                                    @
                                    {
                                      freelancerDetail.freelancer_detail.users
                                        .username
                                    }
                                  </NavLink>{" "}
                                  <NavLink to="#" className="wt-reportuser">
                                    Report User
                                  </NavLink>
                                </span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-9 float-left">
                          <div className="row">
                            {size(
                              freelancerDetail.freelancer_detail.users
                                .work_profiles
                            ) > 0 && (
                                <div className="wt-proposalhead wt-userdetails">
                                  <h2>
                                    {
                                      freelancerDetail.freelancer_detail.users
                                        .work_profiles.work_title
                                    }
                                  </h2>
                                  <ul className="wt-userlisting-breadcrumb wt-userlisting-breadcrumbvtwo">
                                    <li>
                                      <span>
                                        <i className="far fa-money-bill-alt" /> ${" "}
                                        {
                                          freelancerDetail.freelancer_detail.users
                                            .work_profiles.price
                                        }{" "}
                                  </span>
                                    </li>
                                    <li>
                                      <span>
                                        {/* <img
                                      src="/assets/images/flag/img-02.png"
                                      alt=" description"
                                    />{" "} */}
                                        {size(
                                          freelancerDetail.freelancer_detail.users
                                            .profiles
                                        ) > 0 &&
                                          freelancerDetail.freelancer_detail.users
                                            .profiles.country}
                                      </span>
                                    </li>
                                    {/* <li>
                                  <NavLink to="#" className="wt-clicksave">
                                    <i className="fa fa-heart" /> Save
                                  </NavLink>
                                </li> */}
                                  </ul>
                                  <div className="wt-description">
                                    <p>
                                      {
                                        freelancerDetail.freelancer_detail.users
                                          .work_profiles.work_description
                                      }
                                    </p>
                                  </div>
                                  <div className="wt-tag wt-widgettag">
                                    {size(freelancerDetail.freelancer_detail.users
                                      .work_profiles) > 0 &&
                                      freelancerDetail.freelancer_detail.users
                                        .work_profiles.skills_set.split(",").map((skill, index) => (
                                          <NavLink to="#" key={index}>
                                            {skill}
                                          </NavLink>
                                        ))}
                                  </div>
                                </div>
                              )}
                            {/* <div
                            id="wt-statistics"
                            className="wt-statistics wt-profilecounter"
                          >
                            <ProjectStatisticBlock
                              title1="Ongoing"
                              title2="Projects"
                              dataFrom="0"
                              dataTo="03"
                              dataSpeed="800"
                              dataRefreshInterval="03"
                              colorClass="wt-countercolor1"
                            />
                            <ProjectStatisticBlock
                              title1="Completed"
                              title2="Projects"
                              dataFrom="0"
                              dataTo="1503"
                              dataSpeed="800"
                              dataRefreshInterval="100"
                              colorClass="wt-countercolor2"
                            />
                            <ProjectStatisticBlock
                              title1="Cancelled"
                              title2="Projects"
                              dataFrom="0"
                              dataTo="02"
                              dataSpeed="800"
                              dataRefreshInterval="02"
                              colorClass="wt-countercolor4"
                            />
                            <ProjectStatisticBlock
                              title1="Served"
                              title2="Hours"
                              dataFrom="0"
                              dataTo="25"
                              dataSpeed="800"
                              dataRefreshInterval="100"
                              colorClass="wt-countercolor3"
                              extraKeyword="k"
                            />
                            <div className="wt-statisticcontent wt-countercolor1">
                                                    <h3 data-from={`0`} data-to={`03`} data-speed={`800`} data-refresh-interval={`03`}>03</h3>
                                                    <h4>Ongoing <br />Projects</h4>
                                                </div>
                            <div className="wt-statisticcontent wt-countercolor2">
                                                    <h3 data-from={0} data-to={`1503`} data-speed={`8000`} data-refresh-interval={`100`}>1503</h3>
                                                    <h4>Completed <br />Projects</h4>
                                                </div>
                                                <div className="wt-statisticcontent wt-countercolor4">
                                                    <h3 data-from={`0`} data-to={`02`} data-speed={`800`} data-refresh-interval={`02`}>02</h3>
                                                    <h4>Cancelled <br />Projects</h4>
                                                </div>
                                                <div className="wt-statisticcontent wt-countercolor3">
                                                    <h3 data-from={`0`} data-to={`25`} data-speed={`8000`} data-refresh-interval={`100`}>25</h3>
                                                    <em>k</em>
                                                    <h4>Served <br />Hours</h4>
                                                </div>
                            <p>
                              * Adpsicing elit sed do eiusmod tempor incididunt
                              ut labore et dolore.
                            </p>
                          </div> */}
                            <div className="text-left1 col-md-12">
                              {/* <HireFreelancer user_id={freelancer_id.id} /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* User Profile End*/}
                {/* User Listing Start*/}
                {/* <div className="container">
                <div className="row">
                  <div
                    id="wt-twocolumns"
                    className="wt-twocolumns wt-haslayout"
                  >
                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-8 float-left">
                      <div className="wt-usersingle">
                        <div className="wt-clientfeedback">
                          <div className="wt-usertitle wt-titlewithselect">
                            <h2>Client Feedback</h2>
                            <div className="form-group">
                              <span className="wt-select">
                                <select>
                                  <option value="Show All">Show All</option>
                                  <option value="One Page">One Page </option>
                                  <option value="Two Page">Two Page</option>
                                </select>
                              </span>
                            </div>
                          </div>
                          {clientFeedbacks.map((clientFeedback, index) => {
                            if (index % 2 > 0) {
                              return (
                                <ClientFeedbackBlock
                                  clientFeedback={clientFeedback}
                                  key={index}
                                />
                              );
                            } else {
                              return (
                                <ClientFeedbackBlock
                                  clientFeedback={clientFeedback}
                                  key={index}
                                  clsName="wt-bgcolor"
                                />
                              );
                            }
                          })}
                          <LoadMoreBlock />
                        </div>
                        <div className="wt-craftedprojects">
                          <div className="wt-usertitle">
                            <h2>Crafted Projects</h2>
                          </div>
                          <div className="wt-projects">
                            <div className="wt-project">
                              <figure>
                                <img
                                  src="/assets/images/projects/img-01.jpg"
                                  alt=" description"
                                />
                              </figure>
                              <div className="wt-projectcontent">
                                <h3>Themeforest</h3>
                                <NavLink to="#">www.themeforest.net</NavLink>
                              </div>
                            </div>
                            <div className="wt-project">
                              <figure>
                                <img
                                  src="/assets/images/projects/img-02.jpg"
                                  alt=" description"
                                />
                              </figure>
                              <div className="wt-projectcontent">
                                <h3>Videohive</h3>
                                <NavLink to="#">www.videohive.net</NavLink>
                              </div>
                            </div>
                            <div className="wt-project">
                              <figure>
                                <img
                                  src="/assets/images/projects/img-03.jpg"
                                  alt=" description"
                                />
                              </figure>
                              <div className="wt-projectcontent">
                                <h3>Codecanyon</h3>
                                <NavLink to="#">www.codecanyon.net</NavLink>
                              </div>
                            </div>
                            <div className="wt-project">
                              <figure>
                                <img
                                  src="/assets/images/projects/img-04.jpg"
                                  alt=" description"
                                />
                              </figure>
                              <div className="wt-projectcontent">
                                <h3>Graphicriver</h3>
                                <NavLink to="#">www.graphicriver.net</NavLink>
                              </div>
                            </div>
                            <div className="wt-project">
                              <figure>
                                <img
                                  src="/assets/images/projects/img-05.jpg"
                                  alt=" description"
                                />
                              </figure>
                              <div className="wt-projectcontent">
                                <h3>Photodune</h3>
                                <NavLink to="#">www.photodune.net</NavLink>
                              </div>
                            </div>
                            <div className="wt-project">
                              <figure>
                                <img
                                  src="/assets/images/projects/img-06.jpg"
                                  alt=" description"
                                />
                              </figure>
                              <div className="wt-projectcontent">
                                <h3>Audiojungle</h3>
                                <NavLink to="#">www.audiojungle.net</NavLink>
                              </div>
                            </div>
                            <div className="wt-btnarea">
                              <NavLink to="#" className="wt-btn">
                                Load More
                              </NavLink>
                            </div>
                          </div>
                        </div>
                        <div className="wt-experience">
                          <div className="wt-usertitle">
                            <h2>Experience</h2>
                          </div>
                          <div className="wt-experiencelisting-hold">
                            <div className="wt-experiencelisting wt-bgcolor">
                              <div className="wt-title">
                                <h3>Web &amp; Apps Project Manager</h3>
                              </div>
                              <div className="wt-experiencecontent">
                                <ul className="wt-userlisting-breadcrumb">
                                  <li>
                                    <span>
                                      <i className="far fa-building" /> Amento
                                      Tech
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <i className="far fa-calendar" /> Aug 2017
                                      - Till Now
                                    </span>
                                  </li>
                                </ul>
                                <div className="wt-description">
                                  <p>
                                    “ Consectetur adipisicing elit sed do
                                    eiusmod tempor incididunt ut labore et
                                    dolore magna aliquaenim ad minim veniamac
                                    quis nostrud exercitation ullamco laboris. ”
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="wt-experiencelisting">
                              <div className="wt-title">
                                <h3>Sr. PHP &amp; Laravel Developer</h3>
                              </div>
                              <div className="wt-experiencecontent">
                                <ul className="wt-userlisting-breadcrumb">
                                  <li>
                                    <span>
                                      <i className="far fa-building" /> Amento
                                      Tech
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <i className="far fa-calendar" /> Jun 2017
                                      - Jul 2018
                                    </span>
                                  </li>
                                </ul>
                                <div className="wt-description">
                                  <p>
                                    “ Consectetur adipisicing elit sed do
                                    eiusmod tempor incididunt ut labore et
                                    dolore magna aliquaenim ad minim veniamac
                                    quis nostrud exercitation ullamco laboris. ”
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="wt-experiencelisting wt-bgcolor">
                              <div className="wt-title">
                                <h3>PHP Developer</h3>
                              </div>
                              <div className="wt-experiencecontent">
                                <ul className="wt-userlisting-breadcrumb">
                                  <li>
                                    <span>
                                      <i className="far fa-building" /> Amento
                                      Tech
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <i className="far fa-calendar" /> Apr 2016
                                      - Jul 2017
                                    </span>
                                  </li>
                                </ul>
                                <div className="wt-description">
                                  <p>
                                    “ Consectetur adipisicing elit sed do
                                    eiusmod tempor incididunt ut labore et
                                    dolore magna aliquaenim ad minim veniamac
                                    quis nostrud exercitation ullamco laboris. ”
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="divheight" />
                          </div>
                        </div>
                        <div className="wt-experience wt-education">
                          <div className="wt-usertitle">
                            <h2>Education</h2>
                          </div>
                          <div className="wt-experiencelisting-hold">
                            <div className="wt-experiencelisting wt-bgcolor">
                              <div className="wt-title">
                                <h3>Web &amp; Apps Project Manager</h3>
                              </div>
                              <div className="wt-experiencecontent">
                                <ul className="wt-userlisting-breadcrumb">
                                  <li>
                                    <span>
                                      <i className="far fa-building" /> Amento
                                      Tech
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <i className="far fa-calendar" /> Aug 2017
                                      - Till Now
                                    </span>
                                  </li>
                                </ul>
                                <div className="wt-description">
                                  <p>
                                    “ Consectetur adipisicing elit sed do
                                    eiusmod tempor incididunt ut labore et
                                    dolore magna aliquaenim ad minim veniamac
                                    quis nostrud exercitation ullamco laboris. ”
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="wt-experiencelisting">
                              <div className="wt-title">
                                <h3>Sr. PHP &amp; Laravel Developer</h3>
                              </div>
                              <div className="wt-experiencecontent">
                                <ul className="wt-userlisting-breadcrumb">
                                  <li>
                                    <span>
                                      <i className="far fa-building" /> Amento
                                      Tech
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <i className="far fa-calendar" /> Jun 2017
                                      - Jul 2018
                                    </span>
                                  </li>
                                </ul>
                                <div className="wt-description">
                                  <p>
                                    “ Consectetur adipisicing elit sed do
                                    eiusmod tempor incididunt ut labore et
                                    dolore magna aliquaenim ad minim veniamac
                                    quis nostrud exercitation ullamco laboris. ”
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="wt-experiencelisting wt-bgcolor">
                              <div className="wt-title">
                                <h3>PHP Developer</h3>
                              </div>
                              <div className="wt-experiencecontent">
                                <ul className="wt-userlisting-breadcrumb">
                                  <li>
                                    <span>
                                      <i className="far fa-building" /> Amento
                                      Tech
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <i className="far fa-calendar" /> Apr 2016
                                      - Jul 2017
                                    </span>
                                  </li>
                                </ul>
                                <div className="wt-description">
                                  <p>
                                    “ Consectetur adipisicing elit sed do
                                    eiusmod tempor incididunt ut labore et
                                    dolore magna aliquaenim ad minim veniamac
                                    quis nostrud exercitation ullamco laboris. ”
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="divheight" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 float-left">
                      <aside id="wt-sidebar" className="wt-sidebar">
                        <div id="wt-ourskill" className="wt-widget">
                          <div className="wt-widgettitle">
                            <h2>My Skills</h2>
                          </div>
                          <div className="wt-widgetcontent wt-skillscontent">
                            {mySkills.map((mySkill) => (
                              //console.log(mySkill);
                              <SkillsRatingBlock
                                skill={mySkill}
                                key={mySkill}
                              />
                            ))}
                            <div className="wt-btnarea">
                              <NavLink to="#">View More</NavLink>
                            </div>
                          </div>
                        </div>
                        <div className="wt-widget wt-widgetarticlesholder wt-articlesuser">
                          <div className="wt-widgettitle">
                            <h2>Awards &amp; Certifications</h2>
                          </div>
                          <div className="wt-widgetcontent">
                            <div className="wt-particlehold">
                              <figure>
                                <img
                                  src="/assets/images/thumbnail/img-07.jpg"
                                  alt="description"
                                />
                              </figure>
                              <div className="wt-particlecontent">
                                <h3>
                                  <NavLink to="#">Top PHP Excel Skills</NavLink>
                                </h3>
                                <span>
                                  <i className="lnr lnr-calendar" /> Jun 27,
                                  2018
                                </span>
                              </div>
                            </div>
                            <div className="wt-particlehold">
                              <figure>
                                <img
                                  src="/assets/images/thumbnail/img-08.jpg"
                                  alt="description"
                                />
                              </figure>
                              <div className="wt-particlecontent">
                                <h3>
                                  <NavLink to="#">
                                    Monster Developer Award
                                  </NavLink>
                                </h3>
                                <span>
                                  <i className="lnr lnr-calendar" /> Apr 27,
                                  2018
                                </span>
                              </div>
                            </div>
                            <div className="wt-particlehold">
                              <figure>
                                <img
                                  src="/assets/images/thumbnail/img-09.jpg"
                                  alt="description"
                                />
                              </figure>
                              <div className="wt-particlecontent">
                                <h3>
                                  <NavLink to="#">
                                    Best Communication 2015
                                  </NavLink>
                                </h3>
                                <span>
                                  <i className="lnr lnr-calendar" /> May 27,
                                  2018
                                </span>
                              </div>
                            </div>
                            <div className="wt-particlehold">
                              <figure>
                                <img
                                  src="/assets/images/thumbnail/img-10.jpg"
                                  alt="description"
                                />
                              </figure>
                              <div className="wt-particlecontent">
                                <h3>
                                  <NavLink to="#">
                                    Best Logo Design Winner
                                  </NavLink>
                                </h3>
                                <span>
                                  <i className="lnr lnr-calendar" /> Aug 27,
                                  2018
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wt-proposalsr">
                          <div className="tg-authorcodescan tg-authorcodescanvtwo">
                            <figure className="tg-qrcodeimg">
                              <img
                                src="/assets/images/qrcode.png"
                                alt=" description"
                              />
                            </figure>
                            <div className="tg-qrcodedetail">
                              <span className="lnr lnr-laptop-phone" />
                              <div className="tg-qrcodefeat">
                                <h3>
                                  Scan with your <span>Smart Phone </span> To
                                  Get It Handy.
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wt-widget">
                          <div className="wt-widgettitle">
                            <h2>Similar Freelancers</h2>
                          </div>
                          <div className="wt-widgetcontent">
                            <div className="wt-widgettag wt-widgettagvtwo">
                              <NavLink to="#">PHP Developer</NavLink>
                              <NavLink to="#">PHP</NavLink>
                              <NavLink to="#">My SQL</NavLink>
                              <NavLink to="#">Business</NavLink>
                              <NavLink to="#">Website Development</NavLink>
                              <NavLink to="#">Collaboration</NavLink>
                              <NavLink to="#">Decent</NavLink>
                            </div>
                          </div>
                        </div>
                        <div className="wt-widget wt-sharejob">
                          <div className="wt-widgettitle">
                            <h2>Share This User</h2>
                          </div>
                          <div className="wt-widgetcontent">
                            <ul className="wt-socialiconssimple">
                              <li className="wt-facebook">
                                <NavLink to="#">
                                  <i className="fab fa-facebook-f" />
                                  Share on Facebook
                                </NavLink>
                              </li>
                              <li className="wt-twitter">
                                <NavLink to="#">
                                  <i className="fab fa-twitter" />
                                  Share on Twitter
                                </NavLink>
                              </li>
                              <li className="wt-linkedin">
                                <NavLink to="#">
                                  <i className="fab fa-linkedin-in" />
                                  Share on Linkedin
                                </NavLink>
                              </li>
                              <li className="wt-googleplus">
                                <NavLink to="#">
                                  <i className="fab fa-google-plus-g" />
                                  Share on Google Plus
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="wt-widget wt-reportjob">
                          <div className="wt-widgettitle">
                            <h2>Report This User</h2>
                          </div>
                          <div className="wt-widgetcontent">
                            <form className="wt-formtheme wt-formreport">
                              <fieldset>
                                <div className="form-group">
                                  <span className="wt-select">
                                    <select>
                                      <option value="reason">
                                        Select Reason
                                      </option>
                                      <option value="reason1">Reason1</option>
                                      <option value="reason2">Reason2</option>
                                      <option value="reason3">Reason3</option>
                                      <option value="reason4">Reason4</option>
                                    </select>
                                  </span>
                                </div>
                                <div className="form-group">
                                  <textarea
                                    className="form-control"
                                    placeholder="Description"
                                    defaultValue={""}
                                  />
                                </div>
                                <div className="form-group wt-btnarea">
                                  <NavLink to="#" className="wt-btn">
                                    Submit
                                  </NavLink>
                                </div>
                              </fieldset>
                            </form>
                          </div>
                        </div>
                      </aside>
                    </div>
                  </div>
                </div>
              </div> */}
                {/* User Listing End*/}
              </div>
            ) : (
              <div className="wt-main-section wt-paddingtopnull wt-haslayout">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <h5 className="help-text">No User Found</h5>
                    </div>
                  </div>
                </div>
              </div>
            )
        ) : (
            <Loader />
          )}
      </main>
    </>
  );
};

export default FreelancerInfo;
