import React, { useState, useEffect, useRef } from 'react'
import { db } from '../../../firebase'
import firebase from 'firebase';
import Modal from "react-bootstrap/Modal";
import './chatting.css'
import { mapValues, groupBy, omit } from "lodash";
import ChattingWindow from './chatingWindow';

const Chatting = ({ modalAction, setModalAction, professionalId, professionalName, name, jobId, closeChattingWindow, client_id }) => {
  const [messages, setMessages] = useState([])
  const [allMessages, setAllMessages] = useState([])
  const [msg, setMsg] = useState('')
  const [selectedProfessionalId, setSelectedProfessionalId] = useState('')
  const messagesEndRef = useRef(null)

  const userLoginData = JSON.parse(localStorage.getItem("userInfo"));

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }
  useEffect(() => {

    return () => {
      setMessages([]);
      setAllMessages([]);
      setMsg('');
      setSelectedProfessionalId('')
    };
  }, [modalAction])

  useEffect(() => {
    scrollToBottom()
  }, [messages]);

  useEffect(() => {

    // scrollToBottom()

    if (userLoginData.user.role === 3) {
      db.collection("messages").where("jobId", "==", jobId)
        .orderBy('createdAt').onSnapshot(item => {
          const data = item.docs.map(doc => doc.data())
          let grouped = mapValues(groupBy(data, 'professionalId'),
            mlist => mlist.map(message => omit(message, 'professionalId'))
          );
          setAllMessages(grouped)
          Object.keys(grouped).length && handleSelectProfessional(Object.keys(grouped)[0], grouped)
          // Object.keys(grouped).length ? setSelectedProfessionalId(allMessages[item][0]?.professionalName)
          if (selectedProfessionalId !== '') {
            setMessages(grouped[selectedProfessionalId])
          }
        });
    } else {
      db.collection("messages").where("jobId", "==", jobId).where("professionalId", "==", userLoginData.user._id)
        .orderBy('createdAt').limit(50).onSnapshot(item => {
          const data = item.docs.map(doc => doc.data())
          setMessages(data)
        });
    }
  }, [professionalId, selectedProfessionalId])

  const sendMessage = async (e) => {
    e.preventDefault()
    if (msg !== '') {
      setMsg('')
      await db.collection('messages').add({
        text: msg,
        senderId: userLoginData.user._id,
        // recieverId: otherUserId || '',
        professionalId,
        professionalName,
        jobId: jobId,
        senderName: userLoginData.user.first_name + ' ' + userLoginData.user.last_name,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        seen: false
      })
      if (userLoginData.user.role === 3) {
        await db.collection('notification').add({
          notificationFor: professionalId,
          notificationSeen: false,
          jobId: jobId,
          notificatioFrom: userLoginData.user.first_name + ' ' + userLoginData.user.last_name,
          createdAt: firebase.database.ServerValue.TIMESTAMP,
          jobName: name,
          message: msg,
          professionalId: professionalId,
          client_id: userLoginData.user._id
        })
      }
      else {
        await db.collection('notification').add({
          notificationFor: client_id,
          notificationSeen: false,
          jobId: jobId,
          notificatioFrom: userLoginData.user.first_name + ' ' + userLoginData.user.last_name,
          createdAt: firebase.database.ServerValue.TIMESTAMP,
          jobName: name,
          message: msg,
          professionalId: professionalId
        })
      }
    }
  }

  const handleSelectProfessional = (item, m) => {
    const mess = m || allMessages;
    setSelectedProfessionalId(item)
    setMessages(mess[item])
    const collection = db.collection('messages');
    collection.where("jobId", "==", jobId).where("senderId", "==", item).where("seen", "==", false).get().then(response => {
      let batch = firebase.firestore().batch()

      response.docs.forEach((doc) => {
        const docRef = collection.doc(doc.id)
        batch.update(docRef, { seen: true })
      })
      batch.commit().then(() => {
      })
    })
  }

  return (
    <>
      <Modal show={modalAction} onHide={() => { closeChattingWindow && closeChattingWindow(jobId); setModalAction(false) }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton={true}>
          {/* {
            userLoginData.user.role === 3 && messages?.length ?
              <img
                className='back-button'
                src={require('../../../assets/images/back-arrow.png')}
                onClick={() => {closeChattingWindow && closeChattingWindow(jobId);setModalAction(false)}}
              /> :
              ''} */}
          <i className="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => { closeChattingWindow && closeChattingWindow(jobId); setModalAction(false) }}></i>
          {name}
        </Modal.Header>
        <Modal.Body className="modalBody" style={{ overflow: 'hidden' }}>
          {/* <ChattingWindow
            allMessages={allMessages}
            messages={messages}
            handleSelectProfessional={handleSelectProfessional}
            selectedProfessionalId={selectedProfessionalId}
            userLoginData={userLoginData}
            messagesEndRef={messagesEndRef}
          /> */}
          <div className="msgs">
            {userLoginData.user.role === 3
              ?
              Object.keys(allMessages).length ?
                // Object.keys(allMessages).map(item =>
                //   <button key={item} onClick={() => handleSelectProfessional(item)}>
                //     {allMessages[item][0]?.professionalName}
                //   </button>
                // )
                <ChattingWindow
                  allMessages={allMessages}
                  messages={messages}
                  handleSelectProfessional={handleSelectProfessional}
                  selectedProfessionalId={selectedProfessionalId}
                  userLoginData={userLoginData}
                  messagesEndRef={messagesEndRef}
                />
                : "no messages yet! send a new message to assigned professional"
              :
              messages && userLoginData.user.role !== 3 && messages.map((item, index) => (
                <div key={index} style={{ "display": "grid", "justifyContent": `${item.senderId === userLoginData.user._id ? 'end' : 'start'}` }} >
                  <div
                    className={`senderName ${item.senderId === userLoginData.user._id ? 'senderNameRight' : 'senderNameLeft'}`}>{item.senderName}</div>
                  <div className={`msg ${item.senderId === userLoginData.user._id ? 'sent' : 'received'}`}>
                    <p>
                      {item.text}
                    </p>
                  </div>
                </div>
              )
              )

            }
            <div ref={messagesEndRef} />
          </div>
        </Modal.Body>
        <Modal.Footer className='modalFooter'>
          {userLoginData.user.role == 3 &&
            (messages?.length == 0 &&
              Object.keys(allMessages).length !== 0) ? '' :
            <form className='form-control' onSubmit={sendMessage}>
              <div className="sendmsg">
                <input value={msg} onChange={(e) => setMsg(e.target.value)} />
                <button type="submit">Send</button>
              </div>
            </form>
          }
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Chatting