import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import { useLocation, Navigate } from "react-router-dom";

const PaymentSuccess = () => {
  const { state } = useLocation();

  if (state == null) {
    return <Navigate to="/" />;
  }

  const { amount } = state;

  return (
    <>
      <Header />
      <main className="payment-success">
        <div className="msg">
          <h1>Thankyou!</h1>
          {`Funds of $${amount} have been withdrawn `}
          <br />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PaymentSuccess;
