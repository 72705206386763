import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoginPopupStatus } from "../../../../store/actions/common/commonActions";

const AlreadyAccount = () => {
  const dispatch = useDispatch();
  const data_login_popup_status = useSelector(
    (state) => state.data_login_popup_status
  );
  return (
    <>
      <div className="wt-registerformfooter">
        <span>
          Already Have an Account?{" "}
          <NavLink
            to="#"
            onClick={() =>
              dispatch(
                setLoginPopupStatus(
                  !data_login_popup_status.data_login_popup_status
                )
              )
            }
          >
            Login Now
          </NavLink>
        </span>
      </div>
    </>
  );
};

export default AlreadyAccount;
