import {
    FOOTER_1_REQUEST,
    FOOTER_1_SUCCESS,
    FOOTER_1_FAIL,
    FOOTER_2_REQUEST,
    FOOTER_2_SUCCESS,
    FOOTER_2_FAIL,
    FOOTER_3_REQUEST,
    FOOTER_3_SUCCESS,
    FOOTER_3_FAIL,
    FOOTER_NEWSLETTER_REQUEST,
    FOOTER_NEWSLETTER_SUCCESS,
    FOOTER_NEWSLETTER_FAIL,
    FOOTER_RIGHTS_REQUEST,
    FOOTER_RIGHTS_SUCCESS,
    FOOTER_RIGHTS_FAIL
} from "../../constants/common/common"

export const footerOneReducer = (state = { loading: true}, action) => {
    switch (action.type) {
        case FOOTER_1_REQUEST:
            return { loading: true };
        case FOOTER_1_SUCCESS:
            return { loading: false, data:action.payload };
        case FOOTER_1_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const footerTwoReducer = (state = { loading: true}, action) => {
    switch (action.type) {
        case FOOTER_2_REQUEST:
            return { loading: true };
        case FOOTER_2_SUCCESS:
            return { loading: false, data:action.payload };
        case FOOTER_2_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const footerThreeReducer = (state = { loading: true}, action) => {
    switch (action.type) {
        case FOOTER_3_REQUEST:
            return { loading: true };
        case FOOTER_3_SUCCESS:
            return { loading: false, data:action.payload };
        case FOOTER_3_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};


export const footerNewsletterReducer = (state = { loading: true}, action) => {
    switch (action.type) {
        case FOOTER_NEWSLETTER_REQUEST:
            return { loading: true };
        case FOOTER_NEWSLETTER_SUCCESS:
            return { loading: false, data:action.payload };
        case FOOTER_NEWSLETTER_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const footerRightsReducer = (state = { loading: true}, action) => {
    switch (action.type) {
        case FOOTER_RIGHTS_REQUEST:
            return { loading: true };
        case FOOTER_RIGHTS_SUCCESS:
            return { loading: false, data:action.payload };
        case FOOTER_RIGHTS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};