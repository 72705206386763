import {
    FREELANCER_DETAIL_REQUEST,
    FREELANCER_DETAIL_SUCCESS,
    FREELANCER_DETAIL_FAIL
} from "../constants/freelancerConstants"

export const FreelancerDetailReducer = (state = { loading: true}, action) => {
    switch (action.type) {
        case FREELANCER_DETAIL_REQUEST:
            return { loading: true };
        case FREELANCER_DETAIL_SUCCESS:
            return { loading: false, freelancer_detail:action.payload };
        case FREELANCER_DETAIL_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};