import React from "react";
import { NavLink } from "react-router-dom";
import _ from 'lodash';

const SearchContentBlock = ({
  filterExperience,
  filterLocation,
  filterHourlyRate,
  filterEnglishLevel,
  filterLanguage,
  filterRadius,
  callBackClearFilter
}) => {

  const clearFilterBtn = _.size(filterExperience) || _.size(filterLocation) || _.size(filterHourlyRate) || _.size(filterEnglishLevel) || _.size(filterLanguage)|| _.size(filterRadius) 
  const showFilter = (value) => {
      return (<>
        {_.size(value) ? <li className="alert alert-dismissable fade in">
          <span to="#" className="tag">
            {/* <i
              className="fa fa-times close"
              data-dismiss="alert"
              aria-label="close"
            />{" "} */}
            <span>{value}</span>
          </span>
        </li> : null }
        </>);
  };

  return (
    <>
      {clearFilterBtn ? <div className="wt-filterholder">
        <ul className="wt-filtertag">
          <li className="wt-filtertagclear">
            <span className="clear_filter_all" onClick={() =>callBackClearFilter()}>
              <i className="fa fa-times" /> <span>Clear All Filter</span>
            </span>
          </li>
          {showFilter(filterExperience)}
          {showFilter(filterLocation)}
          {showFilter(filterHourlyRate)}
          {showFilter(filterEnglishLevel)}
          {showFilter(filterLanguage)}
          {showFilter(filterRadius)}
        </ul>
      </div> : null}
    </>
  );
};

export default SearchContentBlock;
