import {
  MY_JOB_LIST_GET_REQUEST,
  MY_JOB_LIST_GET_SUCCESS,
  MY_JOB_LIST_GET_FAIL,
  CREATE_JOB_TIME,
} from "../../constants/job";
import { APICall } from "../../../services";
import { signout } from "../userActions";

export const setUserJobs = (post_data) => async (dispatch) => {
  dispatch({
    type: MY_JOB_LIST_GET_REQUEST,
  });
  try {
    const data = await APICall("post", "jobs", post_data);
    data.status === 401 && dispatch(signout());
    dispatch({ type: MY_JOB_LIST_GET_SUCCESS, payload: data });
    return data;
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: MY_JOB_LIST_GET_FAIL, payload: message });
  }
};
