import React from 'react';

const ChattingWindow = (props) => {
    const { allMessages, handleSelectProfessional, selectedProfessionalId, messages, userLoginData, messagesEndRef } = props;
    return (
        <>

            <div className="container p-0">
                <div className="row clearfix">
                    <div className="col-lg-12 p-0">
                        <div className="card chat-app">
                            <div id="plist" className="people-list">
                                <ul className="list-unstyled chat-list mb-0">
                                    {
                                        Object.keys(allMessages).length ?
                                            Object.keys(allMessages).map(item =>
                                                <li class={messages[0]?.professionalName !== allMessages[item][0]?.professionalName ? "clearfix notActiveChat" : "clearfix active"} onClick={() => handleSelectProfessional(item)}>
                                                    <div className="about" style={{ display: 'flex', width: '100%' }}>
                                                        <div className="name" style={{ width: '100%' }}>
                                                            {allMessages[item][0]?.professionalName}
                                                        </div>
                                                        <div className="status" style={{ color: 'blue' }}>{allMessages[item].filter(i => { return i.senderId !== userLoginData.user._id && (i.hasOwnProperty("seen") ? !i.seen : false) }).length || ''}</div>
                                                    </div>
                                                </li>
                                            )
                                            : "No Chats"
                                    }
                                </ul>
                            </div>
                            <div className="chat">
                                {
                                    selectedProfessionalId == '' ?
                                        <div style={{ textAlign: 'center' }}>Select The Chat</div> :
                                        <>
                                            <div className="chat-header clearfix">
                                                <div className="row">
                                                    <div className="col-lg-6">

                                                        <div className="chat-about">
                                                            <h6 className="m-b-0">{messages[0]?.professionalName || 'Name'}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="chat-history">
                                                <ul className="m-b-0">
                                                    {
                                                        messages && messages.map((item, index) => (
                                                            <>
                                                                <div key={index} style={{ "display": "grid", "justifyContent": `${item.senderId === userLoginData.user._id ? 'end' : 'start'}` }} >
                                                                    <div
                                                                        className={`senderName ${item.senderId === userLoginData.user._id ? 'senderNameRight' : 'senderNameLeft'}`}>{item.senderName}</div>
                                                                    <div className={`msg ${item.senderId === userLoginData.user._id ? 'sent' : 'received'}`}>
                                                                        <p>
                                                                            {item.text}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))
                                                    }
                                                    <div ref={messagesEndRef} />
                                                </ul>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ChattingWindow