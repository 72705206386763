import Axios from "axios";

export const APICall = async (method, action, post_data) => {
  let auth_token = localStorage.getItem("userInfoToken"); //userLoginData ? userLoginData.token : "";
  let res_data = [];
  try {
    const headers = auth_token
      ? {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        }
      : "";

    if (method === "get") {
      const { data } = await Axios.get(
        `${process.env.REACT_APP_API_URI}/api/v1/` + action,
        headers
      );
      res_data = data;
      return res_data;
    } else {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_API_URI}/api/v1/` + action,
        post_data,
        headers
      );
      res_data = data;
      return res_data;
    }
  } catch (error) {
    res_data = error.response;
    return res_data;
  }
};
