import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homeStartCmpOrFree } from "../../store/actions/homeContentAction";
import { unescapeHTML } from "../common/Common";

const Company = () => {
  const dispatch = useDispatch();
  const data_home = useSelector(
    (state) => state.data_home_start_cmp_or_freelancer
  );
  const { loading } = data_home;

  useEffect(() => {
    dispatch(homeStartCmpOrFree())
  }, [dispatch]);
  return (
    <>
      {!loading && data_home.data.isActive === 1 && (
        <div
          dangerouslySetInnerHTML={{
            __html: unescapeHTML(data_home.data.blockContent),
          }}
        ></div>
      )}
    </>
  );
};

export default Company;
