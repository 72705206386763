import React from 'react'

const JoinBlock = () => {
    return (
        <>
            <div className="wt-registerhead">
                <div className="wt-title">
                    <h3>Join as a Professional</h3>
                </div>
                {/* <div className="wt-description">
                    <p>Consectetur adipisicing elit sed dotem eiusmod tempor incune utnaem labore etdolore maigna aliqua enim poskina</p>
                </div> */}
            </div>
        </>
    )
}

export default JoinBlock
