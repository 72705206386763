import {
    FREELANCER_LIST_REQUEST,
    FREELANCER_LIST_SUCCESS,
    FREELANCER_LIST_FAIL
} from "../constants/freelancerConstants"

export const FreelancerListReducer = (state = { loading: true}, action) => {
    switch (action.type) {
        case FREELANCER_LIST_REQUEST:
            return { loading: true };
        case FREELANCER_LIST_SUCCESS:
            return { loading: false, freelancer_list:action.payload };
        case FREELANCER_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};