import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homeTopFreelancerHeading } from "../../store/actions/homeContentAction";
import { unescapeHTML } from "../common/Common";
import FreelancersList from "./FreelancersList";
import { size,get } from "lodash";

const Freelancers = () => {
  const freelancerData = useSelector((state) => state.freelancer_list_home);
  const baseurl = size(freelancerData.freelancer_list_home)>0 ? freelancerData.freelancer_list_home.baseurl:'';
  const dispatch = useDispatch();
  const data_home = useSelector(
    (state) => state.data_home_top_freelancer_heading
  );
  const { loading } = data_home;
  // const [loadingFlag, setLoadingFlag] = useState(loading);
  // async function getData() {
  //   await Promise.all([dispatch(homeTopFreelancerHeading())]);
  //   setLoadingFlag(false);
  // }

  useEffect(() => {
    dispatch(homeTopFreelancerHeading())
  }, [dispatch]);
  return (
    <>
      <section className="wt-haslayout wt-main-section wt-latearticles">
        <div className="container">
          <div className="row justify-content-md-center">
            {!loading && data_home.data.isActive === 1 && (
              <div className="col-lg-8"
                dangerouslySetInnerHTML={{
                  __html: unescapeHTML(data_home.data.blockContent),
                }}
              ></div>
            )}
            <div className="wt-topfreelancers">
              {!loading && get(freelancerData, 'freelancer_list_home.users',[]).map(
                  (freelancer, index) => (
                    <FreelancersList
                      freelancer={freelancer}
                      baseurl={baseurl}
                      key={index}
                    />
                  )
                )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Freelancers;
