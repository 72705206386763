import React from 'react';
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";

export default function ProfessionalUserList() {
  return (
    <>
      <Header />
      <div className="wt-haslayout wt-innerbannerholder">
        <h4 className="text-white">User list for Professional</h4>
      </div>
      <Footer />
    </>
  );
}
