import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { authRegisterVerifyOTP } from "../../../../services/auth/authAPI";
import AlreadyAccount from "../common/AlreadyAccount";
import { DirectLogin, submitRegister } from "../../../../store/actions/userActions";
import Loader from "../../../common/Loader";

const RegisterThird = ({ step, nextStep, prevStep }) => {
  const [loader_state, setLoaderState] = useState(false);
  const dispatch = useDispatch();
  const data_registration_process = useSelector(
    (state) => state.data_registration_process
  );
  const data_registration = useSelector((state) => state.data_registration);
  // const data_registration_process = useSelector((state) => state.data_registration_process);
  // console.log(data_registration_process);

  const Schema = Yup.object().shape({
    verify_code: Yup.string().required("OTP is Required"),
  });

  //  data_registration:
  // message: "User contact number already exists"
  // status: 400 == error
  //201 success

  return (
    <>
      <main id="wt-main" className="wt-main wt-haslayout wt-innerbgcolor">
        {/*Register Form Start*/}
        <div className="wt-haslayout wt-main-section">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-xs-12 col-sm-12 col-md-10 push-md-1 col-lg-8 push-lg-2">
                <div className="wt-registerformhold">
                  {!data_registration_process.loading ? (
                    data_registration_process.data_registration.status ===
                      201 ? (
                        <div className="wt-registerformmain">
                          {loader_state && <Loader />}
                          <div className="wt-registerhead">
                            <div className="wt-title">
                              <h3>You're Almost There</h3>
                            </div>
                            <div className="wt-description">
                              {/* <p>
                              Consectetur adipisicing elit sed dotem eiusmod
                              tempor incune utnaem labore etdolore maigna aliqua
                              enim poskina
                            </p> */}
                            </div>
                          </div>
                          <div className="wt-joinforms">
                            <ul className="wt-joinsteps">
                              <li className="wt-done-next">
                                <NavLink to="#">
                                  <i className="fa fa-check" />
                                </NavLink>
                              </li>
                              <li className="wt-done-next">
                                <NavLink to="#">
                                  <i className="fa fa-check" />
                                </NavLink>
                              </li>
                              <li className="wt-active">
                                <NavLink to="#">03</NavLink>
                              </li>
                              {/* <li>
                              <NavLink to="#">04</NavLink>
                            </li> */}
                            </ul>
                          </div>
                          <div className="wt-joinformc">
                            <figure className="wt-joinformsimg">
                              <img
                                src="assets/images/work/img-04.jpg"
                                alt="img description"
                              />
                            </figure>
                            <Formik
                              initialValues={{
                                verify_code: "",
                              }}
                              validationSchema={Schema}
                              onSubmit={async (values) => {
                                setLoaderState(true);
                                let register_stage_3 = {
                                  otp: values.verify_code,
                                  email:
                                    data_registration.data_registration.email,
                                  contact_number:
                                    data_registration.data_registration
                                      .contact_number,
                                };
                                let response = await authRegisterVerifyOTP(
                                  register_stage_3,
                                  data_registration_process.data_registration
                                    .token
                                );
                                if (response.status === 1) {
                                  // let data =
                                  //   data_registration_process.data_registration;
                                  dispatch(submitRegister(1, {}));
                                  toast.success("Success! Registration has successfully completed");
                                  dispatch(
                                    DirectLogin(
                                      data_registration_process.data_registration
                                    )
                                  );
                                  //  nextStep();
                                } else {
                                  setLoaderState(false);
                                  toast("Error! Invalid OTP");
                                }
                              }}
                            >
                              {(props) => (
                                <form
                                  className="wt-formtheme wt-formregister"
                                  onSubmit={props.handleSubmit}
                                >
                                  <fieldset>
                                    <div className="form-group">
                                      <label>
                                        We’ve sent verification code on your
                                        email.
                                      <NavLink to="#"> Why I need code?</NavLink>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Verification Code"
                                        name="verify_code"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.verify_code}
                                      />
                                      {props.errors.verify_code &&
                                        props.touched.verify_code && (
                                          <span className="text-danger">
                                            {props.errors.verify_code}
                                          </span>
                                        )}
                                    </div>
                                    <div className="form-group wt-btnarea">
                                      <button className="wt-btn" type="submit">
                                        Verify now
                                    </button>
                                    </div>
                                  </fieldset>
                                </form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      ) : (
                        <AlreadyAccount />
                      )
                  ) : (
                      ""
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Register Form End*/}
      </main>
    </>
  );
};

export default RegisterThird;
