import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { size } from "lodash";
import Moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader";
import { createJobRequest } from "../../../../services/job/jobRequest";
import { listCategories, listSkills } from "../../../../store/actions/categoryActions";
import CategorySection from "../../../auth/registration/professional/CategorySection";
import { APICall } from "../../../../services";
import { setRegisterData } from "../../../../store/actions/userActions";

const CreateJob = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeLoader, setActiveLoader] = useState(false);
  const [jobRequiredNow, setJobRequiredNow] = useState(true);

  const categoryList = useSelector((state) => state.categoryList);
  //const skillsList = useSelector((state) => state.skillsList);
  const [skillsList, setSkillsList] = useState([]);//useSelector((state) => state.skillsList);
  const data_registration = useSelector((state) => state.data_registration);
  //console.log("skillsList", skillsList);
  const [selectedSkillId, setSelectedSkillId] = useState('');
  const [parentCatId, setParentCatId] = useState('');
  const [childCatId, setChildCatId] = useState('');
  const selectedSets = JSON.parse(localStorage.getItem('selectskill'));
  let selectedSkill = '';
  let parentcatbyskill = '';
  let childcatbyskill = '';
  if (selectedSets !== null) {
    selectedSkill = selectedSets.skill;
    parentcatbyskill = selectedSets.parentcatbyskill;
    childcatbyskill = selectedSets.catbyskill;
  }

  //console.log(JSON.parse(localStorage.getItem('selectskill')).skill);

  const [subCat1, setSubCat1] = useState([]);
  const [subCat2, setSubCat2] = useState([]);


  const [subCatVisibility1, setSubCatVisibility1] = useState(0);
  const [subCatVisibility2, setSubCatVisibility2] = useState(0);


  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [categoryId, setCategoryId] = useState(
    data_registration.loading
      ? ""
      : data_registration.data_registration.category
  );

  const [date_picker, setSelectedDate] = useState(new Date());
  const handleSelectedDate = (value) => {
    setSelectedDate(value);
  };

  const [data_custom_slot, setCustomSlot] = useState([]);
  const handleCustomSlot = () => {
    let selected_date = Moment(date_picker).format("DD-MM-YYYY");

    let final_array = data_custom_slot.filter((value) => {
      return value.date !== selected_date ? true : false;
    });
    let new_arr = {
      date: selected_date,
      time_slot: [],
    };
    let main_array = [...final_array, new_arr];
    setCustomSlot(main_array);
  };

  const removeCustomSlot = (date) => {
    let final_array = data_custom_slot.filter((value) => {
      return value.date !== date ? true : false;
    });
    setCustomSlot(final_array);
  };

  const handleCustomTimeSlot = (v) => {
    if (startTime === "" && endTime === "") {
      toast.error("Error! Please add Time slot");
      return;
    }

    if (startTime >= endTime) {
      toast.error("Error! Start time must be greater than end time");
      return;
    }
    let data = data_custom_slot;
    let new_time_slot = startTime + " To " + endTime;
    setActiveLoader(true);
    for (let i = 0; i < data.length; i++) {
      if (data[i].date === v.date) {
        let time_slot = data[i]["time_slot"];
        if (!time_slot.includes(new_time_slot)) {
          data[i]["time_slot"] = [...time_slot, new_time_slot];
        }
      }
    }
    setCustomSlot(data);
    // setActiveLoader(false);
    setTimeout(() => {
      setActiveLoader(false);
    }, 1);
  };

  const removeCustomTimeSlot = (main_array, sub_value) => {
    setActiveLoader(true);
    let final_array = data_custom_slot;

    for (let i = 0; i < final_array.length; i++) {
      if (final_array[i].date === main_array.date) {
        let final_slot_array = main_array.time_slot.filter((value) => {
          return value === sub_value ? false : true;
        });
        final_array[i].time_slot = final_slot_array;
      }
    }

    // let final_array = data_custom_slot.filter((value) => {
    //   return value.date !== date ? true : false;
    // });
    setCustomSlot(final_array);
    setTimeout(() => {
      setActiveLoader(false);
    }, 1);
  };

  const [startTime, setStartTime] = useState("");
  const handleStartTime = (e) => {
    setStartTime(e.target.value);
  };
  const [endTime, setEndTime] = useState("");
  const handleEndTime = (e) => {
    setEndTime(e.target.value);
  };

  const getDurationData = () => {
    let duration = "";
    for (let i = 0; i < data_custom_slot.length; i++) {
      duration += (i > 0 ? "^" : "") + data_custom_slot[i].date + "~";
      for (let j = 0; j < data_custom_slot[i].time_slot.length; j++) {
        duration += (j > 0 ? "$" : "") + data_custom_slot[i].time_slot[j];
      }
    }
    return duration;
  };

  const Schema = Yup.object().shape({
    job_title: Yup.string().required("Title is Required"),
    description: Yup.string().required("Description is Required"),
    //category: Yup.string().required("Category is Required"),
    //skill: Yup.string().required("Skill is Required"),
  });

  async function getData() {
    await Promise.all([dispatch(listCategories())]);
  }

  async function getSkillData() {
    await Promise.all([dispatch(listSkills())]);
  }

  const loadSkillsData = async (category_id) => {
    let data = await APICall("post", "category-skills", { "category_id": category_id });
    if (data.status === 200) {
      setSkillsList(data);
    } else {
      let errMsg = data.message ? data.message : 'Somthing went wrong';
      toast.error(errMsg);
    }
  }
  const handleChangeCategory = async (e) => {
    if (e.target.value !== '') {
      let targetValue = e.target.value.split('_');
      setCategoryId(targetValue[0]);
      setSelectedCategoryId(targetValue[0]);
      if (e.target.getAttribute('data-tag') !== 'scat8' && targetValue[1] === 1) {
        const res_data = await APICall('get', 'categories/' + targetValue[0], []);//await APICall("get", "categories");
        if (res_data.status === 200) {
          if (e.target.getAttribute('data-tag') === 'cat') {
            setSubCat1(res_data);
            setSubCatVisibility1(1);
          } else if (e.target.getAttribute('data-tag') === 'scat1') {
            setSubCat2(res_data);
            setSubCatVisibility2(1);
            loadSkillsData(targetValue[0]);
          }
        } else {
          toast("Error! Something went wrong");
        }
      }
      let register_stage_2 = { category: targetValue[0] };
      let registerData = {
        ...data_registration.data_registration,
        ...register_stage_2,
      };
      dispatch(setRegisterData(registerData));
    } else {
      if (e.target.getAttribute('data-tag') === 'cat') {
        setSubCatVisibility2(0);
        // setSubCatVisibility3(0);
        // setSubCatVisibility4(0);
        // setSubCatVisibility5(0);
        // setSubCatVisibility6(0);
        // setSubCatVisibility7(0);
        // setSubCatVisibility8(0);
        setSubCat1([]);
        setSubCat2([]);
        // setSubCat3([]);
        // setSubCat4([]);
        // setSubCat5([]);
        // setSubCat6([]);
        // setSubCat7([]);
        // setSubCat8([]);
      }
      //else if (e.target.getAttribute('data-tag') == 'scat1') {
      // setSubCatVisibility3(0);
      // setSubCatVisibility4(0);
      // setSubCatVisibility5(0);
      // setSubCatVisibility6(0);
      // setSubCatVisibility7(0);
      // setSubCatVisibility8(0);
      //setSubCat2([]);
      // setSubCat3([]);
      // setSubCat4([]);
      // setSubCat5([]);
      // setSubCat6([]);
      // setSubCat7([]);
      // setSubCat8([]);
      //}
      // else if (e.target.getAttribute('data-tag') == 'scat2') {
      //   setSubCatVisibility4(0);
      //   setSubCatVisibility5(0);
      //   setSubCatVisibility6(0);
      //   setSubCatVisibility7(0);
      //   setSubCatVisibility8(0);
      //   setSubCat3([]);
      //   setSubCat4([]);
      //   setSubCat5([]);
      //   setSubCat6([]);
      //   setSubCat7([]);
      //   setSubCat8([]);
      // } else if (e.target.getAttribute('data-tag') == 'scat3') {
      //   setSubCatVisibility5(0);
      //   setSubCatVisibility6(0);
      //   setSubCatVisibility7(0);
      //   setSubCatVisibility8(0);
      //   setSubCat4([]);
      //   setSubCat5([]);
      //   setSubCat6([]);
      //   setSubCat7([]);
      //   setSubCat8([]);
      // } else if (e.target.getAttribute('data-tag') == 'scat4') {
      //   setSubCatVisibility6(0);
      //   setSubCatVisibility7(0);
      //   setSubCatVisibility8(0);
      //   setSubCat5([]);
      //   setSubCat6([]);
      //   setSubCat7([]);
      //   setSubCat8([]);
      // } else if (e.target.getAttribute('data-tag') == 'scat5') {
      //   setSubCatVisibility7(0);
      //   setSubCatVisibility8(0);
      //   setSubCat6([]);
      //   setSubCat7([]);
      //   setSubCat8([]);
      // } else if (e.target.getAttribute('data-tag') == 'scat6') {
      //   setSubCatVisibility8(0);
      //   setSubCat7([]);
      //   setSubCat8([]);
      // } else if (e.target.getAttribute('data-tag') == 'scat7') {
      //   setSubCat8([]);
      // }
    }
  }
  const loadChildCategoryById = async (catId) => {
    const res_data = await APICall('get', 'categories/' + catId, []);
    if (res_data.status === 200) {
      setSubCat1(res_data);
      setSubCatVisibility1(1);
    }
  }
  const skillHandleChange = (e) => {
    if (e.target.value !== '') {
      setSelectedSkillId(e.target.value);
    }
  }
  useEffect(() => {
    if (selectedSets !== null) {
      loadSkillsData(selectedSets.catbyskill)
      setSelectedSkillId(selectedSkill);
      setParentCatId(parentcatbyskill + '_1');
      loadChildCategoryById(parentcatbyskill);
      setChildCatId(childcatbyskill + '_1');
      setCategoryId(childcatbyskill);
      setSelectedCategoryId(childcatbyskill);
    }
    getData();
    getSkillData();
  }, [dispatch]);

  return (
    <div
      className="wt-personalskillshold tab-pane active fade show"
      id="tab-create-job"
    >
      {activeLoader && <Loader />}
      <div className="col-md-12">
        <Formik
          initialValues={{
            job_title: "",
            description: "",
            category_id: "",
            skill: "",
          }}
          validationSchema={Schema}
          onSubmit={async (values) => {
            //console.log(values); return false;
            setActiveLoader(true);
            let duration = getDurationData();

            if (!jobRequiredNow) {
              if (duration.length === 0) {
                toast.error("Error! Please add booking date");
                setActiveLoader(false);
                return;
              }
              if (data_custom_slot.length === 0) {
                toast.error(
                  "Error! Please add Available slot to complete booking"
                );
                setActiveLoader(false);
                return;
              }
              if (data_custom_slot[0].time_slot.length === 0) {
                toast.error("Error! Please add Time slot");
                setActiveLoader(false);
                return;
              }
            } else {
              duration =
                duration.length > 0
                  ? duration
                  : Moment().format("YYYY-MM-DD") +
                  "~" +
                  Moment().add(30, "minutes").format("HH") +
                  " To " +
                  Moment().add(2, "hours").add(30, "minutes").format("HH");
            }
            //console.log(values);

            let post_data = {
              job_title: values.job_title,
              description: values.description,
              lat: "", //default empty
              lang: "", //default empty
              category_id: selectedCategoryId, //default empty
              documents: "", //default empty
              workmate_id: "",
              duration: duration,
              job_status: 1,
              urgently_required: jobRequiredNow,
              skill: selectedSkillId ? selectedSkillId : values.skill,
            };
            //console.log(post_data); return false;
            const response = await createJobRequest(post_data, dispatch);
            //console.log(response.data.status);
            if (response.data.status === 201) {
              toast("Success! Action has successfully completed");
              navigate("/userlist/" + response.data.data._id, {
                state: { totalTime: endTime - startTime },
              });
            } else {
              toast("Error! Please try again later");
            }
            setActiveLoader(false);
          }}
        >
          {(props) => (
            <form
              className="wt-formtheme wt-formregister"
              onSubmit={props.handleSubmit}
            >
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Title</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      name="job_title"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.job_title}
                    />
                    {props.errors.job_title && props.touched.job_title && (
                      <span className="text-danger">
                        {props.errors.job_title}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Description</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      name="description"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.description}
                    />
                    {props.errors.description && props.touched.description && (
                      <span className="text-danger">
                        {props.errors.description}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <input type="hidden" name="category_id" value={selectedCategoryId} />

                  <CategorySection title="Select Category" category={parentCatId ? parentCatId : props.values.category} handleBlur={props.handleBlur} handleChange={props.handleChange} visibility="1" name="category" categoryList={categoryList} handleChangeCategory={handleChangeCategory} />
                  {props.errors.category && props.touched.category && (
                    <span className="text-danger mb-3">
                      {props.errors.category}
                    </span>
                  )}

                  <CategorySection title="Select Sub Category" category={childCatId ? childCatId : props.values.subcategory1} handleBlur={props.handleBlur} handleChange={props.handleChange} handleChangeCategory={handleChangeCategory} visibility={subCatVisibility1} name="subcategory1" categoryList={subCat1} dataTag="scat1" />
                </div>

                <div className="col-md-12">
                  <label>Select Skill </label>
                  <div className="form-group">
                    <select
                      data-placeholder="Skill"
                      name="skill"
                      className="form-control"
                      onChange={skillHandleChange}
                      onBlur={props.handleBlur}
                      value={selectedSkillId}
                    >
                      <option defaultValue value="">
                        Skills
                      </option>
                      {!skillsList.loading && size(skillsList.skills) > 0 &&
                        skillsList.skills.map((v, index) => (
                          <option value={v.skillname} key={index}>
                            {v.skillname}
                          </option>
                        )
                        )}
                    </select>
                    {props.errors.skill && props.touched.skill && (
                      <span className="text-danger">
                        {props.errors.skill}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <label>Urgently required </label>
                  <div className="form-group">
                    <label className="font-weight-bold">
                      {" "}
                      <input
                        type="radio"
                        name="description"
                        onChange={() => setJobRequiredNow(true)}
                        defaultChecked={true}
                      />{" "}
                      Yes Required Now{" "}
                    </label>
                    <br />
                    <label className="font-weight-bold">
                      <input
                        type="radio"
                        name="description"
                        onChange={() => setJobRequiredNow(false)}
                        defaultChecked={false}
                      />{" "}
                      No Required Later{" "}
                    </label>
                  </div>
                </div>
              </div>
              {jobRequiredNow ? (
                <div>*In 2 Hours from now</div>
              ) : (
                <>
                  <div className="col-md-12">
                    <div className="row align-items-center">
                      <div className="col-md-6 pl-0">
                        <div className="form-group">
                          <label>Date</label>
                          <DatePicker
                            className="form-control"
                            placeholder=""
                            name="working_date"
                            selected={date_picker}
                            minDate={Moment().toDate()}
                            excludeSeeks={[1]}
                            onChange={(date) => handleSelectedDate(date)}
                          />
                          {props.errors.working_date &&
                            props.touched.working_date && (
                              <span className="text-danger">
                                {props.errors.working_date}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        {" "}
                        <button
                          onClick={() => handleCustomSlot()}
                          className="btn btn-sm btn-success"
                          type="button"
                        >
                          Add Date
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    {data_custom_slot.map((v, i) => (
                      <div className="col-md-12 col-12 mb-1 mt-1" key={i}>
                        <div className="p-4 custom_box">
                          <span className="btn btn-info">{v.date}</span>
                          <span
                            className="badge badge-pill badge-danger pull-right btn"
                            onClick={() => removeCustomSlot(v.date)}
                          >
                            X
                          </span>
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <div>
                                <label className="d-block">
                                  Start Time (Hours)
                                </label>
                                <select
                                  defaultValue={"DEFAULT"}
                                  onChange={handleStartTime}
                                >
                                  <option value="DEFAULT" disabled>
                                    Select
                                  </option>
                                  {[...Array(24)].map((x, i) => {
                                    return (
                                      <option key={i + 1} value={i + 1}>
                                        {i + 1}
                                      </option>
                                    );
                                  })}
                                </select>
                                {/* <input
                              type="number"
                              min="0"
                              max="24"
                              className="form-control"
                              placeholder=""
                              name="start_time"
                              onChange={handleStartTime}
                              value={startTime}
                            /> */}
                              </div>
                            </div>
                            <div className="col-md-4 mt-2">
                              <div className="form-group">
                                <label className="d-block">
                                  End Time (Hours)
                                </label>
                                <select
                                  defaultValue={"DEFAULT"}
                                  onChange={handleEndTime}
                                >
                                  <option value="DEFAULT" disabled>
                                    Select
                                  </option>
                                  {[...Array(24)].map((x, i) => {
                                    return (
                                      <option key={i + 1} value={i + 1}>
                                        {i + 1}
                                      </option>
                                    );
                                  })}
                                </select>
                                {/* <input
                              type="number"
                              className="form-control"
                              min="0"
                              max="24"
                              placeholder=""
                              name="end_time"
                              onChange={handleEndTime}
                              value={endTime}
                            /> */}
                              </div>
                            </div>
                            <div className="col-md-4 mt-2">
                              <button
                                onClick={() => handleCustomTimeSlot(v)}
                                className="btn btn-sm btn-success"
                                type="button"
                              >
                                Add Slot
                              </button>
                            </div>
                            <div className="col-12">
                              <div className="row">
                                {v.time_slot.map((value, index) => (
                                  <div className="col-md-3" key={index}>
                                    <div className="custom_box p-2">
                                      <strong>{value}</strong>
                                      <span
                                        className="badge badge-pill badge-danger pull-right btn "
                                        onClick={() =>
                                          removeCustomTimeSlot(v, value)
                                        }
                                      >
                                        X
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              <button type="submit" className="wt-btn mt-5 mb-5">
                Create Job
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default CreateJob;
