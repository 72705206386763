import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { size } from "lodash";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import JobListDetail from "./JobListDetail";

import {
  // getPendingJobRequest,
  getAcceptedJobRequest,
  sendStartJob,
  sendEndJob,
  sendCompletedJob,
  sendOnWayToStartJob,
} from "../../../services/job/jobRequest";
import Loader from "../../common/Loader";
import Chatting from "../chatting";

const JobListAccepted = ({ update_status, user_role_type, job_status_type }) => {
  const dispatch = useDispatch();
  const [activeLoader, setActiveLoader] = useState(false);

  const [jobList, setJobList] = useState([]);
  const [completedJob, setCompletedJob] = useState({});
  const [modalAction, setModalAction] = useState(false);
  const [chatModalAction, setChatModalAction] = useState(false);

  const userLoginData = JSON.parse(localStorage.getItem("userInfo"));
  const startJob = async (job_id) => {
    setActiveLoader(true);
    let post_data = {
      job_id: job_id,
      job_time: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    let response = await sendStartJob(post_data, dispatch);
    if (response.status === 1) {
      toast("Success! Action has successfully completed");
      // let job_list_data = jobList.filter((value) => {
      //   return value._id === job_id ? false : true;
      // });
      // setJobList(job_list_data);
    } else {
      toast("Error! Please try again later");
    }
    setActiveLoader(false);
  };

  const endJob = async (job_id) => {
    setActiveLoader(true);
    let post_data = {
      job_id: job_id,
      job_time: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    let response = await sendEndJob(post_data, dispatch);
    if (response.status === 1) {
      toast("Success! Action has successfully completed");
      let res_com = await sendCompletedJob(
        {
          job_id: job_id,
          complete_time: moment().format("YYYY-MM-DD HH:mm:ss"),
        },
        dispatch
      );
      if (res_com.status === 1) {
        setCompletedJob(res_com.data);
        setModalAction(true);
      }
      let res_jobs = await getAcceptedJobRequest(dispatch);
      setJobList(res_jobs.status === 0 ? [] : res_jobs.data);
      // let job_list_data = jobList.filter((value) => {
      //   return value._id === job_id ? false : true;
      // });
      // setJobList(job_list_data);
    } else {
      toast("Error! Please try again later");
    }
    setActiveLoader(false);
  };

  const jobAction = async (job_id, type) => {
    setActiveLoader(true);

    let response = await sendOnWayToStartJob(job_id, dispatch);

    if (response.status === 1) {
      toast("Success! Action has successfully completed");
      let job_list_data = jobList.filter((value) => {
        return value.job_id === job_id ? false : true;
      });
      setJobList(job_list_data);
    } else {
      toast("Error! Please try again later");
    }
    setActiveLoader(false);
  };
  useEffect(() => {
    if (update_status !== null && job_status_type === 'accepted') {
      const getData = async () => {
        setActiveLoader(true);
        let res = await getAcceptedJobRequest(dispatch);
        setActiveLoader(false);
        setJobList(res.data);
      };
      getData();
      //console.log(job_status_type);
      const interval = setInterval(() => {
        getData();
      }, 30 * 1000);
      return () => clearInterval(interval);
    }
  }, [dispatch, update_status, job_status_type]);
  return (
    <>
      {activeLoader && <Loader />}
      <div className="tab-pane" id="tab-job-list-accepted">
        <div className="wt-jobdetailsholder">
          <div className="wt-completejobholder">
            <div className="wt-tabscontenttitle">
              <h2>Accepted Jobs</h2>
            </div>
            <div>
              {size(jobList) > 0 ? (
                jobList.map((value, index) => (
                  <div
                    className="wt-userlistinghold wt-featured wt-userlistingvtwo"
                    key={index}
                  >
                    <div className="wt-userlistingcontent wt-userlistingcontentvtwo">
                      <JobListDetail
                        list_value={value}
                        user_role_type={user_role_type}
                      />
                      <div className="wt-contenthead">
                        {(user_role_type === 4 || user_role_type === "4") && value.funds > 0 && (
                          <div className="row pt-2">
                            <div className="col-12 pb-4">
                              {value.job_status === 1 && (
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  onClick={() => jobAction(value._id, "onway")}
                                >
                                  On Way to Start Job
                                </button>
                              )}
                              {value.job_starttime === "" && value.job_status === 4 ? (
                                <button
                                  type="button"
                                  className="btn btn-success  ml-2"
                                  onClick={() => startJob(value._id)}
                                >
                                  Start Job
                                </button>
                              ) : ""
                              }
                              {value.job_starttime !== "" ? (
                                <button
                                  type="button"
                                  className="btn btn-danger ml-1"
                                  onClick={() => endJob(value._id)}
                                >
                                  End Job
                                </button>
                              ) : ""
                              }
                              {value.workmate_id ? (
                                chatModalAction === value._id ? (
                                  <Chatting
                                    key={value._id}
                                    modalAction={chatModalAction === value._id}
                                    setModalAction={setChatModalAction}
                                    professionalId={value.professional._id}
                                    professionalName={
                                      value.professional.first_name +
                                      " " +
                                      value.professional.last_name
                                    }
                                    jobId={value._id}
                                    name={value.job_title}
                                  />
                                ) : (
                                  <button
                                    className="m-2 btn btn-info py-2 px-3"
                                    onClick={() => setChatModalAction(value._id)}
                                  >
                                    Chat
                                  </button>
                                )
                              ) : (
                                <div className="mt-3">"No professional assigned to this job, once a professional accepts invitation, you will be able to chat."</div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center">No Jobs Found</div>
              )}
            </div>
            {size(completedJob) > 0 && (
              <Modal show={modalAction}>
                <Modal.Header>Job Completed</Modal.Header>
                <Modal.Body>
                  <div>
                    Job completed, amount transferred to your wallet.
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div
                    className="btn btn-secondary"
                    onClick={() => setModalAction(false)}
                  >
                    Close
                  </div>
                </Modal.Footer>
              </Modal>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default JobListAccepted;
