import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate as Redirect, Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    //console.log(userInfo);

    useEffect(() => {
        if (!userInfo) {
            toast.error('You have to login first')
        }
    }, [userInfo]);

    return userInfo ? <Outlet /> : <Redirect to="/" />
}

export default PrivateRoute
