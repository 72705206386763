import {
  MY_AVAILABILITY_GET_REQUEST,
  MY_AVAILABILITY_GET_SUCCESS,
  MY_AVAILABILITY_GET_FAIL,
  MEMBERSHIP_PLAN_GET_REQUEST,
  MEMBERSHIP_PLAN_GET_SUCCESS,
  MEMBERSHIP_PLAN_GET_FAIL
} from "../../constants/profile";
import {APICall} from '../../../services';
import { signout } from '../userActions';


export const getMyAvailability = () => async (dispatch) => {
  dispatch({
    type: MY_AVAILABILITY_GET_REQUEST,
  });
  try {
    const data = await APICall('get', 'get-user-availabilities', []);
    data.status===401 && dispatch(signout());
    dispatch({ type: MY_AVAILABILITY_GET_SUCCESS, payload: data.userAvailability });
    return data;
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: MY_AVAILABILITY_GET_FAIL, payload: message });
  }
};

export const setMembershipPlan = () => async (dispatch) => {
  dispatch({
    type: MEMBERSHIP_PLAN_GET_REQUEST,
  });
  try {
    const data = await APICall('get', 'membership',[]);
    data.status===401 && dispatch(signout());
    dispatch({ type: MEMBERSHIP_PLAN_GET_SUCCESS, payload: data.membership });
    return data;
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: MEMBERSHIP_PLAN_GET_FAIL, payload: message });
  }
};
