import { array } from 'prop-types'
import React from 'react'
import { Fragment } from 'react'
import { size } from "lodash";

const CategorySection = ({ title, category, handleBlur, handleChange, visibility, name, categoryList = [], handleChangeCategory, dataTag = 'cat' }) => {
  return (
    <Fragment>
      {visibility == 1 && (
        <div className="row mb-3">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <label>
                  {title}
                </label>
                <span className="wt-select">
                  <select
                    className="form-control ht-select"
                    name={name}
                    id="category"
                    value={category}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      handleChangeCategory(e);
                    }}
                    data-tag={dataTag}
                  >
                    <option defaultValue value="">
                      Categories
                    </option>
                    {!categoryList.loading &&
                      size(
                        categoryList.categories
                      ) > 0 &&
                      categoryList.categories.map(
                        (v, index) => (
                          <option value={v._id + '_' + v.subcategories} key={index}>
                            {v.cat_name}
                          </option>
                        )
                      )}
                  </select>
                  {/* {props.errors.category &&
                                props.touched.category && (
                                    <span className="text-danger">
                                        {props.errors.category}
                                    </span>
                                )} */}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>

  )
}

export default CategorySection
