import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { size } from "lodash";
import { toast } from "react-toastify";
import {
  setRegisterData,
  submitRegister,
} from "../../../../store/actions/userActions";
import {
  setStateListByCountryId,
  setCityListBStateId,
} from "../../../../store/actions/common/commonActions";
import AlreadyAccount from "../common/AlreadyAccount";
import JoinBlock from "../common/JoinBlock";
import Loader from "../../../common/Loader";
import { GoogleAddressLatLong } from "../../../common/third_party/GoogleAddressLatLong";
import { APICall } from "../../../../services";

const RegisterSecond = ({ step, nextStep, prevStep, goToStep }) => {
  const dispatch = useDispatch();
  const data_country_list = useSelector((state) => state.data_country_list);
  const data_state_list = useSelector((state) => state.data_state_list);
  const data_city_list = useSelector((state) => state.data_city_list);
  const data_registration = useSelector((state) => state.data_registration);
  // console.log("data_registration", data_registration);
  const [countryId, setCountryId] = useState(
    data_registration.loading
      ? ""
      : data_registration.data_registration.country_id
  );
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [proImage, setProImage] = useState("");
  const [passImage, setPassImage] = useState("");
  let countryName = !data_state_list.loading
    ? !data_state_list.hasOwnProperty("error")
      ? data_state_list.data_state_list.countries[0].name
      : ""
    : "";
  const handleChangeCountry = (event) => {
    setCountryId(event.target.value);
    dispatch(setStateListByCountryId(event.target.value));
    let register_stage_2 = { country_id: event.target.value };
    let registerData = {
      ...data_registration.data_registration,
      ...register_stage_2,
    };
    dispatch(setRegisterData(registerData));
  };
  const [stateId, setStateId] = useState(
    data_registration.loading
      ? ""
      : data_registration.data_registration.state_id
  );
  let stateName = !data_city_list.loading
    ? data_city_list.data_city_list.states[0].name
    : "";
  const handleChangeState = (event) => {
    setStateId(event.target.value);
    dispatch(setCityListBStateId(event.target.value));
    let register_stage_2 = { state_id: event.target.value };
    let registerData = {
      ...data_registration.data_registration,
      ...register_stage_2,
    };
    dispatch(setRegisterData(registerData));
  };

  const handleChangeCity = (event) => {
    let register_stage_2 = { city: event.target.value };
    let registerData = {
      ...data_registration.data_registration,
      ...register_stage_2,
    };
    dispatch(setRegisterData(registerData));
  };
  const handleAddressChange = async (e) => {
    let address = e.target.value;
    let data = await GoogleAddressLatLong(address);
    //console.log("mapdata", data);
    setLat(data.lat);
    setLong(data.lng);

    let register_stage_2 = { address: address, lat: data.lat, lang: data.lng };
    // let register_stage_2 = { address: address };
    let registerData = {
      ...data_registration.data_registration,
      ...register_stage_2,
    };
    dispatch(setRegisterData(registerData));
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    let data = new FormData();
    data.append("image", selectedFile);
    data.append("uploadType", "image");
    data.append("email", data_registration.data_registration.email);
    data.append("contact_number", data_registration.data_registration.contact_number);
    let profImg = '';
    const res_data = await APICall("post", "image-upload", data);
    if (res_data.status == 200) {
      toast(res_data.message);
      profImg = res_data.image;
      setProImage(profImg)
    } else {
      toast("Error! Image upload failed");
    }
    //console.log(profImg);
    //console.log(proImage);
    let register_stage_2 = { profileImage: profImg };
    const registerData = {
      ...data_registration.data_registration,
      ...register_stage_2,
    };
    dispatch(setRegisterData(registerData));
  };

  const handlePassportChange = async (e) => {
    const selectedPassportImg = e.target.files[0];

    let data = new FormData();
    data.append("image", selectedPassportImg);
    data.append("uploadType", "passportImg");
    data.append("email", data_registration.data_registration.email);
    data.append("contact_number", data_registration.data_registration.contact_number);
    let passImg = '';
    const res_data = await APICall("post", "image-upload", data);
    if (res_data.status == 200) {
      toast(res_data.message);
      passImg = res_data.image;
      setPassImage(passImg)
    } else {
      toast("Error! Image upload failed");
    }
    //console.log(profImg);
    //console.log(proImage);
    let register_stage_2 = { passportImage: passImg };
    const registerData = {
      ...data_registration.data_registration,
      ...register_stage_2,
    };
    dispatch(setRegisterData(registerData));
  };

  const Schema = Yup.object().shape({
    country_id: Yup.string().required("Country is Required"),
    state_id: Yup.string().required("State is Required"),
    city_id: Yup.string().required("City is Required"),
    address: Yup.string().required("Address is Required"),
    password: Yup.string()
      .min(6, "Please enter more than 5 characters")
      .max(20, "Please enter less then 20 characters")
      .required("Password is Required"),
    c_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
    // profileImage: Yup.mixed().required("Profile image is Required"),
    // newsletter: Yup.bool().oneOf([true], 'Newsletter is required')
    terms: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
  });
  return (
    <>
      <main id="wt-main" className="wt-main wt-haslayout wt-innerbgcolor">
        {/*Register Form Start*/}
        <div className="wt-haslayout wt-main-section">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-xs-12 col-sm-12 col-md-10 push-md-1 col-lg-8 push-lg-2">
                <div className="wt-registerformhold">
                  <div className="wt-registerformmain">
                    <JoinBlock />
                    <div className="wt-joinforms">
                      <ul className="wt-joinsteps">
                        <li className="wt-done-next">
                          <NavLink to="#">
                            <i className="fa fa-check" />
                          </NavLink>
                        </li>
                        <li className="wt-active">
                          <NavLink to="#">02</NavLink>
                        </li>
                        <li>
                          <NavLink to="#">03</NavLink>
                        </li>
                        <li>
                          <NavLink to="#">04</NavLink>
                        </li>
                      </ul>
                      <Formik
                        initialValues={{
                          country_id: data_registration.loading
                            ? ""
                            : data_registration.data_registration.country_id,
                          state_id: data_registration.loading
                            ? ""
                            : data_registration.data_registration.state_id,
                          city_id: data_registration.loading
                            ? ""
                            : data_registration.data_registration.city,
                          address: data_registration.loading
                            ? ""
                            : data_registration.data_registration.address,
                          password: data_registration.loading
                            ? ""
                            : data_registration.data_registration.password,
                          c_password: data_registration.loading
                            ? ""
                            : data_registration.data_registration.password,
                          profileImage: data_registration.loading
                            ? null
                            : data_registration.data_registration.profileImage,
                          passportImage: data_registration.loading
                            ? null
                            : data_registration.data_registration.passportImage,
                        }}
                        validationSchema={Schema}
                        onSubmit={(values) => {
                          let register_stage_2 = {
                            country: countryName,
                            country_id: values.country_id,
                            state: stateName,
                            state_id: values.state_id,
                            city: values.city_id,
                            address: values.address,
                            lat: lat,
                            lang: long,
                            password: values.password,
                            confirm_password: values.c_password,
                            profileImage: proImage,
                            passportImage: passImage,
                            role: 4, // 3 company, 4 = freelancer
                            company_emp_no: "",
                            company_department: "",
                          };
                          let registerData = {
                            ...data_registration.data_registration,
                            ...register_stage_2,
                          };
                          dispatch(setRegisterData(registerData));

                          submitRegister(2, registerData);
                          nextStep();
                        }}
                      >
                        {(props) => (
                          <form
                            className="wt-formtheme wt-formregister"
                            onSubmit={props.handleSubmit}
                          >
                            <fieldset className="wt-registerformgroup">
                              <div className="form-group">
                                <span className="wt-select">
                                  <select
                                    className="chosen-select locations"
                                    data-placeholder="Country"
                                    name="country_id"
                                    onChange={(e) => {
                                      props.handleChange(e);
                                      handleChangeCountry(e);
                                    }}
                                    onBlur={props.handleBlur}
                                    value={countryId}
                                  >
                                    <option defaultValue value="">
                                      Country
                                    </option>
                                    {!data_country_list.loading &&
                                      size(
                                        data_country_list.data_country_list
                                          .countries
                                      ) > 0 &&
                                      data_country_list.data_country_list.countries.map(
                                        (v, index) => (
                                          <option value={v._id} key={index}>
                                            {v.name}
                                          </option>
                                        )
                                      )}
                                  </select>
                                  {props.errors.country_id &&
                                    props.touched.country_id && (
                                      <span className="text-danger">
                                        {props.errors.country_id}
                                      </span>
                                    )}
                                </span>
                              </div>
                              <div className="form-group form-group-half">
                                <span className="wt-select">
                                  <select
                                    className="chosen-select locations"
                                    data-placeholder="State"
                                    name="state_id"
                                    onChange={(e) => {
                                      props.handleChange(e);
                                      handleChangeState(e);
                                    }}
                                    onBlur={props.handleBlur}
                                    value={stateId}
                                  >
                                    <option defaultValue value="">
                                      State
                                    </option>
                                    {!data_state_list.loading &&
                                      size(
                                        data_state_list.data_state_list
                                          .countries
                                      ) > 0 &&
                                      data_state_list.data_state_list.countries.map(
                                        (v) =>
                                          size(v.states) > 0 &&
                                          v.states.map((sv, si) => (
                                            <option value={sv._id} key={si}>
                                              {sv.name}
                                            </option>
                                          ))
                                      )}
                                  </select>
                                  {props.errors.state_id &&
                                    props.touched.state_id && (
                                      <span className="text-danger">
                                        {props.errors.state_id}
                                      </span>
                                    )}
                                </span>
                              </div>
                              <div className="form-group form-group-half">
                                <span className="wt-select">
                                  <select
                                    className="chosen-select locations"
                                    data-placeholder="City"
                                    name="city_id"
                                    onChange={(e) => {
                                      props.handleChange(e);
                                      handleChangeCity(e);
                                    }}
                                    onBlur={props.handleBlur}
                                    value={props.values.city_id}
                                  >
                                    <option defaultValue value="">
                                      City
                                    </option>
                                    {!data_city_list.loading &&
                                      size(
                                        data_city_list.data_city_list.states
                                      ) > 0 &&
                                      data_city_list.data_city_list.states.map(
                                        (v) =>
                                          size(v.cities) > 0 &&
                                          v.cities.map((sv, si) => (
                                            <option value={sv.name} key={si}>
                                              {sv.name}
                                            </option>
                                          ))
                                      )}
                                  </select>
                                  {props.errors.city_id &&
                                    props.touched.city_id && (
                                      <span className="text-danger">
                                        {props.errors.city_id}
                                      </span>
                                    )}
                                </span>
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Address*"
                                  name="address"
                                  onChange={(e) => {
                                    props.handleChange(e);
                                    handleAddressChange(e);
                                  }}
                                  onBlur={props.handleBlur}
                                  value={props.values.address}
                                />
                                {props.errors.address &&
                                  props.touched.address && (
                                    <span className="text-danger">
                                      {props.errors.address}
                                    </span>
                                  )}
                              </div>
                              <div className="form-group form-group-half">
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Password*"
                                  name="password"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.password}
                                />

                                {props.errors.password &&
                                  props.touched.password && (
                                    <span className="text-danger">
                                      {props.errors.password}
                                    </span>
                                  )}
                              </div>
                              <div className="form-group form-group-half">
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Retype Password*"
                                  name="c_password"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.c_password}
                                />
                                {props.errors.c_password &&
                                  props.touched.c_password && (
                                    <span className="text-danger">
                                      {props.errors.c_password}
                                    </span>
                                  )}
                              </div>
                            </fieldset>
                            <div className="form-group form-group-half mt-2">
                              <h6>Add Profile Image</h6>
                              <input
                                type="file"
                                name="profileImage"
                                onChange={(e) => {
                                  props.handleChange(e);
                                  handleFileChange(e);
                                }}
                                className="mt-2"
                                accept="image/png image/jpeg"
                              />
                              {props.errors.profileImage && (
                                <span className="text-danger">
                                  {props.errors.profileImage}
                                </span>
                              )}
                            </div>
                            <div className="form-group form-group-half mt-2 pl-2">
                              <h6>Add Passport Image</h6>
                              <input
                                type="file"
                                name="passportImage"
                                onChange={(e) => {
                                  props.handleChange(e);
                                  handlePassportChange(e);
                                }}
                                className="mt-2"
                                accept="image/png image/jpeg"
                              />
                              {props.errors.passportImage && (
                                <span className="text-danger">
                                  {props.errors.passportImage}
                                </span>
                              )}
                            </div>
                            <fieldset className="wt-termsconditions">
                              <div className="wt-checkboxholder">
                                <button
                                  className="wt-btn"
                                  type="button"
                                  onClick={() => prevStep()}
                                >
                                  <i className="fa fa-arrow-left"></i>
                                </button>
                                <button
                                  className="wt-btn pull-right"
                                  type="submit"
                                >
                                  Continue
                                </button>
                              </div>
                            </fieldset>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                  <AlreadyAccount />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Register Form End*/}
      </main>
    </>
  );
};

export default RegisterSecond;
