import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  setRegisterData,
  submitRegister,
} from "../../../../store/actions/userActions";
import { size } from "lodash";
import AlreadyAccount from "../common/AlreadyAccount";
import JoinBlock from "../common/JoinBlock";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader";
import GoogleMapReact from "google-map-react";
import { HiLocationMarker } from "react-icons/hi";
import { GoogleAddressLatLong } from "../../../common/third_party/GoogleAddressLatLong";
import Select from "react-select";
import _ from "lodash";
import { APICall } from "../../../../services";
import CategorySection from "./CategorySection";
import {
  subCategoryList
} from "../../../../store/actions/subCategoryActions";

const RegisterThird = ({ step, nextStep, prevStep, goToStep }) => {
  const dispatch = useDispatch();
  const categoryList = useSelector((state) => state.categoryList);
  const [loader_state, setLoaderState] = useState(false);
  const [cities, setCities] = useState([]);
  const data_registration = useSelector((state) => state.data_registration);
  const cityList = useSelector((state) => state.data_city_list);
  const [policeVerification, setPoliceVerification] = useState(false);
  const [verifiedFile, setVerifiedFile] = useState("");
  const [policeCheck, setPoliceCheck] = useState("");
  const [subCat1, setSubCat1] = useState([]);
  const [subCat2, setSubCat2] = useState([]);
  // const [subCat3, setSubCat3] = useState([]);
  // const [subCat4, setSubCat4] = useState([]);
  // const [subCat5, setSubCat5] = useState([]);
  // const [subCat6, setSubCat6] = useState([]);
  // const [subCat7, setSubCat7] = useState([]);
  // const [subCat8, setSubCat8] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [categoryId, setCategoryId] = useState(
    data_registration.loading
      ? ""
      : data_registration.data_registration.category
  );
  const [subCatVisibility1, setSubCatVisibility1] = useState(0);
  const [subCatVisibility2, setSubCatVisibility2] = useState(0);
  // const [subCatVisibility3, setSubCatVisibility3] = useState(0);
  // const [subCatVisibility4, setSubCatVisibility4] = useState(0);
  // const [subCatVisibility5, setSubCatVisibility5] = useState(0);
  // const [subCatVisibility6, setSubCatVisibility6] = useState(0);
  // const [subCatVisibility7, setSubCatVisibility7] = useState(0);
  // const [subCatVisibility8, setSubCatVisibility8] = useState(0);
  useEffect(() => {
    setCities(
      cityList.data_city_list.states[0].cities.map((city) => {
        return { value: city.name.toLowerCase(), label: city.name };
      })
    );
  }, []);
  const [areas, setAreas] = useState([
    {
      lat: -43.36,
      lng: 171.11,
    },
  ]);

  const defaultProps = {
    center: {
      lat: -43.36,
      lng: 171.11,
    },
    zoom: 5,
  };
  const Marker = () => (
    <div
      style={{
        transform: "translate(-50%, -50%)",
        position: "absolute",
        textAlign: "center",
      }}
    >
      <HiLocationMarker size={40} color="red" />
    </div>
  );

  const onAreaChange = (e) => {
    let area = e.map((e) => e.value);
    let registerData = {
      ...data_registration.data_registration,
      work_area: [...area],
    };
    dispatch(setRegisterData(registerData));

    let data = area.map((area) => GoogleAddressLatLong(area));

    Promise.all(data).then((result) => {
      setAreas(result.filter((a) => a));
    });
  };

  const handlePoliceVerification = async (e) => {
    const selectedValue = e.target.value;
    let register_stage_3 = { police_check: selectedValue };
    //console.log(selectedValue);
    if (selectedValue == 'alreadyHave') {
      setPoliceVerification(true);
    } else {
      setPoliceVerification(false);
    }
    setPoliceCheck(selectedValue);
    let registerData = {
      ...data_registration.data_registration,
      ...register_stage_3,
    };
    dispatch(setRegisterData(registerData));
    //console.log(registerData);
  };

  const handlePoliceFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    //console.log(selectedFile);
    let data = new FormData();
    data.append("image", selectedFile);
    data.append("uploadType", "file");
    data.append("email", data_registration.data_registration.email);
    data.append("contact_number", data_registration.data_registration.contact_number);
    let veriFile = '';
    const res_data = await APICall("post", "image-upload", data);
    if (res_data.status == 200) {
      toast(res_data.message);
      veriFile = res_data.image;
      setVerifiedFile(veriFile)
    } else {
      toast("Error! Image upload failed");
    }
    let register_stage_3 = { verificationFile: veriFile };
    const registerData = {
      ...data_registration.data_registration,
      ...register_stage_3,
    };
    dispatch(setRegisterData(registerData));
    //console.log(registerData);
  };

  const Schema = Yup.object().shape({
    reference: Yup.string().required("Reference is Required"),
    working_rights: Yup.string().required("Working rights is Required"),
    category: Yup.string().required("Category is Required"),
    experience: Yup.string().required("Experience is Required"),
    skills: Yup.string(),
    working_hours: Yup.string().required("Work hours is Required"),
    job_type: Yup.string().required("Job type is Required"),
    police_check: Yup.string(),//.required("This field is Required"),
    have_car: Yup.string().required("This field is Required"),
    terms: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
    price: Yup.number(),
  });

  const handleChangeCategory = async (e) => {
    if (e.target.value != '') {
      let targetValue = e.target.value.split('_');
      setCategoryId(targetValue[0]);
      setSelectedCategoryId(targetValue[0]);
      //console.log(e.target.getAttribute('data-tag'));
      if (e.target.getAttribute('data-tag') != 'scat8' && targetValue[1] == 1) {
        const res_data = await APICall('get', 'categories/' + targetValue[0], []);//await APICall("get", "categories");
        if (res_data.status == 200) {
          if (e.target.getAttribute('data-tag') == 'cat') {
            setSubCat1(res_data);
            setSubCatVisibility1(1);
          } else if (e.target.getAttribute('data-tag') == 'scat1') {
            setSubCat2(res_data);
            setSubCatVisibility2(1);
          }
          //  else if (e.target.getAttribute('data-tag') == 'scat2') {
          //   setSubCat3(res_data);
          //   setSubCatVisibility3(1);
          // } else if (e.target.getAttribute('data-tag') == 'scat3') {
          //   setSubCat4(res_data);
          //   setSubCatVisibility4(1);
          // } else if (e.target.getAttribute('data-tag') == 'scat4') {
          //   setSubCat5(res_data);
          //   setSubCatVisibility5(1);
          // } else if (e.target.getAttribute('data-tag') == 'scat5') {
          //   setSubCat6(res_data);
          //   setSubCatVisibility6(1);
          // } else if (e.target.getAttribute('data-tag') == 'scat6') {
          //   setSubCat7(res_data);
          //   setSubCatVisibility7(1);
          // } else if (e.target.getAttribute('data-tag') == 'scat7') {
          //   setSubCat8(res_data);
          //   setSubCatVisibility8(1);
          // }
        } else {
          toast("Error! Something went wrong");
        }
      }
      let register_stage_2 = { category: targetValue[0] };
      let registerData = {
        ...data_registration.data_registration,
        ...register_stage_2,
      };
      dispatch(setRegisterData(registerData));
    } else {
      if (e.target.getAttribute('data-tag') == 'cat') {
        setSubCatVisibility2(0);
        // setSubCatVisibility3(0);
        // setSubCatVisibility4(0);
        // setSubCatVisibility5(0);
        // setSubCatVisibility6(0);
        // setSubCatVisibility7(0);
        // setSubCatVisibility8(0);
        setSubCat1([]);
        setSubCat2([]);
        // setSubCat3([]);
        // setSubCat4([]);
        // setSubCat5([]);
        // setSubCat6([]);
        // setSubCat7([]);
        // setSubCat8([]);
      }
      // else if (e.target.getAttribute('data-tag') == 'scat1') {
      //   setSubCatVisibility3(0);
      //   setSubCatVisibility4(0);
      //   setSubCatVisibility5(0);
      //   setSubCatVisibility6(0);
      //   setSubCatVisibility7(0);
      //   setSubCatVisibility8(0);
      //   setSubCat2([]);
      //   setSubCat3([]);
      //   setSubCat4([]);
      //   setSubCat5([]);
      //   setSubCat6([]);
      //   setSubCat7([]);
      //   setSubCat8([]);
      // } 
      // else if (e.target.getAttribute('data-tag') == 'scat2') {
      //   setSubCatVisibility4(0);
      //   setSubCatVisibility5(0);
      //   setSubCatVisibility6(0);
      //   setSubCatVisibility7(0);
      //   setSubCatVisibility8(0);
      //   setSubCat3([]);
      //   setSubCat4([]);
      //   setSubCat5([]);
      //   setSubCat6([]);
      //   setSubCat7([]);
      //   setSubCat8([]);
      // } else if (e.target.getAttribute('data-tag') == 'scat3') {
      //   setSubCatVisibility5(0);
      //   setSubCatVisibility6(0);
      //   setSubCatVisibility7(0);
      //   setSubCatVisibility8(0);
      //   setSubCat4([]);
      //   setSubCat5([]);
      //   setSubCat6([]);
      //   setSubCat7([]);
      //   setSubCat8([]);
      // } else if (e.target.getAttribute('data-tag') == 'scat4') {
      //   setSubCatVisibility6(0);
      //   setSubCatVisibility7(0);
      //   setSubCatVisibility8(0);
      //   setSubCat5([]);
      //   setSubCat6([]);
      //   setSubCat7([]);
      //   setSubCat8([]);
      // } else if (e.target.getAttribute('data-tag') == 'scat5') {
      //   setSubCatVisibility7(0);
      //   setSubCatVisibility8(0);
      //   setSubCat6([]);
      //   setSubCat7([]);
      //   setSubCat8([]);
      // } else if (e.target.getAttribute('data-tag') == 'scat6') {
      //   setSubCatVisibility8(0);
      //   setSubCat7([]);
      //   setSubCat8([]);
      // } else if (e.target.getAttribute('data-tag') == 'scat7') {
      //   setSubCat8([]);
      // }
    }
  }

  //   useEffect(() => {
  //     dispatch(submitRegister(1, {}));
  //   }, [dispatch, data_registration]);

  return (
    <>
      <main id="wt-main" className="wt-main wt-haslayout wt-innerbgcolor">
        {/*Register Form Start*/}
        <div className="wt-haslayout wt-main-section">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-xs-12 col-sm-12 col-md-10 push-md-1 col-lg-8 push-lg-2">
                <div className="wt-registerformhold">
                  <div className="wt-registerformmain">
                    {loader_state && <Loader />}
                    <JoinBlock />
                    <div className="wt-joinforms">
                      <ul className="wt-joinsteps">
                        <li className="wt-done-next">
                          <NavLink to="#">
                            <i className="fa fa-check" />
                          </NavLink>
                        </li>
                        <li className="wt-done-next">
                          <NavLink to="#">
                            <i className="fa fa-check" />
                          </NavLink>
                        </li>
                        <li className="wt-active">
                          <NavLink to="#">03</NavLink>
                        </li>
                        <li>
                          <NavLink to="#">04</NavLink>
                        </li>
                      </ul>
                      <Formik
                        initialValues={{
                          reference: data_registration.loading
                            ? ""
                            : data_registration.data_registration.reference,
                          working_rights: data_registration.loading
                            ? ""
                            : data_registration.data_registration
                              .working_rights,
                          category: data_registration.loading
                            ? ""
                            : data_registration.data_registration.category,
                          experience: data_registration.loading
                            ? ""
                            : data_registration.data_registration.experience,
                          skills: data_registration.loading
                            ? ""
                            : data_registration.data_registration.skills,
                          working_hours: data_registration.loading
                            ? ""
                            : data_registration.data_registration.working_hours,
                          job_type: data_registration.loading
                            ? ""
                            : data_registration.data_registration.job_type,
                          police_check: data_registration.loading
                            ? ""
                            : data_registration.data_registration.police_check,
                          have_car: data_registration.loading
                            ? ""
                            : data_registration.data_registration.have_car,
                          work_area: data_registration.loading
                            ? ""
                            : data_registration.data_registration.work_area,
                          newsletter: false,
                          terms: false,
                          price: data_registration.loading
                            ? ""
                            : data_registration.data_registration.price,
                          police_verification_file: data_registration.loading
                            ? ""
                            : data_registration.data_registration.police_verification_file,
                        }}
                        validationSchema={Schema}
                        onSubmit={async (values, actions) => {
                          setLoaderState(true);
                          let register_stage_3 = {
                            reference: values.reference,
                            working_rights: values.working_rights,
                            category: selectedCategoryId,
                            subcategory1: values.subcategory1,
                            subcategory2: values.subcategory2,
                            // subcategory3: values.subcategory3,
                            // subcategory4: values.subcategory4,
                            // subcategory5: values.subcategory5,
                            // subcategory6: values.subcategory6,
                            // subcategory7: values.subcategory7,
                            // subcategory8: values.subcategory8,
                            experience: values.experience,
                            skills: values.skills,
                            working_hours: values.working_hours,
                            job_type: values.job_type,
                            police_check: policeCheck,
                            have_car: values.have_car,
                            work_area: values.work_area,
                            newsletter: values.newsletter,
                            terms: values.terms,
                            price: values.price,
                            police_verification_file: verifiedFile
                          };
                          let registerData = {
                            ...data_registration.data_registration,
                            ...register_stage_3,
                          };
                          dispatch(setRegisterData(registerData));
                          let response = await dispatch(
                            submitRegister(3, registerData)
                          );
                          setLoaderState(false);

                          if (response.status === 201) {
                            nextStep();
                          } else {
                            toast.error(response.message);
                            goToStep(1);
                          }
                        }}
                      >
                        {(props) => (
                          <form
                            className="wt-formtheme wt-formregister"
                            onSubmit={props.handleSubmit}
                          >
                            <fieldset className="wt-registerformgroup">
                              <div className="row mb-3">
                                <div className="d-flex w-100">
                                  <div className="col-md-6">
                                    <label htmlFor="reference">
                                      How did you hear about <img
                                        src="/assets/images/logo.png"
                                        alt="Ficksir" title="Ficksir" style={{ background: "#000000" }}
                                      />
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <label htmlFor="working_rights">
                                      What are your working rights in New
                                      Zealand?
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <span className="wt-select">
                                    <select
                                      className="form-control ht-select"
                                      id="reference"
                                      name="reference"
                                      value={props.values.reference}
                                      onBlur={props.handleBlur}
                                      onChange={props.handleChange}
                                    >
                                      <option defaultValue value="">
                                        Reference
                                      </option>
                                      <option value="friends">
                                        Friends/Family
                                      </option>
                                      <option value="facebook">Facebook</option>
                                      <option value="instagram">
                                        Instagram
                                      </option>
                                      <option value="seek">Seek</option>
                                      <option value="donotremember">
                                        Don't Remember
                                      </option>
                                      <option value="indeed">Indeed</option>
                                      <option value="google">Google</option>
                                    </select>
                                    {props.errors.reference &&
                                      props.touched.reference && (
                                        <span className="text-danger">
                                          {props.errors.reference}
                                        </span>
                                      )}
                                  </span>
                                </div>{" "}
                                <div className="col-md-6">
                                  <span className="wt-select">
                                    <select
                                      className="form-control ht-select"
                                      name="working_rights"
                                      id="working_rights"
                                      value={props.values.working_rights}
                                      onBlur={props.handleBlur}
                                      onChange={props.handleChange}
                                    >
                                      <option defaultValue value="">
                                        Working Rights
                                      </option>
                                      <option value="citizen">Citizen</option>
                                      <option value="permenentResident">
                                        Permenent Resident
                                      </option>
                                      <option value="studentVisa">
                                        Student Visa
                                      </option>
                                      <option value="workingHolidayVisa">
                                        Working Holiday Visa
                                      </option>
                                      <option value="dependentVisa">
                                        Dependent Visa
                                      </option>
                                      <option value="otherRights">
                                        Other New Zealand working rights
                                      </option>
                                      <option value="noRights">
                                        No New Zealand working rights
                                      </option>
                                    </select>
                                    {props.errors.working_rights &&
                                      props.touched.working_rights && (
                                        <span className="text-danger">
                                          {props.errors.working_rights}
                                        </span>
                                      )}
                                  </span>
                                </div>
                              </div>
                              <input type="hidden" name="category" value={selectedCategoryId} />
                              <CategorySection title="Select Category" category={props.values.category} handleBlur={props.handleBlur} handleChange={props.handleChange} visibility="1" name="category" categoryList={categoryList} handleChangeCategory={handleChangeCategory} />

                              <CategorySection title="Select Sub Category" category={props.values.subcategory1} handleBlur={props.handleBlur} handleChange={props.handleChange} handleChangeCategory={handleChangeCategory} visibility={subCatVisibility1} name="subcategory1" categoryList={subCat1} dataTag="scat1" />

                              {/* <CategorySection title="Select Sub Category" category={props.values.subcategory2} handleBlur={props.handleBlur} handleChange={props.handleChange} visibility={subCatVisibility2} name="subcategory2" categoryList={subCat2} dataTag="scat2" handleChangeCategory={handleChangeCategory} />

                              <CategorySection title="Select Sub Category" category={props.values.subcategory3} handleBlur={props.handleBlur} handleChange={props.handleChange} visibility={subCatVisibility3} name="subcategory3" categoryList={subCat3} dataTag="scat3" handleChangeCategory={handleChangeCategory} />

                              <CategorySection title="Select Sub Category" category={props.values.subcategory4} handleBlur={props.handleBlur} handleChange={props.handleChange} visibility={subCatVisibility4} name="subcategory4" categoryList={subCat4} dataTag="scat4" handleChangeCategory={handleChangeCategory} />

                              <CategorySection title="Select Sub Category" category={props.values.subcategory5} handleBlur={props.handleBlur} handleChange={props.handleChange} visibility={subCatVisibility5} name="subcategory5" categoryList={subCat5} dataTag="scat5" handleChangeCategory={handleChangeCategory} />

                              <CategorySection title="Select Sub Category" category={props.values.subcategory6} handleBlur={props.handleBlur} handleChange={props.handleChange} visibility={subCatVisibility6} name="subcategory6" categoryList={subCat6} dataTag="scat6" handleChangeCategory={handleChangeCategory} />

                              <CategorySection title="Select Sub Category" category={props.values.subcategory7} handleBlur={props.handleBlur} handleChange={props.handleChange} visibility={subCatVisibility7} name="subcategory7" categoryList={subCat7} dataTag="scat7" handleChangeCategory={handleChangeCategory} />

                              <CategorySection title="Select Sub Category" category={props.values.subcategory8} handleBlur={props.handleBlur} handleChange={props.handleChange} visibility={subCatVisibility8} name="subcategory8" categoryList={subCat8} dataTag="scat8" handleChangeCategory={handleChangeCategory} /> */}

                              <div className="row mb-3">
                                <div className="col-md-12">
                                  <label htmlFor="working_hours">
                                    Your skills (e.g. Electrician, Carpenter, Plumber etc...)
                                  </label>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group pl-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Skills"
                                      name="skills"
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      value={props.values.skills}

                                    />

                                    {props.errors.skills &&
                                      props.touched.skills && (
                                        <span className="text-danger">
                                          {props.errors.skills}
                                        </span>
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="d-flex w-100">
                                  <div className="col-md-6">
                                    <label htmlFor="working_rights">
                                      How many years of experience do you have?
                                    </label>
                                    <span className="wt-select">
                                      <select
                                        className="form-control ht-select"
                                        name="experience"
                                        id="experience"
                                        value={props.values.experience}
                                        onBlur={props.handleBlur}
                                        onChange={props.handleChange}
                                      >
                                        <option defaultValue value="">
                                          Experience
                                        </option>
                                        <option value="noExperience">
                                          No Experience
                                        </option>
                                        <option value="1-3">
                                          1-3 months
                                        </option>
                                        <option value="4-6">
                                          4-6 months
                                        </option>
                                        <option value="7-12">
                                          7-12 months
                                        </option>
                                        <option value="12-24">
                                          1-2 years
                                        </option>
                                        <option value="24+">2 years+</option>
                                      </select>
                                      {props.errors.experience &&
                                        props.touched.experience && (
                                          <span className="text-danger">
                                            {props.errors.experience}
                                          </span>
                                        )}
                                    </span>
                                  </div>
                                  <div className="col-md-6">
                                    <label htmlFor="working_price">
                                      Rate
                                    </label>
                                    <span className="form-group pl-0 pt-0">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Rate"
                                        name="price"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.price}

                                      />
                                      {props.errors.price &&
                                        props.touched.price && (
                                          <span className="text-danger">
                                            {props.errors.price}
                                          </span>
                                        )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="d-flex w-100">
                                  <div className="col-md-6">
                                    <label htmlFor="working_hours">
                                      How many hours per week are you looking to
                                      work for?
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <label htmlFor="job_type">
                                      What type of job are you looking for?
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <span className="wt-select">
                                    <select
                                      className="form-control ht-select"
                                      name="working_hours"
                                      id="working_hours"
                                      value={props.values.working_hours}
                                      onBlur={props.handleBlur}
                                      onChange={props.handleChange}
                                    >
                                      <option defaultValue value="">
                                        Working hours
                                      </option>
                                      <option value="0-20">
                                        less than 20 hours
                                      </option>
                                      <option value="20-30">20-30 hours</option>
                                      <option value="30-40">30-40 hours</option>
                                      <option value="40+">
                                        more than 40 hours
                                      </option>
                                    </select>
                                    {props.errors.working_hours &&
                                      props.touched.working_hours && (
                                        <span className="text-danger">
                                          {props.errors.working_hours}
                                        </span>
                                      )}
                                  </span>
                                </div>
                                <div className="col-md-6">
                                  <span className="wt-select">
                                    <select
                                      className="form-control ht-select"
                                      name="job_type"
                                      id="job_type"
                                      value={props.values.job_type}
                                      onBlur={props.handleBlur}
                                      onChange={props.handleChange}
                                    >
                                      <option defaultValue value="">
                                        Job type
                                      </option>
                                      <option value="shortTerm">
                                        Short term - For next 2-4 months
                                      </option>
                                      <option value="MediumTerm">
                                        Medium term - For next 5-12 months
                                      </option>
                                      <option value="longTerm">
                                        Long term - More than 12 months
                                      </option>
                                    </select>
                                    {props.errors.job_type &&
                                      props.touched.job_type && (
                                        <span className="text-danger">
                                          {props.errors.job_type}
                                        </span>
                                      )}
                                  </span>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="d-flex w-100">
                                  <div className="col-md-6">
                                    <label htmlFor="police_check">
                                      Are you willing to apply for a police
                                      check?
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <label htmlFor="have_car">
                                      Do you have a car?
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <span className="wt-select">
                                    <select
                                      className="form-control ht-select"
                                      name="police_check"
                                      id="police_check"
                                      value={policeCheck}
                                      onBlur={props.handleBlur}
                                      onChange={(e) => {
                                        //props.handleChange();
                                        handlePoliceVerification(e);
                                      }}
                                    //onChange={props.handleChange}
                                    >
                                      <option defaultValue value="">
                                        Police check
                                      </option>
                                      <option value="alreadyHave">
                                        I already have a recent police check
                                      </option>
                                      <option value="yes">
                                        Yes - I am willing to get one
                                      </option>
                                      <option value="no">
                                        No - I am not comfortable to get one
                                      </option>
                                    </select>
                                    {props.errors.police_check &&
                                      props.touched.police_check && (
                                        <span className="text-danger">
                                          {props.errors.police_check}
                                        </span>
                                      )}
                                  </span>
                                </div>
                                <div className="col-md-6">
                                  <span className="wt-select">
                                    <select
                                      className="form-control ht-select"
                                      name="have_car"
                                      id="have_car"
                                      value={props.values.have_car}
                                      onBlur={props.handleBlur}
                                      onChange={props.handleChange}
                                    >
                                      <option defaultValue value="">
                                        Select
                                      </option>
                                      <option value="yes">Yes</option>
                                      <option value="no">No</option>
                                    </select>
                                    {props.errors.have_car &&
                                      props.touched.have_car && (
                                        <span className="text-danger">
                                          {props.errors.have_car}
                                        </span>
                                      )}
                                  </span>
                                </div>
                              </div>
                              {policeVerification && (
                                <div className="row mb-3 pl-3">
                                  <label htmlFor="police_check">Add Police Check Verification File</label>
                                  <div className="form-group">
                                    <input
                                      type="file"
                                      name="policeCheckVerification"
                                      onChange={(e) => {
                                        props.handleChange(e);
                                        handlePoliceFileChange(e);
                                      }}
                                      className="mt-2"
                                      accept="image/png image/jpeg application/pdf"
                                    />
                                    {props.errors.policeCheckVerification && (
                                      <span className="text-danger">
                                        {props.errors.policeCheckVerification}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )}
                              <div>
                                <label htmlFor="work_area">
                                  What area would you like to work?
                                </label>
                                {/* <select
                                      className="form-control ht-select"
                                      name="work_area"
                                      id="work_area"
                                      value={props.values.work_area}
                                      onBlur={props.handleBlur}
                                      onChange={(e) => {
                                        props.handleChange(e);
                                        onAreaChange(e);
                                      }}
                                    >
                                      <option defaultValue value="">
                                        Select area
                                      </option>
                                      {cityList.data_city_list.states[0].cities.map(
                                        (city, index) => {
                                          return (
                                            <option value={city.name}>
                                              {city.name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                    {props.errors.work_area &&
                                      props.touched.work_area && (
                                        <span className="text-danger">
                                          {props.errors.work_area}
                                        </span>
                                      )} */}
                                <Select
                                  isMulti
                                  name="work_area"
                                  options={cities}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(e) => {
                                    onAreaChange(e);
                                  }}
                                />
                                {props.errors.work_area &&
                                  props.touched.work_area && (
                                    <span className="text-danger">
                                      {props.errors.work_area}
                                    </span>
                                  )}
                              </div>
                              <div className="map-height my-3">
                                <GoogleMapReact
                                  bootstrapURLKeys={{
                                    key: process.env
                                      .REACT_APP_GOOGLE_MAPS_API_KEY,
                                  }}
                                  defaultCenter={defaultProps.center}
                                  defaultZoom={defaultProps.zoom}
                                  center={defaultProps.center}
                                >
                                  {areas.map((area, index) => (
                                    <Marker
                                      key={index}
                                      text="test"
                                      lat={area?.lat}
                                      lng={area?.lng}
                                    />
                                  ))}
                                </GoogleMapReact>
                              </div>
                            </fieldset>
                            <fieldset className="wt-termsconditions">
                              <div className="wt-checkboxholder">
                                <span className="wt-checkbox">
                                  <input
                                    id="termsconditions"
                                    type="checkbox"
                                    name="newsletter"
                                    onChange={props.handleChange}
                                    value={true}
                                  />
                                  <label htmlFor="termsconditions">
                                    <span>Newsletter</span>
                                  </label>
                                  {props.errors.newsletter &&
                                    props.touched.newsletter && (
                                      <span className="text-danger">
                                        {props.errors.newsletter}
                                      </span>
                                    )}
                                </span>
                                <span className="wt-checkbox">
                                  <input
                                    id="termsconditions2"
                                    type="checkbox"
                                    name="terms"
                                    onChange={props.handleChange}
                                    value={true}
                                  />
                                  <label htmlFor="termsconditions2">
                                    <span>
                                      <NavLink to="/terms-and-conditions">
                                        Terms &amp; Conditions
                                      </NavLink>
                                    </span>
                                  </label>
                                  {props.errors.terms &&
                                    props.touched.terms && (
                                      <span className="text-danger">
                                        {props.errors.terms}
                                      </span>
                                    )}
                                </span>
                              </div>
                              <div className="form-group">
                                <button
                                  className="wt-btn"
                                  type="button"
                                  onClick={() => prevStep()}
                                >
                                  <i className="fa fa-arrow-left"></i>
                                </button>
                                <button
                                  className="wt-btn pull-right"
                                  type="submit"
                                >
                                  Continue
                                </button>
                              </div>
                            </fieldset>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                  <AlreadyAccount />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Register Form End*/}
      </main>
    </>
  );
};

export default RegisterThird;
