import React from "react";
import Footer from '../Footer'
import Header from '../Header'
import { useLocation, Navigate } from "react-router-dom";

const PaymentSuccess = () => {
  const { state } = useLocation();
  const { amount, ref_id, ref_job_id } = state;

  //console.log(ref_job_id)


  if (state === null) {
    return <Navigate to="/" />;
  }
  return (
    <>
      <Header />
      <main className="payment-success">
        <div className="msg">
          <h1>Thankyou!</h1>
          {`Payment of $${amount} has been recieved `}
          <br />
          {`Reference No. ${ref_id} `}
        </div>
        {ref_job_id ? (
          <div className="btn-wrapper">
            <a href={`/userlist/${ref_job_id}`}
              className="btn btn-outline-primary mb-5"
            >
              Back to Job Page
            </a>
          </div>
        ) : (
          <div className="btn-wrapper">
            <a href="/"
              className="btn btn-outline-primary mb-5"
            >
              Go to HomePage
            </a>
          </div>
        )
        }

      </main >
      <Footer />
    </>
  )
}

export default PaymentSuccess