import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { size } from "lodash";
import { useNavigate } from "react-router-dom";
import data from "../../sitecontents/StaticContent";
import { subCategoryList } from "../../store/actions/subCategoryActions";
import Loader from "../common/Loader";
import { APICall } from "../../services";

const Categories = ({ categories }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sub_category = useSelector((state) => state.sub_category);
  const [visibleSubCat, setVisibleSubCat] = useState(false);
  const [visibleSubClick, setVisibleSubClick] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const getSubCategory = async (category_id) => {
    setShowLoader(true);
    //  let data= await Promise.all([]);
    dispatch(subCategoryList(category_id)).then((data) => {
      if (data.status === 400) {
        navigate("/userlist");
      } else {
        setVisibleSubClick(true);
      }
    });
  };
  const getMainCategoryList = () => {
    setVisibleSubCat(false);
    setShowLoader(false);
  };

  //  visibleSubCat && size(sub_category.sub_categories) === 0 && !showLoader && navigate('/userlist')

  useEffect(() => {
    async function getData() {
      if (sub_category.loading === false && visibleSubClick) {
        await Promise.all([setVisibleSubCat(true), setShowLoader(false)]);
      }
    }
    getData();
  }, [sub_category, visibleSubClick]);

  return (
    <>
      <section className="wt-haslayout wt-main-section">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-xs-12 col-sm-12 col-md-8 push-md-2 col-lg-6 push-lg-3">
              <div className="wt-sectionhead wt-textcenter">
                <div className="wt-sectiontitle">
                  <h2>{data.categoryTitle}</h2>
                  <span>{data.categorySubTitle}</span>
                </div>
              </div>
            </div>
            {showLoader && <Loader />}
            {!visibleSubCat ? (
              <div className="wt-categoryexpl">
                {categories.map((category, index) => (
                  <div
                    className="col-12 col-sm-12 col-md-3 col-lg-3 float-left"
                    key={index}
                  >
                    <div className="wt-categorycontent">
                      {category.hasOwnProperty("cat_icon") && (
                        <figure>
                          <img
                            src={`${process.env.REACT_APP_API_URI}/images/categories/${category.cat_icon}`}
                            alt={category.cat_name}
                          />
                        </figure>
                      )}
                      <div className="wt-cattitle">
                        <h3>
                          <Link to="#">{category.cat_name}</Link>
                        </h3>
                      </div>
                      <div className="wt-categoryslidup">
                        <p>{category.description}</p>
                        <span
                          className="category_link"
                          onClick={() => getSubCategory(category._id)}
                          to=""
                        >
                          Explore <i className="fa fa-arrow-right" />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 float-left">
                                <div className="wt-btnarea">
                                    <Link to="#" className="wt-btn">{data.viewAll}</Link>
                                </div>
                            </div> */}
              </div>
            ) : (
              <div className="wt-categoryexpl">
                {size(sub_category.sub_categories) > 0 &&
                  sub_category.sub_categories.map((category, index) => (
                    <div
                      className="col-12 col-sm-12 col-md-3 col-lg-3 float-left"
                      key={index}
                    >
                      <div className="wt-categorycontent">
                        {category.hasOwnProperty("cat_icon") && (
                          <figure>
                            <img
                              src={`${process.env.REACT_APP_API_URI}/images/categories/${category.cat_icon}`}
                              alt={category.cat_name}
                            />
                          </figure>
                        )}
                        <div className="wt-cattitle">
                          <h3>
                            <Link to="#">{category.cat_name}</Link>
                          </h3>
                        </div>
                        <div className="wt-categoryslidup">
                          <p>{category.description}</p>
                          <span
                            className="category_link"
                            onClick={() => getSubCategory(category._id)}
                            to=""
                          >
                            Explore <i className="fa fa-arrow-right" />
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                {/* {size(sub_category.sub_categories) === 0 && !showLoader && navigate('/userlist')} */}
                {/* {size(sub_category.sub_categories) === 0 && !showLoader && (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 float-left">
                    <div className="wt-btnarea">No category data found</div>
                  </div>
                )} */}
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 float-left">
                  <div className="wt-btnarea">
                    <span
                      href="#"
                      className="help-text btn"
                      onClick={() => getMainCategoryList()}
                    >
                      <i className="fa fa-arrow-left"></i> Back
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Categories;
