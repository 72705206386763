import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Header from "../../../components/Header";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import { sendChangePassword } from "../../../services/auth/authAPI";

const ChangePassword = () => {
    const dispatch = useDispatch();
  const [loader_state, setLoaderState] = useState(false);
  const Schema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Please enter more than 5 characters")
      .max(20, "Please enter less then 20 characters")
      .required("Password is Required"),
    c_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });
  return (
    <>
      <Header />
      <main id="wt-main" className="wt-main-dashboard wt-haslayout">
        <section className="wt-haslayout wt-dbsectionspace">
          <div className="row mt-5">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 mt-5">
              <div className="wt-dashboardbox wt-dashboardtabsholder wt-accountsettingholder500">
                <div className="wt-tabscontent tab-content">
                  <div className="wt-passwordholder" id="wt-password">
                    <div className="wt-changepassword">
                      <div className="wt-tabscontenttitle">
                        <h2>Change Your Password</h2>
                      </div>
                      {loader_state && <Loader />}
                      <Formik
                        initialValues={{
                          password: "",
                          c_password: "",
                        }}
                        validationSchema={Schema}
                        onSubmit={async (values) => {
                          setLoaderState(true);
                          let post_data = {
                            passwd: values.password,
                            cpasswd: values.c_password,
                          };
                          let response = await sendChangePassword(
                            post_data,
                            dispatch
                          );
                          if (response.status === 1) {
                            toast.success(
                              "Success! Password Changed Successfully"
                            );
                          } else {
                            toast.error("Error! Please try again later");
                          }
                          setLoaderState(false);
                        }}
                      >
                        {(props) => (
                          <form
                            className="wt-formtheme wt-userform"
                            onSubmit={props.handleSubmit}
                          >
                            <fieldset>
                              <div className="form-group">
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Password*"
                                  name="password"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.password}
                                />

                                {props.errors.password &&
                                  props.touched.password && (
                                    <span className="text-danger">
                                      {props.errors.password}
                                    </span>
                                  )}
                              </div>
                              <div className="form-group">
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Retype Password*"
                                  name="c_password"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.c_password}
                                />

                                {props.errors.c_password &&
                                  props.touched.c_password && (
                                    <span className="text-danger">
                                      {props.errors.c_password}
                                    </span>
                                  )}
                              </div>
                            </fieldset>
                            <fieldset>
                              <div className="form-group">
                                <button className="wt-btn" type="submit">
                                  Save &amp; Continue
                                </button>
                              </div>
                            </fieldset>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ChangePassword;
