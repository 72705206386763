import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { size } from "lodash";
import { toast } from "react-toastify";
import { listCategories } from "../../../store/actions/categoryActions";
import { subCategoryList } from "../../../store/actions/subCategoryActions";
import { postCategorySetting } from "../../../services/profile/availabilitySetting";
import Loader from "../../common/Loader";
import _ from "lodash";
import { APICall } from "../../../services";

const CategorySetting = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const categoryList = useSelector((state) => state.categoryList);

  const categories =
    size(categoryList.categories) > 0
      ? categoryList.categories.filter((value) => {
        return value.isActive === 1 ? true : false;
      })
      : [];
  const [activeLoader, setActiveLoader] = useState(false);
  const [catName, setName] = useState("");
  const [catId, setId] = useState("");
  const [display, setDisplay] = useState(false);
  const [skillCatId, setSkillCatId] = useState("");

  const userLoginData = JSON.parse(localStorage.getItem("userInfo"));

  //SKILLS CODE START
  const [formValues, setFormValues] = useState([]);//userLoginData.user.skills
  console.log(formValues);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues)
  }
  let addFormFields = () => {
    setFormValues([...formValues, { name: "", price: "" }])
  }
  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  }
  let handleSubmit = (catId) => async (event) => {
    event.preventDefault();
    const postData = JSON.stringify(formValues);
    let data = await APICall("post", "update-skills", { data: postData, "category_id": catId }, { token: userInfo.token });
    if (data.status === 200) {
      toast.success(data.message);
    } else {
      let errMsg = data.message ? data.message : 'Somthing went wrong';
      toast.error(errMsg);
    }
    setDisplay(false);
  }
  //SKILLS CODE END


  const formSubmit = async (e) => {
    e.preventDefault();
    setActiveLoader(true);

    if (catName.length === 0 || catId.length === 0) {
      toast.error("Error! Please select Categories");
      return false;
    }

    let post_data = {
      user_id: userLoginData.user._id,
      category_name: catName,
      category_id: catId,
    };
    let response = await postCategorySetting(post_data);
    if (response.status === 1) {
      toast("Success! Action has successfully completed");
      // navigate("/dashboard");
    } else {
      toast("Error! Please try again later");
    }
    setActiveLoader(false);
  };

  // const [category, SetCategory] = useState(false);
  const handleCategory = (e, input_cat_id, input_cat_name) => {
    if (e.target.checked) {
      if (!catName.includes(input_cat_name)) {
        let cat_name =
          catName.length === 0
            ? input_cat_name
            : catName + "," + input_cat_name;
        setName(cat_name);
      }
      if (!catId.includes(input_cat_id)) {
        let cat_id =
          catId.length === 0 ? input_cat_id : catId + "," + input_cat_id;
        setId(cat_id);
      }
    } else {
      let cat_name_revers = "";
      if (catName.search(input_cat_name) === 0) {
        cat_name_revers = catName.replace(input_cat_name + ",", "");
        cat_name_revers = cat_name_revers.replace(input_cat_name, "");
      } else {
        cat_name_revers = catName.replace("," + input_cat_name, "");
      }
      setName(cat_name_revers);

      let cat_id_revers = "";
      if (catId.search(input_cat_id) === 0) {
        cat_id_revers = catId.replace(input_cat_id + ",", "");
        cat_id_revers = catId.replace(input_cat_id, "");
      } else {
        cat_id_revers = catId.replace("," + input_cat_id, "");
      }
      setId(cat_id_revers);
    }
    // SetCategory(!e.target.checked);
  };

  const sub_category = useSelector((state) => state.sub_category);
  const [visibleSubCat, setVisibleSubCat] = useState(false);
  const [visibleSubClick, setVisibleSubClick] = useState(false);

  const getSubCategory = async (category_id) => {
    setActiveLoader(true);
    //  let data= await Promise.all([]);
    dispatch(subCategoryList(category_id)).then((data) => {
      if (data.status === 400) {
      } else {
        setVisibleSubClick(true);
      }
    });
  };
  const getMainCategoryList = () => {
    setVisibleSubCat(false);
    setActiveLoader(false);
    setDisplay(false);
  };

  const getSkillByCategory = async (catId) => {
    let data = await APICall("post", "user-skills", { "category_id": catId }, { token: userInfo.token });
    if (data.status === 200) {
      //toast.success(data.message);
      setDisplay(true);
      setSkillCatId(catId);
      setFormValues(data.skills);
    } else {
      let errMsg = data.message ? data.message : 'Somthing went wrong';
      toast.error(errMsg);
    }
  }
  useEffect(() => {
    async function getData() {
      dispatch(listCategories());
      if (sub_category.loading === false && visibleSubClick) {
        await Promise.all([setVisibleSubCat(true), setActiveLoader(false)]);
      }
    }
    getData();
  }, [dispatch, sub_category, visibleSubClick]);

  return (
    <>
      <div
        className="wt-personalskillshold tab-pane fade "
        id="tab-category-setting"
      >
        <div className="wt-yourdetails wt-tabsinfo">
          <div className="wt-tabscontenttitle d-flex">
            <div className="col-10 pt-2 pl-0 float-left">
              <h2>Category Setting</h2>
              {catName.length > 0 && (
                <div>
                  <label className="pt-2">Selected Categories: </label>
                  <strong>{catName}</strong>
                </div>
              )}
            </div>
            {visibleSubClick ? (
              <div className="float-right">
                <span
                  href="#"
                  className="help-text btn btn-secondary"
                  onClick={() => getMainCategoryList()}
                >
                  <i className="fa fa-arrow-left"></i> Back
                    </span>
              </div>
            ) : (
                ''
              )}

          </div>

          {activeLoader && <Loader />}
          {!display ? (
            <form className="wt-formtheme wt-userform" onSubmit={formSubmit}>
              <fieldset>
                <div>
                  <ul className="list-group">
                    {!visibleSubCat
                      ? categories.map((category, index) => (
                        <li className="list-group-item" key={index}>
                          <div className="row">
                            <div className="col-10">
                              <div className="form-check">
                                <label className="form-check-label font-weight-bold">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name={category._id}
                                    checked={
                                      _.size(
                                        _.filter(
                                          userLoginData.user.profile_categories,
                                          (item) =>
                                            item.category_id === category._id
                                        )
                                      ) ||
                                      _.size(
                                        _.filter(
                                          catName.split(","),
                                          (item) => item === category.cat_name
                                        )
                                      )
                                    }
                                    onChange={(e) =>
                                      handleCategory(
                                        e,
                                        category._id,
                                        category.cat_name
                                      )
                                    }
                                  />
                                  {category.cat_name}
                                </label>
                              </div>
                            </div>
                            {category.subcategories === 1 ? (<div className="col-2 pull-right">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => getSubCategory(category._id)}
                              >
                                <i className="fa fa-arrow-right"></i>
                              </button>
                            </div>) : ''}
                          </div>
                          <div className="hide"></div>
                        </li>
                      ))
                      : size(sub_category.sub_categories) > 0
                        ? sub_category.sub_categories.map((category, index) => (
                          <li className="list-group-item" key={index}>
                            <div className="row">
                              <div className="col-10">
                                <div className="form-check">
                                  <label className="form-check-label font-weight-bold">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      name={category._id}
                                      checked={
                                        _.size(
                                          _.filter(
                                            userLoginData.user.profile_categories,
                                            (item) =>
                                              item.category_id === category._id
                                          )
                                        ) ||
                                        _.size(
                                          _.filter(
                                            catName.split(","),
                                            (item) => item === category.cat_name
                                          )
                                        )
                                      }
                                      onChange={(e) =>
                                        handleCategory(
                                          e,
                                          category._id,
                                          category.cat_name
                                        )
                                      }
                                    />
                                    {category.cat_name}
                                  </label>
                                </div>
                              </div>
                              {/* {(category.parent_id === 0 || category.parent_id === "0") ? ( */}
                              <div className="col-2 pull-right">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  //onClick={() => getSubCategory(category._id)}
                                  onClick={() => getSkillByCategory(category._id)}
                                >
                                  <i className="fa fa-arrow-right"></i>
                                </button>
                              </div>
                              {/* //   ) : ''
                            // } */}
                            </div>
                            <div className="hide"></div>
                          </li>
                        ))
                        : "Category not found"}
                  </ul>
                  {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 float-left">
                    <div className="wt-btnarea pull-right">
                      <span
                        href="#"
                        className="help-text btn"
                        onClick={() => getMainCategoryList()}
                      >
                        <i className="fa fa-arrow-left"></i> Back
                    </span>
                    </div>
                  </div> */}
                </div>
                <button className="wt-btn mt-5 mb-5" type="submit">
                  Save &amp; Update Category
                  </button>
              </fieldset>
            </form>
          ) : (
              <div className="wt-yourdetails wt-tabsinfo">
                <div className="wt-tabscontenttitle">
                  <h2>Skills Set</h2>
                </div>
                <form className="wt-formtheme wt-userform" onSubmit={handleSubmit(skillCatId)}>
                  <input type="hidden" name="category_id" value={skillCatId} />
                  {formValues.map((skill, index) => (
                    <fieldset key={index}>
                      <div className="form-group form-group-half col-md-5">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Enter Your SKill"
                          onChange={e => handleChange(index, e)}
                          value={skill.name}
                        />
                      </div>
                      <div className="form-group form-group-half col-md-5">
                        <input
                          type="number"
                          name="price"
                          className="form-control"
                          placeholder="Enter Your SKill Rate ($)"
                          onChange={e => handleChange(index, e)}
                          value={skill.price}
                        />
                      </div>
                      {
                        index ? ''
                          // <button type="button" className="button remove btn btn-danger mt-2" title="Remove Skill" onClick={() => removeFormFields(index)}><i className="fa fa-minus" aria-hidden="true"></i></button>
                          : null
                      }
                    </fieldset>
                  ))}
                  <fieldset>
                    {/* <div className="form-group  mt-4">
                      <button className="wt-btn btn btn-success" type="button" onClick={() => addFormFields()}>
                        <i className="fa fa-plus-circle" aria-hidden="true"></i> Add More Skills
                        </button>
                    </div> */}
                    <div className="form-group  mt-4">
                      {/* <span
                        href="#"
                        className="help-text btn"
                        onClick={() => getMainCategoryList()}
                      >
                        <i className="fa fa-arrow-left"></i> Back
                    </span> */}
                      <button className="wt-btn" type="submit">
                        Update & Save Skills
                      </button>
                    </div>
                  </fieldset>
                </form>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default CategorySetting;
