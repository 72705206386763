import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { categoryListReducer, skillListReducer } from "./reducers/categoryReducers";
import { subcategoryListReducer } from "./reducers/subCategoryReducers";
import { menuListReducer } from "./reducers/menuReducer";
import {
  homeBannerReducer,
  homeHowItWork,
  homeTopFreelancerHeading,
  homeIntroducing,
  homeLatestArticle,
  homeStartCmpOrFree,
  homeExploreCategory,
} from "./reducers/homeContentReducer";
import { ArticlesReducer } from "./reducers/articlesReducer";
import { FreelancerListReducer } from "./reducers/freelancerListReducer";
import { FreelancerDetailReducer } from "./reducers/freelancerDetailReducer";
import { freelancerListHomeReducer } from "./reducers/freelancerListHomeReducer";

import {
  footerOneReducer,
  footerTwoReducer,
  footerThreeReducer,
  footerNewsletterReducer,
  footerRightsReducer,
} from "./reducers/footer/footerReducer";

import {
  userDetailsReducer,
  userLoginReducer,
  userUpdateProfileReducer,
  getRegisterDataReducer,
  userRegisterProcessReducer,
} from "./reducers/userReducers";
import {
  getCountryListReducer,
  getStateListReducer,
  getCityListReducer,
  getLoginPopupReducer,
} from "./reducers/common/commonReducer";
import { getUserAvailability } from "./reducers/job/HireFreelancerReducer";
import { getMyJobList } from "./reducers/job/MyJobListReducer";
import {
  getMyAvailability,
  getMembershipPlan,
} from "./reducers/profile/ProfileReducer";
import {
  jobRightSideReducer,
  jobTimeReducer,
} from "./reducers/JobRightSideReducer";
const initialState = {
  userLogin: {
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,
  },
};

const reducer = combineReducers({
  categoryList: categoryListReducer,
  userLogin: userLoginReducer,
  allMenuList: menuListReducer,
  home_banner: homeBannerReducer,
  article_data: ArticlesReducer,
  sub_category: subcategoryListReducer,
  freelancer_list: FreelancerListReducer,
  freelancer_detail: FreelancerDetailReducer,
  freelancer_list_home: freelancerListHomeReducer,
  data_footer_1: footerOneReducer,
  data_footer_2: footerTwoReducer,
  data_footer_3: footerThreeReducer,
  data_footer_newsletter: footerNewsletterReducer,
  data_footer_rights: footerRightsReducer,
  data_home_how_it_work: homeHowItWork,
  data_home_top_freelancer_heading: homeTopFreelancerHeading,
  data_home_introducing: homeIntroducing,
  data_home_latest_article: homeLatestArticle,
  data_home_start_cmp_or_freelancer: homeStartCmpOrFree,
  data_home_explore_category: homeExploreCategory,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  data_registration: getRegisterDataReducer,
  data_registration_process: userRegisterProcessReducer,
  data_country_list: getCountryListReducer,
  data_state_list: getStateListReducer,
  data_city_list: getCityListReducer,
  data_user_availability: getUserAvailability,
  data_login_popup_status: getLoginPopupReducer,
  data_job_list: getMyJobList,
  data_my_availability: getMyAvailability,
  data_membership_plan: getMembershipPlan,
  data_right_side: jobRightSideReducer,
  skillsList: skillListReducer,
});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
