import React from 'react'
import { NavLink } from "react-router-dom";

const PaginationBlock = () => {
    return (
        <>
            <nav className="wt-pagination wt-displaynone">
                <ul>
                    <li className="wt-prevpage"><NavLink to="#"><i className="lnr lnr-chevron-left" /></NavLink></li>
                    <li><NavLink to="#">1</NavLink></li>
                    <li><NavLink to="#">2</NavLink></li>
                    <li><NavLink to="#">3</NavLink></li>
                    <li><NavLink to="#">4</NavLink></li>
                    <li><NavLink to="#">...</NavLink></li>
                    <li><NavLink to="#">50</NavLink></li>
                    <li className="wt-nextpage"><NavLink to="#"><i className="lnr lnr-chevron-right" /></NavLink></li>
                </ul>
            </nav>
        </>
    )
}

export default PaginationBlock
