import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { get } from "lodash";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import {
  getJobDetails,
  sendJobDisputeByClient,
} from "../../../services/job/jobRequest";
import Loader from "../../../components/common/Loader";

const JobDispute = () => {
  const navigate = useNavigate();
  const job_id = useParams();
  const dispatch = useDispatch();
  const [jobDetails, setJobDetails] = useState([]);
  const [LoaderState, setLoaderState] = useState(false);



  const Schema = Yup.object().shape({
    dispute_text: Yup.string()
      .min(6, "Please enter more than 5 characters")
      .required("Please describe your dispute"),
      dispute_title:Yup.string()
      .required("Please dispute title"),
  });

  useEffect(() => {
    setLoaderState(true);
    const getData = async () => {
      let res = await getJobDetails(job_id.id, dispatch);
      console.log(res);
      if (res.status === 1) {
        get(res, "data.jobs", []).length > 0 && setJobDetails(res.data.jobs[0]);
      }
      setLoaderState(false);
    };
    getData();
  }, [dispatch, job_id]);
  return (
    <>
      <Header />
      <main id="wt-main" className="wt-main-dashboard wt-haslayout">
        <section className="wt-haslayout wt-dbsectionspace">
          <div className="row mt-5">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 ">
              <div className="wt-dashboardbox wt-dashboardtabsholder wt-accountsettingholder500">
                <div className="wt-tabscontent1 tab-content p-5">
                  <div className="wt-passwordholder" id="wt-password">
                    <div className="wt-changepassword">
                      <div className="wt-tabscontenttitle">
                        <h2>Dispute</h2>
                      </div>
                      <div className="row">
                        {LoaderState ? (
                          <Loader />
                        ) : (
                          <div className="col-12">
                            <div className="card">
                              <div className="card-header">
                                {jobDetails.job_title}
                              </div>
                              <div className="card-body custom_card_height">
                                <p className="card-text">
                                  {jobDetails.description}
                                </p>
                                <h5 className="card-title">
                                  Total Amount: {jobDetails.totalamount}
                                </h5>
                                <Formik
                                  initialValues={{
                                    dispute_title:"",
                                    dispute_text: ""
                                  }}
                                  validationSchema={Schema}
                                  onSubmit={async (values) => {
                                    setLoaderState(true);
                                    let post_data = {
                                      job_id: job_id.id,
                                      title:values.dispute_title,
                                      raise_against:jobDetails.workmate_id,//userid
                                      remark: values.dispute_text,
                                    };
                                    let response = await sendJobDisputeByClient(
                                      post_data,
                                      dispatch
                                    );
                                    if (response.status === 1) {
                                      toast.success(
                                        "Success! Dispute Submitted Successfully"
                                      );
                                      navigate('/dashboard');
                                    } else {
                                      toast.error(
                                        "Error! Please try again later"
                                      );
                                    }
                                    setLoaderState(false);
                                  }}
                                >
                                  {(props) => (
                                    <form
                                      className="wt-formtheme wt-userform"
                                      onSubmit={props.handleSubmit}
                                    >
                                      <fieldset>
                                      <div className="form-group">
                                          <Field
                                            placeholder="Describe your dispute"
                                            className="form-control"
                                            name="dispute_title"
                                          />
                                          {props.errors.dispute_title &&
                                            props.touched.dispute_title && (
                                              <span className="text-danger">
                                                {props.errors.dispute_title}
                                              </span>
                                            )}
                                        </div>
                                        <div className="form-group">
                                          <Field
                                            component="textarea"
                                            placeholder="Describe your dispute"
                                            className="form-control"
                                            name="dispute_text"
                                          />
                                          {props.errors.dispute_text &&
                                            props.touched.dispute_text && (
                                              <span className="text-danger">
                                                {props.errors.dispute_text}
                                              </span>
                                            )}
                                        </div>
                                      </fieldset>
                                      <fieldset>
                                        <div className="form-group">
                                          <button
                                            className="wt-btn"
                                            type="submit"
                                          >
                                            Save &amp; Continue
                                          </button>
                                        </div>
                                      </fieldset>
                                    </form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default JobDispute;
