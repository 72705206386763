import {
    FREELANCER_LIST_HOME_REQUEST,
    FREELANCER_LIST_HOME_SUCCESS,
    FREELANCER_LIST_HOME_FAIL
} from "../constants/freelancerConstants"

export const freelancerListHomeReducer = (state = { loading: true}, action) => {
    switch (action.type) {
        case FREELANCER_LIST_HOME_REQUEST:
            return { loading: true };
        case FREELANCER_LIST_HOME_SUCCESS:
            return { loading: false, freelancer_list_home:action.payload };
        case FREELANCER_LIST_HOME_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};