import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import CreateJob from "../../../components/dashboard/job_list/client/CreateJob";
import ProfileRightSide from "../../../components/dashboard/ProfileRightSide";
// import Sidebar from '../../../components/dashboard/Sidebar'

const CreateMyJob = () => {
    const userLoginData = JSON.parse(localStorage.getItem("userInfo"));
    // {userLoginData.user.role === 3 && (
  return (
    <>
      <Header />
      <main id="wt-main-dashboard" className="wt-main-dashboard wt-haslayout">
        {/* <Sidebar /> */}
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-9">
            <div className="wt-haslayout wt-dbsectionspace">
              <div className="wt-dashboardbox wt-dashboardtabsholder">
                <div className="wt-dashboardboxtitle">
                  <h2>Create Job</h2>
                </div>
                <div className="">
                  <CreateJob />
                </div>
              </div>
            </div>
          </div>
          <ProfileRightSide />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default CreateMyJob;
