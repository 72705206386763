import React,{useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux'
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import {setRegisterData,submitRegister} from '../../../../store/actions/userActions'

import AlreadyAccount from "../common/AlreadyAccount";
import JoinBlock from "../common/JoinBlock";

const RegisterFirst = ({ step, nextStep, prevStep }) => {
  const dispatch = useDispatch();
  const data_registration = useSelector((state) => state.data_registration);
  const phoneRegExp = /^\d{10}$/;
  const Schema = Yup.object().shape({
    userTitle: Yup.string()
      .max(10, "Please Enter valid title")
      .required("Full Name is Required"),
    firstName: Yup.string()
      .max(20, "Please enter less then 20 characters")
      .required("First Name is Required"),
    lastName: Yup.string()
      .max(20, "Please enter less then 20 characters")
      .required("Last Name is Required"),
    email: Yup.string()
      .email("Please enter valid email")
      .max(100, "Please enter less then 100 characters")
      .required("Email is required"),
    mobileNumber: Yup.string()
      // .max(10, "Phone Number is too Long!")
      // .length(10, "Phone number is not valid")
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone Number is required"),
  });

  useEffect(()=>{
   dispatch(submitRegister(1,{}));
  },[dispatch,data_registration]);

  return (
    <>
      <main id="wt-main" className="wt-main wt-haslayout wt-innerbgcolor">
        {/*Register Form Start*/}
        <div className="wt-haslayout wt-main-section">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-xs-12 col-sm-12 col-md-10 push-md-1 col-lg-8 push-lg-2">
                <div className="wt-registerformhold">
                  <div className="wt-registerformmain">
                    <JoinBlock />
                    <div className="wt-joinforms">
                      <ul className="wt-joinsteps">
                        <li className="wt-active">
                          <Link to={false}>01</Link>
                        </li>
                        <li>
                          <Link to={false}>02</Link>
                        </li>
                        <li>
                          <Link to={false}>03</Link>
                        </li>
                        <li>
                          <Link to={false}>04</Link>
                        </li>
                      </ul>
                      <Formik
                        initialValues={{
                          userTitle: data_registration.loading?'MR': data_registration.data_registration.user_title,
                          firstName: data_registration.loading?'': data_registration.data_registration.first_name,
                          lastName: data_registration.loading?'':data_registration.data_registration.last_name,
                          email: data_registration.loading?'':data_registration.data_registration.email,
                          mobileNumber: data_registration.loading?'':data_registration.data_registration.contact_number,
                        }}
                        validationSchema={Schema}
                        onSubmit={(values, actions) => {
                          let register_stage_1 = {
                            user_title: values.userTitle,
                            first_name: values.firstName,
                            last_name: values.lastName,
                            email: values.email,
                            contact_number:values.mobileNumber,
                          };
                          let registerData = { ...data_registration.data_registration,...register_stage_1};
                          dispatch(setRegisterData(registerData));
                          nextStep();
                        }}
                      >
                        {(props) => (
                          <form
                            className="wt-formtheme wt-formregister"
                            onSubmit={props.handleSubmit}
                          >
                            <fieldset className="wt-registerformgroup">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group wt-form-group-dropdown ">
                                    <span className="wt-select">
                                      <select
                                        name="userTitle"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.userTitle}
                                      >
                                        <option value="Mr">Mr.</option>
                                        <option value="Mrs">Mrs.</option>
                                      </select>
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="First Name"
                                      name="firstName"
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      value={props.values.firstName}
                                     
                                    />

                                    {props.errors.firstName &&
                                      props.touched.firstName && (
                                        <span className="text-danger">
                                          {props.errors.firstName}
                                        </span>
                                      )}
                                  </div>
                                </div>{" "}
                                <div className="col-md-6">
                                  <div className="form-group ">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Last Name"
                                      name="lastName"
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      value={props.values.lastName}
                                      
                                    />

                                    {props.errors.lastName &&
                                      props.touched.lastName && (
                                        <span className="text-danger">
                                          {props.errors.lastName}
                                        </span>
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group form-group-half1">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Email"
                                      name="email"
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      value={props.values.email}
                                     
                                    />

                                    {props.errors.email &&
                                      props.touched.email && (
                                        <span className="text-danger">
                                          {props.errors.email}
                                        </span>
                                      )}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group ">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Mobile Number"
                                      name="mobileNumber"
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      value={props.values.mobileNumber}
                                     
                                    />

                                    {props.errors.mobileNumber &&
                                      props.touched.mobileNumber && (
                                        <span className="text-danger">
                                          {props.errors.mobileNumber}
                                        </span>
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <button className="wt-btn" type="submit">
                                  Start Now
                                </button>
                              </div>
                            </fieldset>
                          </form>
                        )}
                      </Formik>
                      {/* <div className="wt-joinnowholder">
                        <div className="wt-title">
                          <h4>Join Now With</h4>
                        </div>
                        <div className="wt-description">
                          <p>
                            Use a social account for faster login or easy
                            registration to directly get in to your account and
                            start a good business
                          </p>
                        </div>
                        <ul className="wt-socialicons wt-iconwithtext">
                          <li className="wt-facebook">
                            <Link to={false}>
                              <i className="fa fa-facebook-f" />
                              <em>Facebook</em>
                            </Link>
                          </li>
                          <li className="wt-twitter">
                            <Link to={false}>
                              <i className="fab fa-twitter" />
                              <em>Twitter</em>
                            </Link>
                          </li>
                          <li className="wt-googleplus">
                            <Link to={false}>
                              <i className="fab fa-google-plus-g" />
                              <em>Google</em>
                            </Link>
                          </li>
                          <li className="wt-instagram">
                            <Link to={false}>
                              <i className="fab fa-instagram" />
                              <em>Instagram</em>
                            </Link>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                  <AlreadyAccount />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Register Form End*/}
      </main>
    </>
  );
};

export default RegisterFirst;
