import React, { useCallback, useEffect, useState } from "react";
import { Alert, Button, Container, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { HiLocationMarker } from "react-icons/hi";
import GoogleMapReact from "google-map-react";

import * as userActions from "../store/actions/userActions";
import Loader from "./common/Loader";

const Marker = () => (
  <div>
    <HiLocationMarker size={40} color="red" />
  </div>
);

const defaultMapProps = {
  center: {
    lat: 59.95,
    lng: 30.33,
  },
  zoom: 11,
};

const MINUTE_MS = 120000; // Time interval to hit api

export function MapViewModal({ open, userId, onClose }) {
  const dispatch = useDispatch();

  const [mapProps, setMapProps] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const changeMapProps = useCallback(async () => {
    if (!userId) return;
    try {
      setLoading(true);
      const result = await dispatch(userActions.getUserLocation({ userId }));
      console.log("Here", result);
      if (!result.data) {
        throw new Error("Not able to fetch location!");
      }

      setMapProps({
        ...defaultMapProps,
        center: { lat: result.data[0].lat, lng: result.data[0].lng },
      });
      setLoading(false);
    } catch (err) {
      setError(err.message || "Not able to fetch current location!");
      setLoading(false);
    }
  }, [userId, dispatch]);

  useEffect(() => {
    const interval =
      !error &&
      open &&
      setInterval(() => {
        changeMapProps();
      }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [changeMapProps, error, open]);

  useEffect(() => {
    if (open && !mapProps && !error) changeMapProps();
  }, [changeMapProps, open, error, mapProps]);

  if (loading) {
    return <Loader />;
  }

  return (
    mapProps && (
      <Modal show={open} onHide={onClose} onExiting={() => setMapProps(null)}>
        <h1 style={{ padding: "10px 20px 5px 20px", marginBottom: 0 }}>
          On Way
        </h1>
        <p style={{ padding: "0 20px 0 20px" }}>
          You are viewing live location.
        </p>
        <Alert
          show={!!error}
          variant="danger"
          style={{ margin: "0 20px 10px 20px" }}
        >
          {error}
        </Alert>
        <Container
          style={{
            height: "60vh",
            width: "100%",
            padding: "0px 20px 20px 20px",
          }}
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            }}
            defaultCenter={mapProps.center}
            defaultZoom={defaultMapProps.zoom}
            center={mapProps.center}
          >
            <Marker lat={mapProps.center.lat} lng={mapProps.center.lng} />
          </GoogleMapReact>
        </Container>
        <Button
          style={{ margin: "0 20px 20px 20px" }}
          type="button"
          onClick={onClose}
        >
          Exit
        </Button>
      </Modal>
    )
  );
}

export default MapViewModal;
