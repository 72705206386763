import React from "react";
import { Oval } from "react-loader-spinner";
function Loader() {
  return (
    <div className="main-loader">
      <Oval color="#1F1B49" height={50} width={50} />
    </div>
  );
}

export default Loader;
