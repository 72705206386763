import React, { useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import { APICall } from "../../../services";
import _ from "lodash";
import Loader from "../../../components/common/Loader";

export default function Transactions() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [transactions, setTransactions] = useState([]);
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [detailHtml, setDetailHtml] = useState("");

  useEffect(() => {
    async function fetchWalletDetails() {
      let { wallets, totalAmt, html } = await APICall("get", "transactions", userInfo.user._id);
      setTransactions(wallets);
      setTotalAmount(totalAmt);
      setDetailHtml(html);
      setLoaderStatus(false);
    }
    fetchWalletDetails();
  }, []);

  return (
    <>
      <Header />
      {loaderStatus && <Loader />}
      <main id="wt-main" className="wt-main-dashboard wt-haslayout">
        <section className="wt-haslayout wt-dbsectionspace">
          <div className="row mt-3 ml-4">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-11">
              <div className="wt-dashboardbox wt-dashboardtabsholder wt-accountsettingholder500 pb-5">
                <div className="wt-tabscontent1 tab-content p-5">
                  <div className="wt-passwordholder" id="wt-password">
                    <div className="wt-changepassword">
                      <h4 className="mb-2">Total Amount - ${totalAmount}</h4>
                      <div className="wt-tabscontenttitle mb-4">
                        <h2>Transactions History</h2>
                      </div>
                      {detailHtml ? (
                        <div dangerouslySetInnerHTML={{ __html: detailHtml }} />
                      ) : (
                        <>
                          <div className="d-flex justify-content-between w-100">
                            <span className="col-2 h6">Date</span>
                            <span className="col-2 h6">Status</span>
                            <span className="col-2 h6">Amount</span>
                          </div>
                          <hr />
                          {_.map(transactions, (transaction, index) => {
                            return (
                              <>
                                <div className="d-flex justify-content-between w-100" key={index}>
                                  <span className="col-2">
                                    {transaction.transaction_date ?? "-"}
                                  </span>
                                  <span className="col-2">
                                    {transaction.payment_status === 0 ? (
                                      <span>Pending</span>
                                    ) : (
                                      <span>Approved</span>
                                    )}
                                  </span>
                                  <span className="col-2">
                                    {transaction.transaction_type === 1 ? (
                                      <span>+</span>
                                    ) : (
                                      <span>-</span>
                                    )} ${transaction.amount}
                                  </span>
                                </div>
                                {transactions.length === index + 1 ? null : <hr />}
                              </>
                            );
                          })}
                        </>
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
