import Axios from "axios";
import {
  HOME_BANNER_REQUEST,
  HOME_BANNER_FAIL,
  HOME_BANNER_SUCCESS,
  HOME_HOW_IT_WORK_REQUEST,
  HOME_HOW_IT_WORK_FAIL,
  HOME_HOW_IT_WORK_SUCCESS,
  HOME_TOP_FREELANCER_HEADING_REQUEST,
  HOME_TOP_FREELANCER_HEADING_FAIL,
  HOME_TOP_FREELANCER_HEADING_SUCCESS,
  HOME_INTRODUCING_REQUEST,
  HOME_INTRODUCING_FAIL,
  HOME_INTRODUCING_SUCCESS,
  HOME_LATEST_ARTICLE_REQUEST,
  HOME_LATEST_ARTICLE_FAIL,
  HOME_LATEST_ARTICLE_SUCCESS,
  HOME_START_CMP_OR_FREE_REQUEST,
  HOME_START_CMP_OR_FREE_FAIL,
  HOME_START_CMP_OR_FREE_SUCCESS,
  HOME_EXPLORE_CATEGORY_REQUEST,
  HOME_EXPLORE_CATEGORY_FAIL,
  HOME_EXPLORE_CATEGORY_SUCCESS
} from "../constants/homeConstants";




export const homeBanner = ()=> async (dispatch) => {
  dispatch({
    type: HOME_BANNER_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      `${process.env.REACT_APP_API_URI}/api/v1/banners`
    );
    dispatch({ type: HOME_BANNER_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: HOME_BANNER_FAIL, payload: message });
  }
};

export const homeHowItWork = ()=> async (dispatch) => {
  dispatch({
    type: HOME_HOW_IT_WORK_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      `${process.env.REACT_APP_API_URI}/api/v1/staticblock/home_how_it_work`
    );
    dispatch({ type: HOME_HOW_IT_WORK_SUCCESS, payload: data.data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: HOME_HOW_IT_WORK_FAIL, payload: message });
  }
};

export const homeTopFreelancerHeading = ()=> async (dispatch) => {
  dispatch({
    type: HOME_TOP_FREELANCER_HEADING_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      `${process.env.REACT_APP_API_URI}/api/v1/staticblock/home_sec_top_freelancer_heading`
    );
    dispatch({ type: HOME_TOP_FREELANCER_HEADING_SUCCESS, payload: data.data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: HOME_TOP_FREELANCER_HEADING_FAIL, payload: message });
  }
};
export const homeIntroducing = ()=> async (dispatch) => {
  dispatch({
    type: HOME_INTRODUCING_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      `${process.env.REACT_APP_API_URI}/api/v1/staticblock/home_sec_introducing`
    );
    dispatch({ type: HOME_INTRODUCING_SUCCESS, payload: data.data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: HOME_INTRODUCING_FAIL, payload: message });
  }
};
export const homeLatestArticle = ()=> async (dispatch) => {
  dispatch({
    type: HOME_LATEST_ARTICLE_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      `${process.env.REACT_APP_API_URI}/api/v1/staticblock/home_sec_latest_article`
    );
    dispatch({ type: HOME_LATEST_ARTICLE_SUCCESS, payload: data.data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: HOME_LATEST_ARTICLE_FAIL, payload: message });
  }
};
export const homeStartCmpOrFree = ()=> async (dispatch) => {
  dispatch({
    type: HOME_START_CMP_OR_FREE_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      `${process.env.REACT_APP_API_URI}/api/v1/staticblock/home_sec_company_or_freelancer`
    );
    dispatch({ type: HOME_START_CMP_OR_FREE_SUCCESS, payload: data.data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: HOME_START_CMP_OR_FREE_FAIL, payload: message });
  }
};

export const homeExploreCategory = ()=> async (dispatch) => {
  dispatch({
    type: HOME_EXPLORE_CATEGORY_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      `${process.env.REACT_APP_API_URI}/api/v1/staticblock/home_sec_explore_category`
    );
    dispatch({ type: HOME_EXPLORE_CATEGORY_SUCCESS, payload: data.data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: HOME_EXPLORE_CATEGORY_FAIL, payload: message });
  }
};
