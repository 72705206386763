import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import {
  getPaypalClientId,
} from "../../../services/job/jobRequest";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { APICall } from "../../../services";

const WithdrawFunds = () => {
  const { state } = useLocation();
  const { jobId, totalPrice } = state || { jobId: null, totalPrice: null };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [paypalClientId, setPaypalClientId] = useState("");
  const [currentBalance, setCurrentBalance] = useState();
  const OnWithdrawFunds = async () => {
    const success_data = {
      amount: amount,
      transaction_type: 2,
      transaction_date: Date.now().toString(),
      statusCode: 200,
      ref_job_id: jobId ? jobId : "",
      remark: "Funds withdrawn",
    };

    const res_data = await APICall("post", "funds", success_data);
    if (res_data) {
      toast(res_data.message);
      navigate("/withdraw-success", {
        state: {
          amount: Number(amount),
        },
      });
    } else {
      toast("Error! Please try again later");
    }
  };
  const [amount, setAmount] = useState(totalPrice);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  //console.log(userInfo.user.bank_detail);
  const onAmountChange = (e) => {
    if (e.target.value >= 1) {
      setAmount(e.target.value);
    } else {
      setAmount(0);
    }
  };
  const goToBankPage = () => {
    navigate("/add-bank-detail");
  }
  useEffect(async () => {
    let response = await getPaypalClientId(dispatch);
    if (response) {
      setPaypalClientId(response.data.data.paypal.clientid);
    }
    let data = await APICall("post", "user-wallet", { token: userInfo.token });
    setCurrentBalance(data.userWalletAmt);
  }, []);

  if (paypalClientId === "") return null;

  return (
    <>
      <Header />
      <main id="wt-main" className="wt-main-dashboard wt-haslayout">
        <section className="wt-haslayout wt-dbsectionspace">
          <div className="row mt-3 ml-4">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-11">
              <div className="wt-dashboardbox wt-dashboardtabsholder wt-accountsettingholder500 pb-5">
                <div className="wt-tabscontent1 tab-content p-5">
                  <div className="wt-passwordholder" id="wt-password">
                    <div className="wt-changepassword">
                      <div className="wt-tabscontenttitle">
                        <h2>Withdraw Funds</h2>
                      </div>
                      <div className="ml-4">
                        <h5>Current Balance</h5>
                        <h5 className="my-3">$ {currentBalance}</h5>
                        {!userInfo.user?.bank_detail ? (
                          <>
                            <div>Click below button to add your bank details :</div>
                            <div className="mt-2">
                              <Button className="btn btn-success" onClick={goToBankPage}>
                                <span>Add Bank Details</span>
                              </Button>
                            </div>
                          </>
                        ) : (
                          <div className="d-flex">
                            <input
                              type="number"
                              placeholder="Enter Amount"
                              min={1}
                              value={amount}
                              disabled={totalPrice}
                              className="col-lg-2 col-sm-4 form-control mr-3"
                              onChange={onAmountChange}
                            />
                            <button
                              className="btn btn-success"
                              disabled={amount <= 0 || amount > currentBalance ? true : false}
                              onClick={OnWithdrawFunds}
                            >
                              Withdraw Funds
                            </button>
                          </div>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default WithdrawFunds;
