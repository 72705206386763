import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { postAvailability } from "../../../services/profile/availabilitySetting";
import Loader from "../../common/Loader";
import { getMyAvailability } from "../../../store/actions/profile/ProfileActions";
import { APICall } from "../../../services";

const AvailabilitySetting = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const data_my_availability = useSelector(
    (state) => state.data_my_availability
  );

  const [weeklyOffMon, setWeeklyOffMon] = useState(false);
  const [weeklyOffTue, setWeeklyOffTue] = useState(false);
  const [weeklyOffWed, setWeeklyOffWed] = useState(false);
  const [weeklyOffThu, setWeeklyOffThu] = useState(false);
  const [weeklyOffFri, setWeeklyOffFri] = useState(false);
  const [weeklyOffSat, setWeeklyOffSat] = useState(false);
  const [weeklyOffSun, setWeeklyOffSun] = useState(false);
  const [activeLoader, setActiveLoader] = useState(false);

  const userLoginData = JSON.parse(localStorage.getItem("userInfo"));

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setActiveLoader(true);
    let weekday_list = getWeekDayList();

    let post_data = {
      user_id: userLoginData.user._id,
      weekday_off: weekday_list,
      special_holiday: holiday_date_list,
      special_working_day: working_date_list,
      working_hour: slot_list,
    };

    let response = await postAvailability(post_data);
    if (response.status === 1) {
      toast("Success! Action has successfully completed");
      // navigate("/dashboard");
    } else {
      toast("Error! Please try again later");
    }
    setActiveLoader(false);
  };
  const getWeekDayList = () => {
    let weekday_list = "";
    if (weeklyOffMon) {
      weekday_list = weeklyOffMon && "Monday";
    }
    if (weeklyOffTue) {
      weekday_list += (weekday_list === "" ? "" : ",") + "Tuesday";
    }
    if (weeklyOffWed) {
      weekday_list += (weekday_list === "" ? "" : ",") + "Wednesday";
    }
    if (weeklyOffThu) {
      weekday_list += (weekday_list === "" ? "" : ",") + "Thursday";
    }
    if (weeklyOffFri) {
      weekday_list += (weekday_list === "" ? "" : ",") + "Friday";
    }
    if (weeklyOffSat) {
      weekday_list += (weekday_list === "" ? "" : ",") + "Saturday";
    }
    if (weeklyOffSun) {
      weekday_list += (weekday_list === "" ? "" : ",") + "Sunday";
    }

    return weekday_list;
  };
  const handleWeeklyOff = (name) => {
    switch (name) {
      case "weekday_off_monday":
        setWeeklyOffMon(!weeklyOffMon);
        break;
      case "weekday_off_tuesday":
        setWeeklyOffTue(!weeklyOffTue);
        break;
      case "weekday_off_wednesday":
        setWeeklyOffWed(!weeklyOffWed);
        break;
      case "weekday_off_thursday":
        setWeeklyOffThu(!weeklyOffThu);
        break;
      case "weekday_off_friday":
        setWeeklyOffFri(!weeklyOffFri);
        break;
      case "weekday_off_saturday":
        setWeeklyOffSat(!weeklyOffSat);
        break;
      case "weekday_off_sunday":
        setWeeklyOffSun(!weeklyOffSun);
        break;
      default:
        break;
    }
  };

  const [holiday_date_list, setHolidayDateList] = useState(
    !data_my_availability.loading && data_my_availability.data !== undefined
      ? data_my_availability.data.special_holiday
      : ""
  );

  const [holiday_date, setHolidayDate] = useState(new Date());
  const handleHolidayDate = (value) => {
    setHolidayDate(value);
  };

  const addHolidayList = () => {
    let value = Moment(holiday_date).format("DD-MM-YYYY");
    let flag = holiday_date_list.includes(value);
    if (!flag) {
      let list =
        holiday_date_list + (holiday_date_list === "" ? "" : ",") + value;
      setHolidayDateList(list);
    }
  };

  const [working_date_list, setWorkingDateList] = useState(
    !data_my_availability.loading && data_my_availability.data !== undefined
      ? data_my_availability.data.special_working_day
      : ""
  );
  const [working_date, setSelectedDate] = useState(new Date());
  const handleWorkingDate = (value) => {
    setSelectedDate(value);
  };

  const addWorkingList = () => {
    let value = Moment.utc(working_date).format("DD-MM-YYYY");
    let flag = working_date_list.includes(value);
    if (!flag) {
      let list =
        working_date_list + (working_date_list === "" ? "" : ",") + value;
      setWorkingDateList(list);
    }
  };
  const [slot_from, setSlotFrom] = useState("");
  const handleSlotFrom = (e) => {
    setSlotFrom(e.target.value);
  };
  const [slot_to, setSlotTo] = useState("");
  const handleSlotTo = (e) => {
    setSlotTo(e.target.value);
  };

  const [slot_list, setSlotList] = useState(
    !data_my_availability.loading && data_my_availability.data !== undefined
      ? data_my_availability.data.working_hour
      : ""
  );
  const addSlotList = () => {
    let slot = slot_from + " to " + slot_to;
    let list_data = slot_list + (slot_list === "" ? "" : ",") + slot;
    setSlotList(list_data);
  };

  useEffect(() => {
    dispatch(getMyAvailability());
  }, [dispatch]);

  useEffect(() => {
    setWorkingDateList(
      !data_my_availability.loading && data_my_availability.data !== undefined
        ? data_my_availability.data.special_holiday
        : ""
    );
    setHolidayDateList(
      !data_my_availability.loading && data_my_availability.data !== undefined
        ? data_my_availability.data.special_working_day
        : ""
    );
    setSlotList(
      !data_my_availability.loading && data_my_availability.data !== undefined
        ? data_my_availability.data.working_hour
        : ""
    );
    if (
      !data_my_availability.loading &&
      data_my_availability.data !== undefined
    ) {
      let weekday_list_array = data_my_availability.data.weekday_off.split(",");
      weekday_list_array.map((v, i) => {
        let value = v.replace(/\s/g, "");
        switch (value) {
          case "Monday":
            setWeeklyOffMon(true);
            break;
          case "Tuesday":
            setWeeklyOffTue(true);
            break;
          case "Wednesday":
            setWeeklyOffWed(true);
            break;
          case "Thursday":
            setWeeklyOffThu(true);
            break;
          case "Friday":
            setWeeklyOffFri(true);
            break;
          case "Saturday":
            setWeeklyOffSat(true);
            break;
          case "Sunday":
            setWeeklyOffSun(true);
            break;
          default:
            break;
        }
        return true;
      });
    }
  }, [data_my_availability]);

  return (
    <>
      <div
        className="wt-personalskillshold tab-pane fade"
        id="tab-availability-setting"
      >
        <div className="wt-yourdetails wt-tabsinfo">
          <div className="wt-tabscontenttitle">
            <h2>Availability Setting</h2>
          </div>
          {activeLoader && <Loader />}
          <form
            className="wt-formtheme wt-userform"
            onSubmit={formSubmitHandler}
          >
            <fieldset>
              <label className="font-weight-bold">Weekday Off</label>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="font-weight-bold">
                      <input
                        type="checkbox"
                        name="weekday_off_monday"
                        checked={weeklyOffMon}
                        onChange={(e) => handleWeeklyOff("weekday_off_monday")}
                      />{" "}
                      Monday
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="font-weight-bold">
                      <input
                        type="checkbox"
                        name="weekday_off_tuesday"
                        checked={weeklyOffTue}
                        onChange={(e) => handleWeeklyOff("weekday_off_tuesday")}
                      />{" "}
                      Tuesday
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="font-weight-bold">
                      <input
                        type="checkbox"
                        name="weekday_off_wednesday"
                        checked={weeklyOffWed}
                        onChange={(e) =>
                          handleWeeklyOff("weekday_off_wednesday")
                        }
                      />{" "}
                      Wednesday
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="font-weight-bold">
                      <input
                        type="checkbox"
                        name="weekday_off_thursday"
                        checked={weeklyOffThu}
                        onChange={(e) =>
                          handleWeeklyOff("weekday_off_thursday")
                        }
                      />{" "}
                      Thursday
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="font-weight-bold">
                      <input
                        type="checkbox"
                        name="weekday_off_friday"
                        checked={weeklyOffFri}
                        onChange={(e) => handleWeeklyOff("weekday_off_friday")}
                      />{" "}
                      Friday
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="font-weight-bold">
                      <input
                        type="checkbox"
                        name="weekday_off_saturday"
                        checked={weeklyOffSat}
                        onChange={(e) =>
                          handleWeeklyOff("weekday_off_saturday")
                        }
                      />{" "}
                      Saturday
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="font-weight-bold">
                      <input
                        type="checkbox"
                        name="weekday_off_sunday"
                        checked={weeklyOffSun}
                        onChange={(e) => handleWeeklyOff("weekday_off_sunday")}
                      />{" "}
                      Sunday
                    </label>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="row">
                <div className="col-12">
                  <label className="font-weight-bold">Special Holiday</label>
                  <div className="form-group form-group-half mb-2">
                    <DatePicker
                      className="form-control"
                      placeholderText=""
                      dateFormat="dd-MM-yyyy"
                      name="holiday_date"
                      selected={holiday_date}
                      minDate={Moment().toDate()}
                      onChange={(date) => handleHolidayDate(date)}
                    />
                  </div>
                  <div className="form-group form-group-half mb-2">
                    <button
                      className="btn btn-sm btn-success"
                      type="button"
                      onClick={() => addHolidayList()}
                    >
                      Add
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  {holiday_date_list !== "" && (
                    <div className="custom_box p-2">
                      <span>{holiday_date_list}</span>
                      {/* <span>
                      date{" "}
                      <i className="fa fa-trash text-danger cursor pull-right"></i>
                    </span> */}
                      <i
                        className="fa fa-trash text-danger cursor pull-right"
                        onClick={() => setHolidayDateList("")}
                      ></i>
                    </div>
                  )}
                </div>
              </div>
              <hr></hr>
              <div className="row">
                <div className="col-12">
                  <label className="font-weight-bold">
                    Special Working Day
                  </label>
                  <div className="form-group form-group-half mb-2">
                    <DatePicker
                      className="form-control"
                      placeholder=""
                      dateFormat="dd-MM-yyyy"
                      name="working_date"
                      selected={working_date}
                      minDate={Moment().toDate()}
                      onChange={(date) => handleWorkingDate(date)}
                    />
                  </div>
                  <div className="form-group form-group-half mb-2">
                    <button
                      className="btn btn-sm btn-success"
                      type="button"
                      onClick={() => addWorkingList()}
                    >
                      Add
                    </button>
                  </div>
                </div>

                <div className="col-md-12">
                  {working_date_list !== "" && (
                    <div className="custom_box p-2">
                      <span>{working_date_list}</span>
                      {/* <span>
                      date{" "}
                      <i className="fa fa-trash text-danger cursor pull-right"></i>
                    </span> */}
                      <i
                        className="fa fa-trash text-danger cursor pull-right"
                        onClick={() => setWorkingDateList("")}
                      ></i>
                    </div>
                  )}
                </div>
              </div>
              <hr></hr>
              <div className="row">
                <div className="col-12">
                  <label className="font-weight-bold">Time Slot</label>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="form-group mb-2">
                    <div>Time from</div>
                    {/* <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      name="slot_from"
                      value={slot_from}
                      onChange={(e) => handleSlotFrom(e)}
                    /> */}
                    <select
                      defaultValue={"DEFAULT"}
                      onChange={(e) => handleSlotFrom(e)}
                    >
                      <option value="DEFAULT" disabled>
                        Select
                      </option>
                      {[...Array(24)].map((x, i) => {
                        return (
                          <option key={i + 1} value={i + 1}>
                            {i + 1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group mb-2">
                    <div>Time To</div>
                    {/* <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      name="slot_to"
                      value={slot_to}
                      onChange={(e) => handleSlotTo(e)}
                    /> */}
                    <select
                      defaultValue={"DEFAULT"}
                      onChange={(e) => handleSlotTo(e)}
                    >
                      <option value="DEFAULT" disabled>
                        Select
                      </option>
                      {[...Array(24)].map((x, i) => {
                        return (
                          <option key={i + 1} value={i + 1}>
                            {i + 1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <button
                    className="btn btn-sm btn-success pull-right"
                    type="button"
                    onClick={() => addSlotList()}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {slot_list !== "" && (
                    <div className="custom_box p-2">
                      {slot_list}
                      {/* <span>
                      date{" "}
                      <i className="fa fa-trash text-danger cursor pull-right"></i>
                    </span> */}
                      <i
                        className="fa fa-trash text-danger cursor pull-right"
                        onClick={() => setSlotList("")}
                      ></i>
                    </div>
                  )}
                </div>
              </div>
              <hr></hr>
            </fieldset>
            <button className="wt-btn mt-5 mb-5" type="submit">
              Save &amp; Update
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AvailabilitySetting;
