import Axios from "axios"
import {
    CATEGORY_LIST_REQUEST,
    CATEGORY_LIST_SUCCESS,
    CATEGORY_LIST_FAIL,
    SKILLS_LIST_REQUEST,
    SKILLS_LIST_SUCCESS,
    SKILLS_LIST_FAIL
} from "../constants/categoryConstants"

export const listCategories = () => async (dispatch) => {
    dispatch({
        type: CATEGORY_LIST_REQUEST
    });
    try {
        const { data } = await Axios.get(`${process.env.REACT_APP_API_URI}/api/v1/parent-categories`);
        dispatch({ type: CATEGORY_LIST_SUCCESS, payload: data.parentCategories });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: CATEGORY_LIST_FAIL, payload: message });
    }
};

export const listSkills = () => async (dispatch) => {
    dispatch({
        type: SKILLS_LIST_REQUEST
    });
    try {
        const { data } = await Axios.get(`${process.env.REACT_APP_API_URI}/api/v1/skills`);
        dispatch({ type: SKILLS_LIST_SUCCESS, payload: data.skills });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: SKILLS_LIST_FAIL, payload: message });
    }
};