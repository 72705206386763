import Axios from "axios"
import {
    FOOTER_1_REQUEST,
    FOOTER_1_SUCCESS,
    FOOTER_1_FAIL,
    FOOTER_2_REQUEST,
    FOOTER_2_SUCCESS,
    FOOTER_2_FAIL,
    FOOTER_3_REQUEST,
    FOOTER_3_SUCCESS,
    FOOTER_3_FAIL,
    FOOTER_NEWSLETTER_REQUEST,
    FOOTER_NEWSLETTER_SUCCESS,
    FOOTER_NEWSLETTER_FAIL,
    FOOTER_RIGHTS_REQUEST,
    FOOTER_RIGHTS_SUCCESS,
    FOOTER_RIGHTS_FAIL
} from "../../constants/common/common"


export const LoadFooter1 = () => async (dispatch) => {
    dispatch({
        type: FOOTER_1_REQUEST
    });
    try {
        const { data } = await Axios.get(`${process.env.REACT_APP_API_URI}/api/v1/staticblock/Footer1`);
        dispatch({ type: FOOTER_1_SUCCESS, payload: data.data});
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: FOOTER_1_FAIL, payload: message });
    }
};

export const LoadFooter2 = () => async (dispatch) => {
    dispatch({
        type: FOOTER_2_REQUEST
    });
    try {
        const { data } = await Axios.get(`${process.env.REACT_APP_API_URI}/api/v1/staticblock/Footer2`);
        dispatch({ type: FOOTER_2_SUCCESS, payload: data.data});
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: FOOTER_2_FAIL, payload: message });
    }
};

export const LoadFooter3 = () => async (dispatch) => {
    dispatch({
        type: FOOTER_3_REQUEST
    });
    try {
        const { data } = await Axios.get(`${process.env.REACT_APP_API_URI}/api/v1/staticblock/footer3`);
        dispatch({ type: FOOTER_3_SUCCESS, payload: data.data});
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: FOOTER_3_FAIL, payload: message });
    }
};



export const LoadFooterNewsletter = () => async (dispatch) => {
    dispatch({
        type: FOOTER_NEWSLETTER_REQUEST
    });
    try {
        const { data } = await Axios.get(`${process.env.REACT_APP_API_URI}/api/v1/staticblock/Newsletter`);
        dispatch({ type: FOOTER_NEWSLETTER_SUCCESS, payload: ((data.status===200)?data.data:data.message)}); //add throw
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: FOOTER_NEWSLETTER_FAIL, payload: message });
    }
};

export const LoadFooterRights = () => async (dispatch) => {
    dispatch({
        type: FOOTER_RIGHTS_REQUEST
    });
    try {
        const { data } = await Axios.get(`${process.env.REACT_APP_API_URI}/api/v1/staticblock/footer_rights`);
        dispatch({ type: FOOTER_RIGHTS_SUCCESS, payload: ((data.status===200)?data.data:data.message)}); //add throw
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: FOOTER_RIGHTS_FAIL, payload: message });
    }
};