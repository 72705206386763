export const FREELANCER_LIST_REQUEST = 'FREELANCER_LIST_REQUEST';
export const FREELANCER_LIST_FAIL = 'FREELANCER_LIST_FAIL';
export const FREELANCER_LIST_SUCCESS = 'FREELANCER_LIST_SUCCESS';
export const FREELANCER_DETAIL_REQUEST = 'FREELANCER_DETAIL_REQUEST';
export const FREELANCER_DETAIL_FAIL = 'FREELANCER_DETAIL_FAIL';
export const FREELANCER_DETAIL_SUCCESS = 'FREELANCER_DETAIL_SUCCESS';
export const FREELANCER_LIST_HOME_REQUEST = 'FREELANCER_LIST_HOME_REQUEST';
export const FREELANCER_LIST_HOME_SUCCESS = 'FREELANCER_LIST_HOME_SUCCESS';
export const FREELANCER_LIST_HOME_FAIL = 'FREELANCER_LIST_HOME_FAIL';

