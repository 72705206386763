import Axios from "axios";
import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_SIGNOUT,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_TOPSELLERS_LIST_REQUEST,
  USER_TOPSELLERS_LIST_SUCCESS,
  USER_TOPSELLERS_LIST_FAIL,
  AUTH_REGISTER_PROCESS_REQUEST,
  AUTH_REGISTER_PROCESS_SUCCESS,
  AUTH_REGISTER_PROCESS_FAIL,
  USER_BANK_REQUEST,
  USER_BANK_SUCCESS,
  USER_BANK_FAIL,
} from "../constants/userConstants";
import { toast } from "react-toastify";

export const signin = (email, password) => async (dispatch) => {
  dispatch({
    type: USER_LOGIN_REQUEST,
    payload: {
      email,
      password,
    },
  });
  try {
    const { data } = await Axios.post(
      `${process.env.REACT_APP_API_URI}/api/v1/login`,
      { email, password }
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    toast.success(data.message);
    localStorage.setItem("userInfoToken", data.token);
    localStorage.setItem("userInfo", JSON.stringify(data));
    // localStorage.setItem("userInfo", JSON.stringify(data.user));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const DirectLogin = (data) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    localStorage.setItem("userInfoToken", data.token);
    localStorage.setItem("userInfo", JSON.stringify(data));
    localStorage.setItem("add_bank_account", 0);
    // localStorage.setItem("userInfo", JSON.stringify(data.user));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};
export const submitRegister = (step_type, post_data) => async (dispatch) => {
  dispatch({
    type: USER_REGISTER_REQUEST,
    // payload: {
    //     name,
    //     email,
    //     password
    // }
  });
  try {
    if (step_type === 1) {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });
    } else {
      const formData = new FormData();
      for (const key in post_data) {
        formData.append(key, post_data[key]);
      }
      const { data } = await Axios.post(
        `${process.env.REACT_APP_API_URI}/api/v1/register`,
        formData
      );
      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });
      return data;
      // localStorage.setItem("userinfo", JSON.stringify(data));
    }
    // localStorage.setItem("userInfoToken", data.token);
    // localStorage.setItem("userInfo", JSON.stringify(data.user));
    // dispatch({
    //     type: USER_LOGIN_SUCCESS,
    //     payload: data
    // });
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const setRegisterData = (data) => async (dispatch) => {
  dispatch({ type: AUTH_REGISTER_PROCESS_REQUEST });
  try {
    dispatch({ type: AUTH_REGISTER_PROCESS_SUCCESS, payload: data });
  } catch (error) {
    const message = "Error";
    dispatch({ type: AUTH_REGISTER_PROCESS_FAIL, payload: message });
  }
};

export const signout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("cartItems");
  localStorage.removeItem("shippingAddress");
  localStorage.removeItem("userInfoToken");
  localStorage.removeItem("add_bank_account");
  dispatch({
    type: USER_SIGNOUT,
  });
  // toast.success("Logout successfully");
};

export const detailsUser = (userId) => async (dispatch, getState) => {
  dispatch({ type: USER_DETAILS_REQUEST, payload: userId });
  const {
    userLogin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.get(`/api/users/${userId}`, {
      headers: { Authorization: `Bearer ${userInfo?.token}` },
    });
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_DETAILS_FAIL, payload: message });
  }
};
export const updateUserProfile = (user) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
  const {
    userLogin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.put(`/api/users/profile`, user, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: message });
  }
};
export const updateUser = (user) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
  const {
    userLogin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.put(`/api/users/${user._id}`, user, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_UPDATE_FAIL, payload: message });
  }
};
export const listUsers = () => async (dispatch, getState) => {
  dispatch({ type: USER_LIST_REQUEST });
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const { data } = await Axios.get("/api/users", {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    });
    dispatch({ type: USER_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_LIST_FAIL, payload: message });
  }
};
export const deleteUser = (userId) => async (dispatch, getState) => {
  dispatch({ type: USER_DELETE_REQUEST, payload: userId });
  const {
    userLogin: { userInfo },
  } = getState();
  try {
    const { data } = await Axios.delete(`/api/users/${userId}`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: USER_DELETE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_DELETE_FAIL, payload: message });
  }
};
export const listTopSellers = () => async (dispatch) => {
  dispatch({ type: USER_TOPSELLERS_LIST_REQUEST });
  try {
    const { data } = await Axios.get("/api/users/top-sellers");
    dispatch({ type: USER_TOPSELLERS_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_TOPSELLERS_LIST_FAIL, payload: message });
  }
};

// Save user location
export const saveUserLocation =
  ({ userId, lat, lng }) =>
    async (dispatch, getState) => {
      const {
        userLogin: { userInfo },
      } = getState();
      try {
        const payload = {
          user_id: userId,
          lat,
          lng,
        };
        const { data } = await Axios.post(
          `${process.env.REACT_APP_API_URI}/api/v1/savelocation`,
          payload,
          { headers: { Authorization: `Bearer ${userInfo.token}` } }
        );
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

export const getUserLocation =
  ({ userId, lat, lng }) =>
    async (_, getState) => {
      const {
        userLogin: { userInfo },
      } = getState();
      try {
        const payload = {
          user_id: userId,
          lat,
          lng,
        };
        const { data } = await Axios.post(
          `${process.env.REACT_APP_API_URI}/api/v1/getlocation`,
          payload,
          { headers: { Authorization: `Bearer ${userInfo.token}` } }
        );
        return data;
      } catch (error) {
        throw error;
      }
    };

export const denyUserLocation =
  ({ userId, lat, lng }) =>
    async (dispatch, getState) => {
      const {
        userLogin: { userInfo },
      } = getState();
      try {
        const payload = {
          user_id: userId,
          lat,
          lng,
        };
        const { data } = await Axios.post(
          `${process.env.REACT_APP_API_URI}/api/v1/denylocation`,
          payload,
          { headers: { Authorization: `Bearer ${userInfo.token}` } }
        );
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

export const submitUserBankInfo = (post_data) => async (dispatch, getState) => {
  dispatch({
    type: USER_BANK_REQUEST,
  });
  const {
    userLogin: { userInfo },
  } = getState();
  try {
    const formData = new FormData();
    for (const key in post_data) {
      formData.append(key, post_data[key]);
    }
    const { data } = await Axios.post(`${process.env.REACT_APP_API_URI}/api/v1/bank-details`, formData, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({
      type: USER_BANK_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: USER_BANK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}
