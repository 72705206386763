import React, { useState, useEffect } from "react";
import { size } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { homeLatestArticle } from "../../store/actions/homeContentAction";
import { unescapeHTML } from "../common/Common";

const Articles = () => {
  const articleDetail = useSelector((state) => state);
  const { loading } = articleDetail;
  //   const categoryList = useSelector((state) => state.categoryList);//base_path

  let article_data = (articleDetail.article_data.article_data!== undefined)?articleDetail.article_data.article_data:[];
  let base_path =  (articleDetail.article_data.article_data!== undefined)?articleDetail.article_data.article_data.base_path:'';

  //   const { loading } = categoryList;
  const articleDate = (article_date) => {
    let d = new Date(article_date);
    return (
      d.toLocaleString("default", { month: "short" }) +
      " " +
      d.getDate() +
      ", " +
      d.getYear()
    );
  };

  const dispatch = useDispatch();
  const data_home = useSelector((state) => state.data_home_latest_article);
  const [loadingFlag, setLoadingFlag] = useState(data_home.loading);
  async function getData() {
    await Promise.all([dispatch(homeLatestArticle())]);
    setLoadingFlag(false);
  }
  useEffect(() => {
    data_home.loading && getData();
  }, []);

  return (
    <>
      <section className="wt-haslayout wt-main-section wt-latearticles">
        <div className="container">
          <div className="row justify-content-md-center">
            {!loadingFlag && data_home.data.isActive == 1 && (
              <div
                className="col-lg-8"
                dangerouslySetInnerHTML={{
                  __html: unescapeHTML(data_home.data.blockContent),
                }}
              ></div>
            )}
            <div className="wt-articlesholder">
              {!loading &&
                size(article_data.posts) > 0 &&
                article_data.posts.map((value, index) => (
                  <div
                    key={index}
                    className="col-12 col-sm-12 col-md-6 col-lg-4 float-left"
                  >
                    <div className="wt-articles">
                      <figure className="wt-articleimg">
                        {value.hasOwnProperty("media_link") && (
                          <img
                            src={base_path + value.media_link}
                            alt="img description"
                          />
                        )}
                      </figure>
                      <div className="wt-articlecontents">
                        <div className="wt-title">
                          <h3>
                            <a href="#">{value.title}</a>
                          </h3>
                          <span className="wt-datetime">
                            <i className="ti-calendar" />{" "}
                            {articleDate(value.created_at)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Articles;
