import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { size } from "lodash";
import { toast } from "react-toastify";
import { FreelancerDetail } from "../../store/actions/freelancerDetailActions";
import { sendInviteJobRequest } from "../../services/job/jobRequest";
import Loader from "../common/Loader";
import _ from "lodash";
import Modal from 'react-bootstrap/Modal';
// import RatingBlock from './RatingBlock';

const UserListBlock = ({ userinfo, baseurl, invitedUsers, perHourPrice, selectskill }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeLoader, setActiveLoader] = useState(false);
  const [inviteText, setInviteText] = useState("Invite");
  const [inviteBtn, setInviteBtn] = useState(false);
  const [isInvited, setIsInvited] = useState(false);
  const url_param = useParams();
  const jobActive = size(url_param) > 0 ? true : false;
  const job_id = jobActive ? url_param.id : "";

  //Modal Changes Start
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //Modal Changes End
  //console.log(selectskill);
  const navigateToCreateJob = () => {
    localStorage.setItem('selectskill', JSON.stringify(selectskill));
    navigate("/create-job");
  }
  useEffect(() => {
    setIsInvited(_.includes(invitedUsers, userinfo._id));
  }, []);

  //   const { work_profiles, profiles } = userinfo;
  //   const flag_work_profile = size(work_profiles) > 0 ? true : false;
  //   const flag_profiles = size(profiles) > 0 ? true : false;
  //   const key_skill =
  //     size(profiles) > 0 ? userinfo.work_profiles.skills_set.split(",") : [];

  const getFreelancerDetails = (id) => {
    dispatch(FreelancerDetail(id));
    navigate("/user/" + id);
  };

  const sendJobInvitation = async (job_id, userinfo) => {
    setActiveLoader(true);
    setInviteBtn(true);
    perHourPrice(userinfo.price);
    let post_data = { job_id: job_id, workmate_id: userinfo._id, amount: userinfo.price };
    let response = await sendInviteJobRequest(post_data, dispatch);
    if (response.status === 1) {
      setInviteText("Invitation Sent");
      toast("Success! Action has successfully completed");
    } else {
      toast("Error! Please try again later");
      setInviteBtn(false);
    }
    setActiveLoader(false);
  };
  //   useEffect(() => {

  //   }, [userinfo]);

  return (
    <>
      {" "}
      {activeLoader && <Loader />}
      <div className="wt-userlistinghold wt-featured">
        <span className="wt-featuredtag"></span>
        <figure className="wt-userlistingimg">
          <img
            src={
              userinfo.profile_image
                ? baseurl + userinfo.profile_image
                : "/assets/images/profile/avtar250.jpg"
            }
            alt={userinfo.first_name + " " + userinfo.last_name}
            className="img-md"
          />
        </figure>
        <div className="wt-userlistingcontent">
          <div className="wt-contenthead">
            <div className="wt-title">
              <span
                className="btn"
                onClick={() => getFreelancerDetails(userinfo._id)}
              >
                <i className={`fa fa-check-circle ${userinfo.isOnline ? '' : 'grey'} `} />{" "}
                {userinfo.first_name + " " + userinfo.last_name}
              </span>
              <h2 className="m-0">
                {size(userinfo.work_profiles) > 0 &&
                  userinfo.work_profiles.work_title}
              </h2>
              <span>({userinfo.experience_level})</span>
            </div>
            {!jobActive && (
              <button
                type="button"
                className="wt-btn pull-right"
                onClick={navigateToCreateJob}
              >
                Create Job to Invite
              </button>
            )}
            <ul className="wt-userlisting-breadcrumb">
              <li>
                <span>
                  <i className="far fa-money-bill-alt" /> ${userinfo.price}
                </span>
              </li>
              <li>
                <span>
                  {" "}
                  {size(userinfo.profiles) > 0 && userinfo.profiles.country}
                </span>
              </li>
              {/* <li>
                <NavLink
                  to="#"
                  className={userinfo.like === "Save" ? `wt-clicksave` : ""}
                >
                  <i className="fa fa-heart" /> Save
                </NavLink>
              </li> */}
            </ul>
          </div>
          {jobActive && (
            <div className="wt-rightarea">
              <button
                type="button"
                className="wt-btn"
                disabled={inviteBtn || isInvited}
                onClick={() => sendJobInvitation(job_id, userinfo)}
              >
                {isInvited ? (
                  <>
                    <i className="fa fa-check" /> Already Invited
                  </>
                ) : (
                  inviteText
                )}
              </button>
            </div>
          )}
          <div className="wt-rightarea">
            {/* <RatingBlock rating={userinfo.rating} /> */}
            {/* <span className="wt-starcontent">{userinfo.rating}/<sub>5</sub> <em>{userinfo.feedback}</em></span> */}
          </div>
        </div>
        <div className="wt-description">
          <p>
            {size(userinfo.work_profiles) > 0 &&
              userinfo.work_profiles.work_description}
          </p>
        </div>
        <div className="wt-tag wt-widgettag">
          {size(userinfo.work_profiles) > 0 &&
            userinfo.work_profiles.skills_set.split(",").map((skill, index) => (
              <NavLink to="#" key={index} onClick={handleShow}>
                {skill}
              </NavLink>
            ))}
          {size(userinfo.profiles) > 0 && <NavLink to="#">...</NavLink>}
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Skill Set</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Skill Name</th>
                <th scope="col">Price</th>
              </tr>
            </thead>
            <tbody>

              {size(userinfo.work_profiles) > 0 &&
                userinfo.skills.map((skill, index) => (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{skill.name}</td>
                    <td>{skill.price}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserListBlock;
