import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import RegisterFirst from "../../../components/auth/registration/professional/RegisterFirst";
// import RegisterFifth from "../../components/main/RegisterFifth";
import RegisterSecond from "../../../components/auth/registration/professional/RegisterSecond";
import RegisterFourth from "../../../components/auth/registration/professional/RegisterFourth";
import {
  setRegisterData,
  submitRegister,
} from "../../../store/actions/userActions";
import { setCountryList } from "../../../store/actions/common/commonActions";
import { listCategories } from "../../../store/actions/categoryActions";
import { useDispatch } from "react-redux";
import RegisterThird from "../../../components/auth/registration/professional/RegisterThird";
import { useNavigate } from "react-router-dom";
const RegisterAsProfessional = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  // proceed to the next step
  const nextStep = () => {
    setStep(step + 1);
  };
  // go back to previous step
  const prevStep = () => {
    setStep(step - 1);
  };
  const gotoStep = (stepNumber) => {
    setStep(stepNumber);
  }

  const gotoAddBankPage = () => {
    console.log('is Here');
    navigate("/add-bank-detail");
  }

  // const data_country_list = useSelector((state) => state.data_country_list);
  // const register_store_data = useSelector((state) => state);

  // const getCountryList = () => {
  //   return data_country_list.loading ? dispatch(setCountryList()) : null;
  // };
  let flag = true;

  useEffect(() => {
    const registerData = {
      user_title: "MR",
      first_name: "",
      last_name: "",
      email: "",
      contact_number: "",
      country: "",
      country_id: "",
      state: "",
      state_id: "",
      city: "",
      password: "",
      profileImage: {},
      role: 4, // 3 company, 4 = freelancer
      company_emp_no: "",
      company_department: "",
      newsletter: false,
      terms: false,
      address: "",
      lat: "",
      lang: "",
      reference: "",
      working_rights: "",
      category: "",
      experience: "",
      working_hours: "",
      job_type: "",
      police_check: "",
      have_car: "",
      work_area: [],
      add_bank_account: 0,
    };
    dispatch(setRegisterData(registerData));
    dispatch(submitRegister(1, {}));
    dispatch(setCountryList());
    dispatch(listCategories());
  }, [dispatch, flag]);
  return (
    <>
      <Header />
      <div className="wt-haslayout wt-innerbannerholder">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-xs-12 col-sm-12 col-md-8 push-md-2 col-lg-6 push-lg-3">
              <div className="wt-innerbannercontent">
                <div className="wt-title">
                  <h2>Join Now For FREE</h2>
                </div>
                <ol className="wt-breadcrumb">
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li className="wt-active">Join Now</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(() => {
        switch (step) {
          case 1:
            return (
              <RegisterFirst
                step={step}
                nextStep={nextStep}
                prevStep={prevStep}
              />
            );
          case 2:
            return (
              <RegisterSecond
                step={step}
                nextStep={nextStep}
                prevStep={prevStep}
                gotoStep={gotoStep}
              />
            );
          case 3:
            return (
              <RegisterThird
                step={step}
                nextStep={nextStep}
                prevStep={prevStep}
                goToStep={gotoStep}
              />
            );
          case 4:
            return (
              <RegisterFourth
                step={step}
                nextStep={gotoAddBankPage}
                prevStep={prevStep}
              />
            );
          default:
            return (
              <RegisterFirst
                step={step}
                nextStep={nextStep}
                prevStep={prevStep}
              />
            );
        }
      })()}
      <Footer />
    </>
  );
};

export default RegisterAsProfessional;
