import React from "react";
// import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import Home from "./components/Home";
import AboutUs from "./pages/public/home/AboutUs";
import ContactUs from "./pages/public/home/ContactUs";
import PrivacyPolicy from "./pages/public/home/PrivacyPolicy";
import Terms from "./pages/public/home/Terms";
import Page from "./pages/public/home/Page";
import PrivateRoute from "./components/auth/PrivateRoute";
import OnlyPublicRoute from "./components/auth/OnlyPublicRoute";
// import UserAvailability from "./components/dashboard/user_availability";

import FreelancerList from "./pages/private/find_freelancer/FreelancerList";
import FreelancerInfo from "./pages/private/find_freelancer/FreelancerInfo";

import Dashboard from "./pages/private/dashboard/Dashboard";
import ChangePassword from "./pages/private/auth/ChangePassword";
// import Chat from './pages/private/chat'
import UserProfile from "./pages/private/dashboard/UserProfile";
import MyJobList from "./pages/private/dashboard/MyJobList";
import CreateMyJob from "./pages/private/dashboard/CreateMyJob";
import Membership from "./pages/private/membership";
import JobDispute from "./pages/private/dashboard/JobDispute";
import JobDisputeDiscussion from "./pages/private/dashboard/JobDisputeDiscussion";

import RegisterAsClient from "./pages/public/auth/RegisterAsClient";
import RegisterAsProfessional from "./pages/public/auth/RegisterAsProfessional";

import PageNoteFound from "./pages/common/PageNoteFound";
import PaymentSuccess from "./components/dashboard/paymentSuccess";
import WithdrawSuccess from "./components/dashboard/withdrawSuccess";
import useGeolocation from "./hooks/useGeolocation";

import "bootstrap/dist/css/bootstrap.min.css";
import AddFunds from "./pages/private/Funds/AddFunds";
import WithdrawFunds from "./pages/private/Funds/WithdrawFunds";
import Transactions from "./pages/private/Funds/Transactions";
import PayMembership from "./pages/private/membership/PayMembership";
import MembershipPaySuccess from "./components/dashboard/membershipPaySuccess";
import BankDetails from "./pages/private/Funds/BankDetails";

const App = () => {
  useGeolocation();

  return (
    <Router>
      <ToastContainer />
      <div id="wt-wrapper" className="wt-wrapper wt-haslayout">
        <div className="wt-contentwrapper">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<AboutUs />} />
            <Route exact path="/contact" element={<ContactUs />} />
            <Route exact path="/privacy" element={<PrivacyPolicy />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/page/:id" element={<Page />} />
            <Route exact path="/register" element={<OnlyPublicRoute />}>
              <Route exact path="/register" element={<RegisterAsClient />} />
            </Route>
            <Route
              exact
              path="/become-a-professional"
              element={<OnlyPublicRoute />}
            >
              <Route
                exact
                path="/become-a-professional"
                element={<RegisterAsProfessional />}
              />
            </Route>
            <Route exact path="/userlist/:id" element={<PrivateRoute />}>
              <Route exact path="/userlist/:id" element={<FreelancerList />} />
            </Route>
            <Route exact path="/userlist" element={<PrivateRoute />}>
              <Route exact path="/userlist" element={<FreelancerList />} />
            </Route>
            <Route exact path="/user/:id" element={<PrivateRoute />}>
              <Route exact path="/user/:id" element={<FreelancerInfo />} />
            </Route>
            {/* <Route exact path="/dashboard" element={<PrivateRoute />}>
              <Route exact path="/dashboard" element={<Dashboard />} />
            </Route> */}
            <Route exact path="/user-profile" element={<PrivateRoute />}>
              <Route exact path="/user-profile" element={<UserProfile />} />
            </Route>
            <Route exact path="/add-bank-detail" element={<PrivateRoute />}>
              <Route exact path="/add-bank-detail" element={<BankDetails />} />
            </Route>
            <Route exact path="/change-password" element={<PrivateRoute />}>
              <Route
                exact
                path="/change-password"
                element={<ChangePassword />}
              />
            </Route>
            <Route exact path="/change-password" element={<PrivateRoute />}>
              <Route
                exact
                path="/change-password"
                element={<ChangePassword />}
              />
            </Route>
            {/* <Route exact path="/messages" element={<PrivateRoute />}>
              <Route exact path="/messages" element={<Chat />} />
            </Route> */}
            <Route exact path="/membership" element={<PrivateRoute />}>
              <Route exact path="/membership" element={<Membership />} />
            </Route>
            <Route exact path="/addfunds" element={<PrivateRoute />}>
              <Route exact path="/addfunds" element={<AddFunds />} />
              <Route exact path="/addfunds/:id" element={<AddFunds />} />
            </Route>
            <Route exact path="/paymembership" element={<PrivateRoute />}>
              <Route exact path="/paymembership" element={<PayMembership />} />
              <Route exact path="/paymembership/:id" element={<PayMembership />} />
            </Route>
            <Route exact path="/withdraw-funds" element={<PrivateRoute />}>
              <Route exact path="/withdraw-funds" element={<WithdrawFunds />} />
            </Route>
            <Route exact path="/transactions" element={<PrivateRoute />}>
              <Route exact path="/transactions" element={<Transactions />} />
            </Route>
            <Route exact path="/job-list" element={<PrivateRoute />}>
              <Route exact path="/job-list" element={<MyJobList />} />
            </Route>
            <Route exact path="/create-job" element={<PrivateRoute />}>
              <Route exact path="/create-job" element={<CreateMyJob />} />
            </Route>
            <Route exact path="/payment-success" element={<PrivateRoute />}>
              <Route
                exact
                path="/payment-success"
                element={<PaymentSuccess />}
              />
            </Route>
            <Route exact path="/membership-payment-success" element={<PrivateRoute />}>
              <Route
                exact
                path="/membership-payment-success"
                element={<MembershipPaySuccess />}
              />
            </Route>
            <Route exact path="/withdraw-success" element={<PrivateRoute />}>
              <Route
                exact
                path="/withdraw-success"
                element={<WithdrawSuccess />}
              />
            </Route>
            <Route exact path="/dispute/:id" element={<PrivateRoute />}>
              <Route exact path="/dispute/:id" element={<JobDispute />} />
            </Route>
            <Route
              exact
              path="/job-dispute-discussion/:id"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/job-dispute-discussion/:id"
                element={<JobDisputeDiscussion />}
              />
            </Route>
            {/* <Route exact path="/user-availability" element={<PrivateRoute />}>
              <Route exact path="/user-availability" element={<UserAvailability />} />
            </Route> */}
            <Route path="*" element={<PageNoteFound />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
