import React, { useEffect } from 'react'
// import * as Yup from "yup";
// import { Formik } from "formik";
import { submitUserBankInfo } from "../../../store/actions/userActions";
//import { postBankDetail } from "../../../services/profile/availabilitySetting";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { toast } from "react-toastify";

const BankInfo = () => {

    const dispatch = useDispatch();
    //const userLogin = useSelector((state) => state.userLogin);
    //const { userInfo } = userLogin;
    let initialValues = {
        account_number: "",
        bank_name: "",
        account_holder_name: "",
        ifsc_code: "",
        swift_code: "",
    };

    const userbankInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userbankInfo.user.bank_detail) {
        const { account_holder_name, account_number, bank_name, ifsc_code, swift_code } = userbankInfo.user.bank_detail[0];
        // eslint-disable-next-line react-hooks/exhaustive-deps
        initialValues = {
            account_number: account_number,
            bank_name: bank_name,
            account_holder_name: account_holder_name,
            ifsc_code: ifsc_code,
            swift_code: swift_code,
        };
    }

    const [values, setValues] = useState(initialValues);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let post_data = {
            account_number: values.account_number,
            bank_name: values.bank_name,
            account_holder_name: values.account_holder_name,
            ifsc_code: values.ifsc_code,
            swift_code: values.swift_code,
        };
        let response = await dispatch(
            submitUserBankInfo(post_data)
        );
        if (response.status === 200) {
            toast(response.message);

            const usersInfo = JSON.parse(localStorage.getItem('userInfo'));
            const arrObj = [];
            JSON.stringify(post_data, function (key, value) {
                arrObj.push(value);
            })
            console.log(arrObj)

            Object.keys(usersInfo).forEach((key) => {
                if (key === 'user') {
                    usersInfo[key]['bank_detail'] = arrObj;
                };
            });
            localStorage.setItem('userInfo', JSON.stringify(usersInfo));

        } else {
            toast("Error! Please try again later");
        }
    }
    //const data_registration = useSelector((state) => state.data_registration);

    // const Schema = Yup.object().shape({
    //     account_number: Yup.number()
    //         .required("Account Number is Required"),
    //     bank_name: Yup.string()
    //         .required("Bank Name is Required"),
    //     account_holder_name: Yup.string()
    //         .required("Account Holder Name is Required"),
    //     ifsc_code: Yup.string()
    //         .required("IFSC Code is Required"),
    //     swift_code: Yup.string()
    //         .required("Swift Code is Required"),
    // });
    return (
        <>
            <div
                className="wt-personalskillshold"
            >
                <div className="wt-yourdetails wt-tabsinfo">
                    <div className="wt-tabscontenttitle">
                        <h2>Bank Details</h2>
                    </div>
                    {/* <Formik
                        initialValues={{
                            account_number: "",
                            bank_name: "",
                            account_holder_name: "",
                            ifsc_code: "",
                            swift_code: "",
                        }}
                        validationSchema={Schema}
                        onSubmit={async (values, actions) => {
                            let post_data = {
                                account_number: values.account_number,
                                bank_name: values.bank_name,
                                account_holder_name: values.account_holder_name,
                                ifsc_code: values.ifsc_code,
                                swift_code: values.swift_code,
                            };
                            //let bankData = { ...data_registration.data_registration, ...register_stage_4 };
                            let response = await dispatch(
                                submitUserBankInfo(post_data)
                            );
                            console.log(response);
                        }}
                    > */}
                    {/* {(props) => ( */}
                    <form className="wt-formtheme wt-userform" onSubmit={handleSubmit}>
                        <fieldset>
                            <div className="form-group form-group-half">
                                <input
                                    type="text"
                                    name="account_number"
                                    className="form-control"
                                    placeholder="Account Number"
                                    value={values.account_number}
                                    onChange={handleInputChange}
                                    required
                                />
                                {/* {props.errors.account_number &&
                                    props.touched.account_number && (
                                        <span className="text-danger">
                                            {props.errors.account_number}
                                        </span>
                                    )} */}
                            </div>
                            <div className="form-group form-group-half">
                                <input
                                    type="text"
                                    name="bank_name"
                                    className="form-control"
                                    placeholder="Bank Name"
                                    value={values.bank_name}
                                    onChange={handleInputChange}
                                    required
                                />
                                {/* {props.errors.bank_name &&
                                    props.touched.bank_name && (
                                        <span className="text-danger">
                                            {props.errors.bank_name}
                                        </span>
                                    )} */}
                            </div>
                            <div className="form-group form-group-half">
                                <input
                                    type="text"
                                    name="account_holder_name"
                                    className="form-control"
                                    placeholder="Account Holder Name"
                                    value={values.account_holder_name}
                                    onChange={handleInputChange}
                                    required
                                />
                                {/* {props.errors.account_holder_name &&
                                    props.touched.account_holder_name && (
                                        <span className="text-danger">
                                            {props.errors.account_holder_name}
                                        </span>
                                    )} */}
                            </div>
                            <div className="form-group form-group-half">
                                <input
                                    type="text"
                                    name="ifsc_code"
                                    className="form-control"
                                    placeholder="IFSC Code"
                                    value={values.ifsc_code}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group form-group-half">
                                <input
                                    type="text"
                                    name="swift_code"
                                    className="form-control"
                                    placeholder="SWIFT Code"
                                    value={values.swift_code}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="wt-location wt-tabsinfo">
                                <fieldset>
                                    <div className="form-group  mt-4">
                                        <button className="wt-btn" type="submit">
                                            Save &amp; Update
                                        </button>
                                    </div>
                                </fieldset>
                            </div>
                        </fieldset>
                    </form>
                    {/* )} */}
                    {/* </Formik> */}
                </div>
            </div>
        </>
    )
}

export default BankInfo
