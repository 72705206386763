import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const OnlyPublicRoute = ({ component: Component, ...rest }) => {
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    return (
        userInfo ?  <Navigate to="/" /> :<Outlet />
    );
}

export default OnlyPublicRoute
