import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { size } from "lodash";
import { toast } from "react-toastify";
import { signin } from "../store/actions/userActions";
import UserNavBlock from "./users/UserNavBlock";
import MenuListing from "./common/MenuListing";
import { setLoginPopupStatus } from "../store/actions/common/commonActions";
import { sendForgotPassword } from "../services/auth/authAPI";
import { OverlayTrigger, Popover, Button, Badge } from 'react-bootstrap';
import { db } from '../firebase';
import firebase from 'firebase';
import { mapValues, groupBy, omit } from "lodash";
import './header.css'
import Chatting from "./dashboard/chatting";

const Header = (props) => {
  const { extraClass } = props;
  const [notifications, setNotifications] = useState({});

  const dispatch = useDispatch();
  const data_login_popup_status = useSelector(
    (state) => state.data_login_popup_status
  );
  // const [isOpen, setIsOpen] = useState(data_login_popup_status.data_login_popup_status);
  const [flag_forgot_password, setFlagForgotPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(signin(email, password));
    dispatch(setLoginPopupStatus(false));
  };

  const submitForgot = async (e) => {
    e.preventDefault();
    let post_data = { email: forgotEmail };
    let response = await sendForgotPassword(post_data, dispatch);
    if (response.status === 1) {
      toast.success("Success! Check you email for new password");
      dispatch(setLoginPopupStatus(false));
    } else {
      toast.error("Error! Please try again later");
    }
  };
  const [forgotEmail, setForgotEmail] = useState("");
  const handleForgotEmail = (e) => {
    setForgotEmail(e.target.value);
  };

  let style = {
    overflow: "hidden",
    display: data_login_popup_status.data_login_popup_status ? "block" : "none",
    transition: "2s",
  };
  const loginSlide = () => {
    dispatch(
      setLoginPopupStatus(!data_login_popup_status.data_login_popup_status)
    );
    // setIsOpen((prev) => !prev);
  };
  useEffect(() => {
    dispatch(setLoginPopupStatus(false));
  }, [dispatch]);
  const [selectedNotification, setSelectedNotification] = useState('');
  const [chatModalAction, setChatModalAction] = useState(false)
  const [openChatWindow, setOpenChatWindow] = useState(false)
  const [notificationCount, setNotificationCount] = useState(0)
  const selectNotification = (item) => {
    setOpenChatWindow(false)
    setSelectedNotification(item)
    setChatModalAction(true);

  }

  const closeChattingWindow = (item) => {
    setOpenChatWindow(false)
    db.collection('notification').where('jobId', '==', item)
      .where("notificationFor", "==", JSON.parse(localStorage.getItem('userInfo')).user._id)
      .get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          doc.ref.delete();
        });
      })
  }

  const clearNotification = () => {
    db.collection('notification')
      .where("notificationFor", "==", JSON.parse(localStorage.getItem('userInfo')).user._id)
      .get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          doc.ref.delete();
        });
      })
    setOpenChatWindow(!openChatWindow)
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">
        Notifications
      </Popover.Header>
      <Popover.Body style={{ padding: 0 }}>
        <div id="plist" className="people-list" style={{ height: 'auto' }}>
          <ul className="list-unstyled chat-list mb-0 p-0" style={{ minWidth: '250px' }}>
            {Object.keys(notifications).length ?
              Object.keys(notifications).map((item, index) => {
                return notifications[item] && (
                  <li className="clearfix notificationCard p-0" style={{ marginTop: '5px' }} onClick={() => selectNotification(item)}>
                    <div className="about d-flex" style={{ display: 'flex', width: '100%' }}>
                      <div className="name" style={{ width: '100%' }}>
                        You Got Message From {notifications[item][0].notificatioFrom} for {notifications[item][0].jobName}
                      </div>
                    </div>
                  </li>
                )
              }
              )
              : <div style={{ textAlign: 'center', margin: '10px' }}>No Notifications</div>}
            {Object.keys(notifications).length ? <li className="clearfix notActiveChat p-0" style={{ background: '#FFA500' }} onClick={() => clearNotification()}>
              <div className="about d-flex" style={{ display: 'flex', width: '100%' }}>
                <div className="name" style={{ width: '100%' }}>
                  Clear All
                </div>
              </div>
            </li> : <></>}
          </ul>
        </div>
      </Popover.Body>
    </Popover>
  );
  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      checkNotification()
      setInterval(() => checkNotification(), 1 * 60 * 1000);
    }

  }, [localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.role : ''])

  useEffect(() => {
    if (notifications && Object.keys(notifications).length) {
      setNotificationCount(Object.keys(notifications).length)
    } else {
      setNotificationCount(0)
    }
  }, [notifications])
  const checkNotification = () => {
    localStorage.getItem('userInfo') &&
      db.collection("notification").where("notificationFor", "==", JSON.parse(localStorage.getItem('userInfo')).user._id)
        .onSnapshot(item => {
          const data = item.docs.map(doc => doc.data())
          let grouped = mapValues(groupBy(data, 'jobId'));
          setNotifications(grouped)
        });
  }
  return (
    <>
      <header id="wt-header" className={`wt-header ${extraClass} wt-haslayout`}>
        <div className="wt-navigationarea">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <strong className="wt-logo">
                  <Link to="/">
                    <img
                      src="/assets/images/logo.png"
                      alt="company logo here"
                    />
                  </Link>
                </strong>

                <div className="wt-rightarea" style={{ display: "flex", margin: 'auto' }}>


                  <nav id="wt-nav" className="wt-nav navbar-expand-lg">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarNav"
                      aria-controls="navbarNav"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <i className="lnr lnr-menu" />
                    </button>
                    <div
                      className="collapse navbar-collapse wt-navigation"
                      id="navbarNav"
                    >
                      <ul className="navbar-nav">
                        <li className="menu-item-has-children page_item_has_children">
                          <NavLink
                            to="/"
                            className={({ isActive }) =>
                              isActive ? "active" : ""
                            }
                          >
                            Home
                          </NavLink>
                        </li>
                        <MenuListing />
                        {!userInfo && (
                          <li>
                            <Link to="/register">Join Now</Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </nav>
                  {userInfo &&
                    <OverlayTrigger placement="bottom" overlay={popover} show={openChatWindow} >

                      <Button onClick={() => { setOpenChatWindow(!openChatWindow) }} style={{ margin: 'auto', background: 'transparent', border: '0px' }}>
                        <i className="fa fa-bell" style={{ color: "white", margin: 'auto' }} onClick={() => { setOpenChatWindow(!openChatWindow) }}></i>
                        <Badge bg="#FDA500" style={{ background: '#FDA500' }}>{notificationCount}</Badge>
                      </Button>
                    </OverlayTrigger>}
                  {userInfo ? (
                    <UserNavBlock userinfo={userInfo} />
                  ) : (
                      <div className="wt-loginarea">
                        <div className="wt-loginoption wt-loginoptionvtwo">
                          <Link
                            to="#"
                            id="wt-loginbtns"
                            className="wt-btn"
                            onClick={loginSlide}
                          >
                            Login
                        </Link>
                          <div className="wt-loginformhold" style={style}>
                            <div className="wt-loginheader">
                              <span>Login</span>
                              <Link to="#">
                                <i className="fa fa-times" />
                              </Link>
                            </div>
                            {!flag_forgot_password ? (
                              <form
                                className="wt-formtheme wt-loginform do-login-form"
                                onSubmit={submitHandler}
                              >
                                <fieldset>
                                  <div className="form-group">
                                    <input
                                      type="email"
                                      id="email"
                                      placeholder="Enter email"
                                      required
                                      onChange={(e) => setEmail(e.target.value)}
                                      value={email}
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      type="password"
                                      id="password"
                                      placeholder="Enter password"
                                      required
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                      value={password}
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="wt-logininfo">
                                    <button
                                      className="wt-btn do-login-button"
                                      type="submit"
                                    >
                                      Login
                                  </button>
                                    <span className="wt-checkbox">
                                      <input
                                        id="wt-login"
                                        type="checkbox"
                                        name="rememberme"
                                      />
                                      <label htmlFor="wt-login">
                                        Keep me logged in
                                    </label>
                                    </span>
                                  </div>
                                </fieldset>
                                <div className="wt-loginfooterinfo">
                                  <Link
                                    to="#"
                                    onClick={() => setFlagForgotPassword(true)}
                                    className="wt-forgot-password"
                                  >
                                    Forgot password?
                                </Link>
                                  <Link to="/register">Create account</Link>
                                </div>
                              </form>
                            ) : (
                                <form
                                  className="wt-formtheme wt-loginform do-forgot-password-form wt-hide-form1"
                                  onSubmit={submitForgot}
                                >
                                  <fieldset>
                                    <div className="form-group">
                                      <input
                                        type="email"
                                        name="email"
                                        className="form-control get_password"
                                        placeholder="Email"
                                        onChange={(e) => handleForgotEmail(e)}
                                        value={forgotEmail}
                                      />
                                    </div>
                                    <div className="wt-logininfo">
                                      <button
                                        type="submit"
                                        className="wt-btn do-get-password"
                                      >
                                        Get Pasword
                                  </button>
                                    </div>
                                  </fieldset>
                                  <div className="wt-loginfooterinfo">
                                    <Link
                                      to="#"
                                      onClick={() => setFlagForgotPassword(false)}
                                      className="wt-show-login"
                                    >
                                      Login
                                </Link>
                                    <Link to="/register">Register</Link>
                                  </div>
                                </form>
                              )}
                          </div>
                        </div>

                        <Link to="/become-a-professional" className="wt-btn">
                          Become a Professional
                      </Link>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {Object.keys(notifications).length && selectedNotification !== '' && notifications[selectedNotification] ?
          <Chatting
            key='notification'
            modalAction={chatModalAction}
            setModalAction={setChatModalAction}
            professionalId={notifications[selectedNotification]?.reverse()[0].professionalId}
            professionalName={localStorage.getItem('userInfo') ? `${JSON.parse(localStorage.getItem('userInfo')).user.first_name} ${JSON.parse(localStorage.getItem('userInfo')).user.last_name}` : ''}
            jobId={notifications[selectedNotification]?.reverse()[0].jobId}
            name={notifications[selectedNotification]?.reverse()[0].jobName}
            closeChattingWindow={closeChattingWindow}
            client_id={JSON.parse(localStorage.getItem('userInfo')).user.role === 3 ? undefined : notifications[selectedNotification]?.reverse()[0].client_id}
          /> : <></>
        }
      </header>
    </>
  );
};

export default Header;
