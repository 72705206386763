import React from 'react'
import Articles from './Articles'
import Categories from './Categories'
import WorkStart from './WorkStart';
import Company from './Company';
import Experience from './Experience';
import Freelancers from './Freelancers';
import NativeMobileApp from './NativeMobileApp';


const Main = ({ categories }) => {
    return (
        <main id="wt-main" className="wt-main wt-haslayout">
            <Categories categories={categories} />
            <WorkStart />
            <Freelancers />
            <NativeMobileApp />
            <Articles />
            <Company />
            <Experience />
        </main>
    )
}

export default Main
