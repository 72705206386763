import {
  HOME_BANNER_REQUEST,
  HOME_BANNER_FAIL,
  HOME_BANNER_SUCCESS,
  HOME_HOW_IT_WORK_REQUEST,
  HOME_HOW_IT_WORK_FAIL,
  HOME_HOW_IT_WORK_SUCCESS,
  HOME_TOP_FREELANCER_HEADING_REQUEST,
  HOME_TOP_FREELANCER_HEADING_FAIL,
  HOME_TOP_FREELANCER_HEADING_SUCCESS,
  HOME_INTRODUCING_REQUEST,
  HOME_INTRODUCING_FAIL,
  HOME_INTRODUCING_SUCCESS,
  HOME_LATEST_ARTICLE_REQUEST,
  HOME_LATEST_ARTICLE_FAIL,
  HOME_LATEST_ARTICLE_SUCCESS,
  HOME_START_CMP_OR_FREE_REQUEST,
  HOME_START_CMP_OR_FREE_FAIL,
  HOME_START_CMP_OR_FREE_SUCCESS,
  HOME_EXPLORE_CATEGORY_REQUEST,
  HOME_EXPLORE_CATEGORY_FAIL,
  HOME_EXPLORE_CATEGORY_SUCCESS
} from "../constants/homeConstants";

export const homeBannerReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case HOME_BANNER_REQUEST:
      return { loading: true };
    case HOME_BANNER_SUCCESS:
      return { loading: false, home_banner: action.payload };
    case HOME_BANNER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const homeHowItWork = (state = { loading: true }, action) => {
  switch (action.type) {
    case HOME_HOW_IT_WORK_REQUEST:
      return { loading: true };
    case HOME_HOW_IT_WORK_SUCCESS:
      return { loading: false, data: action.payload };
    case HOME_HOW_IT_WORK_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const homeTopFreelancerHeading = (state = { loading: true }, action) => {
  switch (action.type) {
    case HOME_TOP_FREELANCER_HEADING_REQUEST:
      return { loading: true };
    case HOME_TOP_FREELANCER_HEADING_SUCCESS:
      return { loading: false, data: action.payload };
    case HOME_TOP_FREELANCER_HEADING_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const homeIntroducing = (state = { loading: true }, action) => {
  switch (action.type) {
    case HOME_INTRODUCING_REQUEST:
      return { loading: true };
    case HOME_INTRODUCING_SUCCESS:
      return { loading: false, data: action.payload };
    case HOME_INTRODUCING_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const homeLatestArticle = (state = { loading: true }, action) => {
  switch (action.type) {
    case HOME_LATEST_ARTICLE_REQUEST:
      return { loading: true };
    case HOME_LATEST_ARTICLE_SUCCESS:
      return { loading: false, data: action.payload };
    case HOME_LATEST_ARTICLE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const homeStartCmpOrFree = (state = { loading: true }, action) => {
  switch (action.type) {
    case HOME_START_CMP_OR_FREE_REQUEST:
      return { loading: true };
    case HOME_START_CMP_OR_FREE_SUCCESS:
      return { loading: false, data: action.payload };
    case HOME_START_CMP_OR_FREE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const homeExploreCategory = (state = { loading: true }, action) => {
  switch (action.type) {
    case HOME_EXPLORE_CATEGORY_REQUEST:
      return { loading: true };
    case HOME_EXPLORE_CATEGORY_SUCCESS:
      return { loading: false, data: action.payload };
    case HOME_EXPLORE_CATEGORY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};